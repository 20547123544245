import { lazy } from 'react'

import AppRouteInterface from 'src/types/AppRouteInterface'
import { Product, SubProduct } from 'src/utils/product'

const prebidRoutes: ReadonlyArray<AppRouteInterface> = [
  {
    path: `/${Product.PbsYield}/${SubProduct.AudienceAnalytics}`,
    component: lazy(
      () =>
        /* webpackMode: "lazy", webpackChunkName: "prebid_audience_analytics" */
        import('../components/ui/pages/products/prebid/AudienceAnalytics'),
    ),
  },
  {
    path: `/${Product.PbsYield}/${SubProduct.PrebidAnalytics}`,
    component: lazy(
      () =>
        /* webpackMode: "lazy", webpackChunkName: "prebid_demand_analytics" */
        import('../components/ui/pages/products/prebid/PrebidAnalytics'),
    ),
  },
]

export default prebidRoutes
