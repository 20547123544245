import differenceBy from 'lodash/differenceBy'
import uniqBy from 'lodash/uniqBy'
import { AnyAction } from 'redux'

import {
  MAPPING_MENU_SELECT_ALL,
  MAPPING_MENU_SELECT_CHANGED,
  MAPPING_MENU_SELECT_ONE_CHANGED,
  MAPPING_MENU_SET_AVAILABILITY,
  MAPPING_MENU_SET_MODAL_STATUS,
  MAPPING_MENU_SHOW_SELECT_ONLY_CHANGED,
  MAPPING_MENU_UNSELECT_ALL,
} from '../constants/constants'

export interface MappingSelectedItem {
  id: string
  name: string
  original_platforms: string[]
}

export interface MappingMenuStoreProps {
  isSelected: boolean
  currentSelectedItems: MappingSelectedItem[]
  isShowSelectedOnly: boolean
  isAvailable: boolean
  modalStatus: '' | 'open' | 'close' // Detect if mapping modal validation is open
}

const initState: Record<string, MappingMenuStoreProps> = {}

export const defaultMappingMenuState: MappingMenuStoreProps = {
  isSelected: false,
  currentSelectedItems: [],
  isShowSelectedOnly: false,
  isAvailable: false,
  modalStatus: '',
}

const mappingMenuReducer = (state = initState, action: AnyAction & { ecosystemId: string }) => {
  const currentEcosystemId = action.payload?.ecosystemId

  if (currentEcosystemId === undefined) {
    return state
  }

  const currentEcosystemState = state[currentEcosystemId] || defaultMappingMenuState

  switch (action.type) {
    case MAPPING_MENU_SELECT_CHANGED: {
      const { isSelected } = action.payload as MappingMenuStoreProps

      if (isSelected) {
        return { ...state, [currentEcosystemId]: { ...currentEcosystemState, isSelected } }
      }

      return {
        ...state,
        [currentEcosystemId]: {
          ...defaultMappingMenuState,
          isAvailable: currentEcosystemState.isAvailable,
        },
      }
    }
    case MAPPING_MENU_SELECT_ALL: {
      const { items, isSelect } = action.payload as {
        items: MappingSelectedItem[]
        isSelect: boolean
      }

      if (isSelect) {
        return {
          ...state,
          [currentEcosystemId]: {
            ...currentEcosystemState,
            currentSelectedItems: uniqBy(
              [...currentEcosystemState.currentSelectedItems, ...items],
              item => item.id,
            ),
          },
        }
      }

      return {
        ...state,
        [currentEcosystemId]: {
          ...currentEcosystemState,
          currentSelectedItems: differenceBy(
            currentEcosystemState.currentSelectedItems,
            items,
            item => item.id,
          ),
        },
      }
    }
    case MAPPING_MENU_UNSELECT_ALL: {
      return {
        ...state,
        [currentEcosystemId]: { ...currentEcosystemState, currentSelectedItems: [] },
      }
    }
    case MAPPING_MENU_SELECT_ONE_CHANGED: {
      const { item, isSelected } = action.payload as {
        item: MappingSelectedItem
        isSelected: boolean
      }
      let currentSelectedItems = [...currentEcosystemState.currentSelectedItems]

      if (isSelected) {
        currentSelectedItems.push(item)
        uniqBy([...currentSelectedItems, item], ({ id }) => id)
      } else {
        currentSelectedItems = currentSelectedItems.filter(({ id }) => id !== item.id)
      }

      return { ...state, [currentEcosystemId]: { ...currentEcosystemState, currentSelectedItems } }
    }
    case MAPPING_MENU_SHOW_SELECT_ONLY_CHANGED: {
      const { isShowSelectedOnly } = action.payload as MappingMenuStoreProps

      return { ...state, [currentEcosystemId]: { ...currentEcosystemState, isShowSelectedOnly } }
    }

    case MAPPING_MENU_SET_AVAILABILITY: {
      const { isAvailable } = action.payload as MappingMenuStoreProps

      return { ...state, [currentEcosystemId]: { ...currentEcosystemState, isAvailable } }
    }

    case MAPPING_MENU_SET_MODAL_STATUS: {
      const { modalStatus } = action.payload as MappingMenuStoreProps

      return { ...state, [currentEcosystemId]: { ...currentEcosystemState, modalStatus } }
    }

    default:
      return state
  }
}

export default mappingMenuReducer
