import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import React, { FC } from 'react'

import ExportSchedulingContainer from 'src/components/data/containers/ExportSchedulingContainer/ExportSchedulingContainer'
import Button from 'src/components/ui/atoms/Button'
import DownloadPdfIcon from 'src/components/ui/atoms/Icons/DownloadPdfIcon'
import SaveIcon from 'src/components/ui/atoms/Icons/Save'
import SaveAs from 'src/components/ui/atoms/Icons/SaveAs'
import InputModal from 'src/components/ui/molecules/InputModal'
import ShareDashboard from 'src/components/ui/molecules/ShareDashboard'
import useComponentConfig from 'src/hooks/useComponentConfig'
import useHasChangeOnPage from 'src/hooks/useHasChangeOnPage'
import usePrinter from 'src/hooks/usePrinter'
import useProductPage from 'src/hooks/useProductPage'
import useQueryParams from 'src/hooks/useQueryParams'
import useTracking from 'src/hooks/useTracking'
import { useAppSelector } from 'src/redux/hooks'
import SaveDashboardItemInterface from 'src/types/SaveDashboardItemInterface'
import { ExportConfig } from 'src/utils/export'
import { Product, shareLinksProducts } from 'src/utils/product'

import * as styles from './DashboardOptions.scss'

interface DashboardOptionsProps {
  disabled: boolean
  displayModal: string
  printModeAllowed?: boolean
  labelError: boolean
  modalInputValue: string
  componentsCodes?: {
    exportDashboard: string
  }
  setModalInputValue: (value: string) => void
  handleModalChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  setLabelError: (value: boolean) => void
  setDisplayModal: (value: '' | 'edit' | 'copy' | 'save') => void
  saveDashboard: () => Promise<void>
  saveNewDashboard: (dashboard: SaveDashboardItemInterface) => Promise<void>
}

const DashboardOptions: FC<DashboardOptionsProps> = ({
  componentsCodes,
  disabled,
  printModeAllowed,
  displayModal,
  labelError,
  modalInputValue,
  handleModalChange,
  setDisplayModal,
  setLabelError,
  saveNewDashboard,
  saveDashboard,
}) => {
  const exportDashboardConfig: ExportConfig = useComponentConfig(componentsCodes?.exportDashboard)
  const { hasChangesOnDashboard } = useHasChangeOnPage()
  const { trackEvent } = useTracking()
  const { setQueryParams } = useQueryParams()
  const { setPrintMode, printMode } = usePrinter()
  const page = useProductPage()
  const isLoadingDone = useAppSelector(state =>
    state.root.loadingComponents.every(component => !component.loading),
  )

  const buttonType = !hasChangesOnDashboard ? 'disable' : 'silence'

  return (
    <div className={styles.DashboardOptions}>
      <InputModal
        open={displayModal === 'save'}
        onClose={() => {
          setDisplayModal('')
          setLabelError(false)
        }}
        onChange={handleModalChange}
        value={modalInputValue}
        hasError={labelError}
        submit={() => {
          saveNewDashboard({ label: modalInputValue } as SaveDashboardItemInterface)
          trackEvent({ action: 'Click: Save as.. Dashboard' })
        }}
        title="Save as"
        buttonLabel="Save"
        data-testid="save-as-modal"
        inputTestId="save-as-input"
      />

      <Button
        buttonType="silence"
        className={cx(
          styles.Icon,
          styles.ButtonsNoPadding,
          styles.DashboardOptionsButton,
          styles.DashboardOptionsFirstButton,
        )}
      >
        <SaveAs
          onClick={() => {
            setDisplayModal('save')
          }}
        />
      </Button>

      <Button
        buttonType={buttonType}
        className={cx(styles.Icon, styles.ButtonsNoPadding, styles.DashboardOptionsButton)}
      >
        <SaveIcon
          onClick={() => {
            saveDashboard()
            trackEvent({ action: 'Click: Save Dashboard' })
          }}
        />
      </Button>

      {!isEmpty(exportDashboardConfig) && (
        <ExportSchedulingContainer
          className={styles.DashboardOptionsButton}
          exportDashboardConfig={exportDashboardConfig as ExportConfig}
        />
      )}

      {printModeAllowed && (
        <Button
          disabled={disabled}
          buttonType={!isLoadingDone || disabled ? 'disable' : 'silence'}
          onClick={() => {
            setQueryParams({ download_pdf: true })
            trackEvent({ action: 'Click: Download PDF icon' })
            setPrintMode(true)
          }}
          className={cx(styles.Icon, styles.ButtonsNoPadding, styles.DashboardOptionsButton)}
        >
          <DownloadPdfIcon />
        </Button>
      )}

      {!printMode && shareLinksProducts.includes(page.product as Product) && (
        <ShareDashboard
          className={cx(
            styles.Icon,
            styles.ButtonsNoPadding,
            styles.DashboardOptionsButton,
            styles.DashboardOptionsShareButton,
          )}
          isLoading={!isLoadingDone}
        />
      )}
    </div>
  )
}

export default DashboardOptions
