import { mergeWith } from 'lodash'
import { AnyAction } from 'redux'

import { mergeUnique } from 'src/utils/helpers'
import { ComponentsSettings, SettingsLoaded } from 'src/utils/settings'
import {
  RESET_COMPONENTS_SETTINGS,
  RESET_SETTINGS_LOADED,
  SET_COMPONENTS_SETTINGS,
  SET_COMPONENTS_SETTINGS_LOADING,
  SET_OLD_COMPONENTS_SETTINGS,
  SET_SETTINGS_LOADED,
} from '../constants/constants'

export interface ComponentsSettingsStoreProps {
  loading: boolean // Loading while settings are loading
  settings: ComponentsSettings
  oldSettings: ComponentsSettings | any
  settingsLoaded?: SettingsLoaded
}

const initialState: ComponentsSettingsStoreProps = {
  loading: false,
  settings: {}, // Set current settings
  oldSettings: undefined, // Set previous settings / Keep a version of original settings
  settingsLoaded: {}, // Determine if the settings are loaded correctly. ex: { [pageCode]: { [ecosystems|keyMetrics|filterBar]: (true|false) }... }
}

/**
 * Update components settings / Local function
 * @param stateSettings
 * @param value
 * @param subProductCode
 * @param componentCode
 */
const updatedSettings = (
  stateSettings: ComponentsSettings,
  value: any,
  subProductCode?: string,
  componentCode?: string,
) => {
  let settings = value

  if (subProductCode && componentCode) {
    const newComponentSettings = { [subProductCode]: { [componentCode]: value } }
    settings = newComponentSettings
  } else {
    if (subProductCode) {
      settings = { [subProductCode]: settings }
    }
  }

  return Object.assign({}, mergeWith(stateSettings, settings, mergeUnique))
}

export default function componentsSettingsReducer(
  state: ComponentsSettingsStoreProps = initialState,
  action: AnyAction,
): ComponentsSettingsStoreProps {
  if (action.type === SET_COMPONENTS_SETTINGS) {
    const { value, subProductCode, componentCode } = action.payload
    const settings = updatedSettings(state.settings, value, subProductCode, componentCode)

    return { ...state, settings }
  } else if (action.type === SET_OLD_COMPONENTS_SETTINGS) {
    const { value, subProductCode, componentCode } = action.payload
    const oldSettings = updatedSettings(state.oldSettings, value, subProductCode, componentCode)

    return { ...state, oldSettings }
  } else if (action.type === RESET_COMPONENTS_SETTINGS) {
    const { payload } = action
    const settings = updatedSettings({}, payload.settings)
    const oldSettings = updatedSettings({}, payload.oldSettings)

    return { ...state, settings, oldSettings }
  } else if (action.type === SET_COMPONENTS_SETTINGS_LOADING) {
    const { value } = action.payload

    return { ...state, loading: value }
  } else if (action.type === SET_SETTINGS_LOADED) {
    const { value } = action.payload
    const settingsLoaded = Object.assign({}, mergeWith(state.settingsLoaded, value, mergeUnique))

    return { ...state, settingsLoaded }
  } else if (action.type === RESET_SETTINGS_LOADED) {
    const settingsLoaded = Object.assign({}, {})

    return { ...state, settingsLoaded }
  }

  return state
}
