import { useCallback } from 'react'

import { setComponentsSettingsAction } from 'src/redux/actions/componentsSettingsActions'
import { ComponentCode } from 'src/utils/settings'
import useDispatcher from './useDispatcher'
import useProductPage from './useProductPage'

export const useSetComponentSettingsAction = () => {
  const setComponentsSettingsDispatcher = useDispatcher(setComponentsSettingsAction)
  const { dashboardKey } = useProductPage()

  const handleSetComponentSettings = useCallback(
    ({
      settings,
      componentCode,
      isOld,
    }: {
      settings: object
      componentCode: ComponentCode | null
      isOld?: boolean
    }) => {
      setComponentsSettingsDispatcher(settings, componentCode, dashboardKey, isOld || false)
    },
    [setComponentsSettingsDispatcher, dashboardKey],
  )

  return handleSetComponentSettings
}
