import { Dictionary } from 'lodash'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'

import { AppProductInterface } from 'src/types/AppProductInterface'

/**
 * @function
 * @name getMatchingChildren
 * @description Gather in a list all the children of a component that match a given condition
 * @param {object} root Element from which we want to gather the matching children
 * @param {function} condition Function the children have to match in order to be returned
 * @param {boolean} isTopRoot flat to tell if we are analysing the whole component tree
 * @returns {array} all the matching children
 */
export const getMatchingChildren = (root, condition, isTopRoot = false) => {
  const recurse = (result, component) => {
    const newResult = condition(component) ? [...result, component] : result
    if (component.children) {
      return component.children.reduce(recurse, newResult)
    }
    return newResult
  }
  if (isTopRoot) {
    return Object.values(root).reduce(recurse, [])
  }
  return recurse([], root)
}

/**
 * @function
 * @name getMatchingChild
 * @description get the first children of a component that match a given condition
 * @param {object} root Element from which we want to gather the first matching child
 * @param {function} condition Function the children have to match in order to be returned
 * @param {boolean} isTopRoot flat to tell if we are analysing the whole components tree
 * @returns {object} the first matching child
 */
export const getMatchingChild = (root, condition, isTopRoot = false) => {
  const recurse = (result, component) => {
    if (result) {
      return result
    }
    if (condition(component)) {
      return component
    }
    if (component.children) {
      return component.children.reduce(recurse, result)
    }
    return null
  }
  if (isTopRoot) {
    return Object.values(root).reduce(recurse, null)
  }
  return recurse(null, root)
}

/**
 * @function
 * @name getPath
 * @description get the path from the root to an element
 * @param {object} root Starting point of our tree (doesn't have to be the whole component object)
 * @param {object} element Element from which we want to retrieve the path
 * @param {boolean} isTopRoot flat to tell if we are analysing the whole components object
 * @returns {array} the path from root to element, ordered by decreasing depth
 */
export const getPath = (root, element, isTopRoot = false) => {
  const recurse = (result, component) => {
    if (result.length > 0) {
      return result
    }
    if (element.id === component.id) {
      return [component]
    }
    if (component.children) {
      const data = component.children.reduce(recurse, result)
      if (data.length > 0) {
        return [...data, component]
      }
      return data
    }
    return []
  }
  if (isTopRoot) {
    return Object.values(root).reduce(recurse, [])
  }
  return recurse([], root)
}

export const getClosestParent = (root, element, isTopRoot = false) =>
  getPath(root, element, isTopRoot).find(el => el.component_identifier === 'product')

export const transformChildren = (root: AppProductInterface) => {
  const recurse = (component: AppProductInterface) => {
    if (component.children) {
      return {
        ...component,
        children: component.children.map(recurse),
      }
    }
    return component
  }
  return recurse(root)
}

export const mapSubProduct = (
  tree: Dictionary<AppProductInterface>,
  transformation: (c: AppProductInterface) => AppProductInterface,
) =>
  mapValues(tree, product => {
    if (!product.children) {
      return product
    }
    return {
      ...product,
      children: product.children.map(transformation),
    }
  })

export const getFilterRoot = (root, product) => {
  const flags = {
    dashboard: 'filter',
    benchmark: 'filter',
    analyzer: 'filter',
    market_alert: 'filter',
    market_overview: 'filter',
    datadownload: 'data_download',
  }
  const category = flags[product]
  return getMatchingChild(root, el => el.component_identifier === category)
}

export const getFieldAmount = root => getMatchingChildren(root, el => el.is_field).length

export const buildEmitters = (products, type) => {
  const applyEmitters = (result, component) => {
    if (component[type]) {
      const { code } = component
      return component[type].reduce(
        (subResult, emitter) => ({
          ...subResult,
          [emitter]: subResult[emitter] ? [...subResult[emitter], code] : [code],
        }),
        result,
      )
    }
    return result
  }

  const recurse = (result, component) => {
    const newResult = applyEmitters(result, component)
    if (component.children) {
      return component.children.reduce(recurse, newResult)
    }
    return newResult
  }

  return Object.values(products).reduce(recurse, {})
}

const transformProgramListToSubProduct = (
  products: AppProductInterface[],
): AppProductInterface[] => {
  return products.reduce<AppProductInterface[]>((acc, product) => {
    const productCp = product

    if (productCp.program_list?.length) {
      const products = productCp.program_list.map(program => ({
        ...productCp,
        id: `${productCp.id}-${program.id}`,
        name: program.label,
        program_list: undefined,
        isProgram: true,
        programId: program.id,
        children: productCp.children
          ? transformProgramListToSubProduct(productCp.children)
          : undefined,
      }))

      return [...acc, ...products]
    }

    product.children = product.children
      ? transformProgramListToSubProduct(product.children)
      : undefined

    return [...acc, product]
  }, [] as AppProductInterface[])
}

export const prepareComponentTree = (tree: AppProductInterface[]) => {
  const products = keyBy(transformProgramListToSubProduct(tree), 'code')
  const trees = mapSubProduct(products, subProduct => transformChildren(subProduct))
  return trees
}
