import { Field } from 'redux-form'

import ToggleButton from 'src/components/ui/atoms/ToggleButton'

import * as styles from '../../ExportSchedulingForm.scss'

const ExportFormat = ({ availableFormats }: { availableFormats: ReadonlyArray<string> }) => (
  <div className={styles.Format}>
    <Field
      name="format"
      type="radio"
      component={({ input }: HTMLFormElement) => (
        <ToggleButton
          {...input}
          name={availableFormats[0]}
          key={availableFormats[0]}
          id={availableFormats[0]}
          checked
          readOnly
        >
          PDF
        </ToggleButton>
      )}
      value={availableFormats[0]}
    />
  </div>
)

export default ExportFormat
