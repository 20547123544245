import { createContext, FC, ReactNode, useContext } from 'react'

interface PopupButtonContextType {
  isOpen: boolean
  toggle: (isOpen: boolean) => void
  isHovered: boolean
}

interface PopupButtonProviderProps extends PopupButtonContextType {
  children: ReactNode
}

const PopupButtonContext = createContext<PopupButtonContextType | null>(null)

const PopupButtonProvider: FC<PopupButtonProviderProps> = ({
  children,
  isOpen,
  isHovered,
  toggle,
}) => {
  return (
    <PopupButtonContext.Provider value={{ isOpen, toggle, isHovered }}>
      {children}
    </PopupButtonContext.Provider>
  )
}

export const usePopupButtonContext = () => {
  const context = useContext(PopupButtonContext)

  if (!context) {
    throw new Error('usePopupButtonContext must be used within a PopupButtonProvider')
  }

  return context
}

export default PopupButtonProvider
