import cx from 'classnames'
import PropTypes from 'prop-types'

import * as styles from './FlatInput.scss'

const FlatInput = props => {
  const {
    className,
    hasError,
    isCalendarField,
    placeholder,
    autoFocus,
    componentColor,
    ...othersProps
  } = props

  const classes = cx({
    [styles.FlatInput]: true,
    [styles.FlatInputError]: hasError,
    [styles.CalendarField]: isCalendarField,
    [className]: !!className,
  })

  return (
    <input
      {...othersProps}
      className={classes}
      placeholder={placeholder}
      onKeyPress={e => {
        if (e.key === 'Enter') e.preventDefault()
      }}
      autoFocus={autoFocus}
    />
  )
}

FlatInput.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  hasError: PropTypes.bool,
  readOnly: PropTypes.bool,
  isCalendarField: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  autoFocus: PropTypes.bool,
}

FlatInput.defaultProps = {
  hasError: false,
}

export default FlatInput
