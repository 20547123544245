import cx from 'classnames'
import { FC } from 'react'
import { NavLink } from 'react-router-dom'

import SelfServiceMappingIcon from 'src/components/ui/atoms/Icons/SelfServiceMappingIcon'
import useHasMappingRightAccess from 'src/hooks/useHasMappingRightAccess'
import { CommonPage } from 'src/utils/product'

import * as styles from '../Menu.scss'

export interface SelfServiceMappingSidebarMenuProps {
  currentPage: string
  footerMenuClassName: string
  isSideBarOpen: boolean
  activeIconStyle: { color: string }
  inactiveIconStyle: { color: string }
}

const SelfServiceMappingSidebarMenu: FC<SelfServiceMappingSidebarMenuProps> = ({
  currentPage,
  footerMenuClassName,
  isSideBarOpen,
  activeIconStyle,
  inactiveIconStyle,
}) => {
  const isCurrentPage = currentPage === CommonPage.SelfServiceMapping
  const hasMappingAccess = useHasMappingRightAccess()

  const serviceMappingPage = cx({
    [styles.parentElement]: true,
    [styles.parentElementActiveLink]: isCurrentPage,
  })

  const MappedIconStyle = {
    fontSize: '16px',
    width: '16px',
  }

  const selfServiceMappingProps = isCurrentPage ? activeIconStyle : inactiveIconStyle

  const hideItems = cx({
    [styles.hiddenItem]: !isSideBarOpen,
  })

  if (!hasMappingAccess) {
    return null
  }

  return (
    <NavLink
      className={serviceMappingPage}
      to={`/${CommonPage.SelfServiceMapping}`}
      aria-label="Mapping"
    >
      <div className={footerMenuClassName}>
        <span className={styles.FooterIcon} style={MappedIconStyle}>
          <SelfServiceMappingIcon {...selfServiceMappingProps} />
        </span>
        <span className={hideItems}>Mapping</span>
      </div>
    </NavLink>
  )
}

export default SelfServiceMappingSidebarMenu
