import cx from 'classnames'
import { forwardRef, HTMLProps } from 'react'

import * as styles from './Button.scss'

export interface ButtonProps extends Omit<HTMLProps<HTMLButtonElement>, 'buttonType' | 'ref'> {
  buttonType?:
    | 'silence'
    | 'primary'
    | 'success'
    | 'disable'
    | 'secondary'
    | 'small'
    | 'successSecondary'
    | 'pink'
  disabled?: boolean
  animationStart?: boolean
  ref?: HTMLButtonElement
  height?: number | string
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    children,
    onClick,
    buttonType,
    disabled,
    animationStart,
    className,
    height,
    style,
    ...othersProps
  } = props

  const buttonClass = cx(styles.button, className, {
    [styles.Silence]: buttonType === 'silence',
    [styles.SilenceDisable]: buttonType === 'silence' && disabled,
    [styles.Primary]: buttonType === 'primary',
    [styles.PrimaryDisable]: buttonType === 'primary' && disabled,
    [styles.Success]: buttonType === 'success',
    [styles.SuccessDisable]: buttonType === 'success' && disabled,
    [styles.Secondary]: buttonType === 'secondary',
    [styles.SecondaryDisable]: buttonType === 'secondary' && disabled,
    [styles.Disable]: buttonType === 'disable',
    [styles.Small]: buttonType === 'small',
    [styles.SmallAnimation]: animationStart,
    [styles.SuccessSecondary]: buttonType === 'successSecondary',
    [styles.SuccessSecondaryDisable]: buttonType === 'successSecondary' && disabled,
    [styles.Pink]: buttonType === 'pink',
    [styles.PinkDisable]: buttonType === 'pink' && disabled,
  })
  // To avoid Impossible d'assigner le type 'string' au type '"button" | "submit" | "reset" | undefined'.ts(2322)
  const propsButton: any = {
    type: 'button',
  }
  return (
    <button
      role="button"
      {...propsButton}
      className={buttonClass}
      onClick={e => onClick?.(e)}
      data-type={buttonType}
      ref={ref}
      style={{ height, ...style }}
      {...othersProps}
    >
      {children}
    </button>
  )
})

Button.displayName = 'Button'

export default Button
