const PriceIcon = props => (
  <svg fill="currentColor" width="24" height="24" viewBox="-2 0 25 25" {...props}>
    <g data-name="Layer 2">
      <path
        fill="currentColor"
        d="M13.2 3.6A3.6 3.6 0 1 0 7 6.08h5.2a3.58 3.58 0 0 0 1-2.48zm5.74 8.93a7.2 7.2 0 0 0-3.36-4.37L15.6 6h-1.28l-1.2 1.2H8.4a7.21 7.21 0 0 0-7.17 6.6H0v2.55h1.27V24H5.1v-2.4h6.37V24h3.83v-3.21a7.2 7.2 0 0 0 3.63-4.44h1.47v-3.82zM14.4 14.4a1.2 1.2 0 1 1 1.2-1.2 1.2 1.2 0 0 1-1.2 1.2z"
        data-name="Layer 1"
      />
    </g>
  </svg>
)

export default PriceIcon
