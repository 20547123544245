const ReportIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" height="11" width="24" viewBox="0 0 24 11" {...props}>
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M24 0h-5.4a5.36 5.36 0 0 0-3.36 1.2H8.77A5.38 5.38 0 0 0 5.37 0H0v2.4l.78.26a5.35 5.35 0 0 0-.73 3.51 5.41 5.41 0 1 0 10.7-1.54 5.75 5.75 0 0 0-.26-1h3a5.42 5.42 0 0 0-.33 1.8A5.42 5.42 0 0 0 24 5.4a5.3 5.3 0 0 0-.77-2.74L24 2.4V0zM5.4 8.4a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm13.2 0a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
    />
  </svg>
)

export default ReportIcon
