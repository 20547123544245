import cx from 'classnames'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'

import LogoutIcon from 'src/components/ui/atoms/Icons/Logout'
import SettingsAdminIcon from 'src/components/ui/atoms/Icons/SettingsAdminIcon'
import UserIcon from 'src/components/ui/atoms/Icons/UserIcon'
import { MENU } from 'src/utils/constant'
import { CommonPage } from 'src/utils/product'
import { userType } from 'src/utils/propTypes'
import SelfServiceMappingSidebarMenu from './SelfServiceMappingSidebarMenu'

import * as styles from './Menu.scss'

const inactiveIcon = { color: '#9eabb0' }
const activeIcon = { color: '#2d348d' }
const ADMIN = 'admin'

class SidebarFooter extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
    updateMenuStatus: PropTypes.func.isRequired,
    history: PropTypes.object,
    user: userType,
  }

  constructor(props) {
    super(props)

    this.handleLogout = this.handleLogout.bind(this)
  }

  handleLogout() {
    const { logout, updateMenuStatus } = this.props

    updateMenuStatus(false)
    logout()
  }

  render() {
    const { isOpen, history, user } = this.props

    const urlParse =
      history &&
      history.location &&
      history.location.pathname.split('/').filter(item => item.length)

    const [currentPage] = urlParse

    const adminProps = currentPage === MENU.footer.administration ? activeIcon : inactiveIcon
    const accountProps = currentPage === MENU.footer.account ? activeIcon : inactiveIcon

    const footerMenu = cx({
      [styles.Product]: true,
    })

    const hideItems = cx({
      [styles.hiddenItem]: !isOpen,
    })

    const AdminSection = cx({
      [styles.Administration]: true,
      [styles.hiddenItem]: !isOpen,
    })

    const accountPage = cx({
      [styles.parentElement]: true,
      [styles.parentElementActiveLink]: currentPage === 'account',
    })

    const serviceMappingPage = cx({
      [styles.parentElement]: true,
      [styles.parentElementActiveLink]: currentPage === CommonPage.SelfServiceMapping,
    })

    const AdminIconStyle = {
      fontSize: '1.6rem',
      widht: '1.438rem',
      marginRight: '1.4rem',
    }

    const isAdmin = user && user.default_company_right === ADMIN

    return (
      <div className={styles.Footer}>
        <div>
          <SelfServiceMappingSidebarMenu
            activeIconStyle={activeIcon}
            footerMenuClassName={footerMenu}
            hideItemsClassName={hideItems}
            inactiveIconStyle={inactiveIcon}
            isSideBarOpen={isOpen}
            serviceMappingPageClassName={serviceMappingPage}
            currentPage={currentPage}
          />

          <NavLink
            className={accountPage}
            to="/account/platform"
            aria-label="My settings link button"
          >
            <div className={footerMenu}>
              <span className={styles.FooterIcon}>
                <SettingsAdminIcon {...accountProps} />
              </span>
              <span className={hideItems}>My Settings</span>
            </div>
          </NavLink>
          {isAdmin && (
            <NavLink
              className={styles.parentElement}
              to="/administration"
              activeClassName={styles.parentElementActiveLink}
              aria-label="Administration link button"
            >
              <div className={footerMenu}>
                <span className={styles.FooterIcon} style={AdminIconStyle}>
                  <UserIcon {...adminProps} />
                </span>
                <span className={AdminSection}>Administration</span>
              </div>
            </NavLink>
          )}
          <div
            className={styles.parentElement}
            onClick={this.handleLogout}
            role="button"
            aria-label="Logout button"
            tabIndex={0}
          >
            <div className={footerMenu}>
              <span className={styles.FooterIcon}>
                <LogoutIcon />
              </span>
              <span className={hideItems}>Logout</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SidebarFooter
