import cx from 'classnames'
import { CSSProperties, HTMLAttributes } from 'react'

import Tooltip from 'src/components/ui/atoms/Tooltip'
import { truncate } from 'src/utils/text'

import * as styles from './Badge.scss'

export interface BadgeProps extends HTMLAttributes<HTMLSpanElement> {
  label: any
  type?: string
  color?: string
  textColor?: string
  small?: boolean
  className?: string
  truncLimit?: number
}

export const BADGE_TYPE = {
  success: 'success',
  danger: 'danger',
  notification: 'notification',
  notificationMenu: 'notificationMenu',
  secondary: 'secondary',
  neutral: 'neutral',
}

const Badge = ({
  label,
  type,
  small,
  color,
  textColor,
  className,
  truncLimit,
  ...otherProps
}: BadgeProps) => {
  const shouldLabelBeTruncate = label.length > (truncLimit as number)
  const truncLabel = shouldLabelBeTruncate ? truncate(label, truncLimit as number) : label
  const classes = cx(className, styles.Badge, {
    [styles.Success]: type === BADGE_TYPE.success,
    [styles.Danger]: type === BADGE_TYPE.danger,
    [styles.Notification]: type === BADGE_TYPE.notification,
    [styles.NotificationMenu]: type === BADGE_TYPE.notificationMenu,
    [styles.Neutral]: type === BADGE_TYPE.neutral,
    [styles.Small]: small,
    [styles.Unknown]: label === 'unknown',
  })
  const style = {
    backgroundColor: label === 'unknown' ? 'unset' : color,
  } as CSSProperties

  if (textColor) {
    style.color = textColor
  }

  return (
    <>
      <span
        data-testid="ecosystem-badge"
        style={style}
        className={classes}
        data-tip=""
        data-for={label}
        {...otherProps}
      >
        {truncLabel}
      </span>
      {shouldLabelBeTruncate && (
        <Tooltip id={label} delayShow={300}>
          {label}
        </Tooltip>
      )}
    </>
  )
}

export default Badge
