const ChevronUp = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="6"
    className="chevron-up-icon"
    viewBox="0 0 9 6"
    data-testid="chevron-up-icon"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M1.28 5.03L4.5 1.81l3.22 3.22a.75.75 0 0 0 1.06-1.06L5.03.22a.75.75 0 0 0-1.06 0L.22 3.97a.75.75 0 1 0 1.06 1.06z"
    />
  </svg>
)

export default ChevronUp
