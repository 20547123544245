import { FC, ReactNode, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import usePrinter from 'src/hooks/usePrinter'
import useSettings from 'src/hooks/useSettings'
import useTracking from 'src/hooks/useTracking'
import { getProductSuffixNameFn } from 'src/utils/productUtils'
import { parseURL } from 'src/utils/urls'

interface PageProps {
  title?: string
  children: ReactNode
  isWithSuffix?: boolean
}

const PAGE_SUFFIX = ' | Adomik Platform'
const UNKNOWN_PAGE = 'Adomik Platform'
const PRINT_TITLE = 'Report Dashboard Download'

const Page: FC<PageProps> = ({ children, title, isWithSuffix: isWithSuffixProp }) => {
  const { trackPageView } = useTracking()
  const settings = useSettings()
  const { printMode } = usePrinter()

  const { pathname } = useLocation()

  const { product: productFormPathname, page } = parseURL(pathname)
  const productElement = settings?.elementsByCode?.[page || productFormPathname]
  const parentName = productElement ? getProductSuffixNameFn(productElement.parentName) : undefined
  const space = parentName ? ' ' : ''
  const productName = productElement ? `${parentName}${space}${productElement.name}` : undefined

  const pageTitle = printMode ? PRINT_TITLE : productName || title || productFormPathname

  const isWithSuffix = isWithSuffixProp || printMode

  const pageTitleWithSuffix = `${pageTitle}${isWithSuffix ? PAGE_SUFFIX : ''}`

  useEffect(() => {
    if (pageTitleWithSuffix === UNKNOWN_PAGE) {
      return
    }
    document.title = pageTitleWithSuffix
    trackPageView(pageTitleWithSuffix)
  }, [pageTitleWithSuffix, pageTitle, printMode, trackPageView])

  return <>{children}</>
}

Page.defaultProps = {
  isWithSuffix: true,
}

export default Page
