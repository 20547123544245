import { MouseEvent, useCallback, useMemo, useRef, useState } from 'react'

import { ReactCallBackFunction } from 'src/types/CallbackFunction'

interface HoverProps<T> {
  onMouseMove?: (evt: MouseEvent<T>) => void
  onMouseLeave?: (evt: MouseEvent<T>) => void
  isMouseEnter?: boolean // Use mouse over or mouse move event
  mouseLeaveDelay?: number // Delay before the mouse leave event is triggered
}

const useHover = <T = HTMLDivElement>(args?: HoverProps<T>) => {
  const [isHovered, setIsHovered] = useState(false)

  const timeoutRef = useRef<NodeJS.Timer | number>()

  const handleSetIsHovered: ReactCallBackFunction<boolean> = useCallback(
    (state: boolean | ((prevState: boolean) => boolean)) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current as number)
      }
      setIsHovered(s => (typeof state === 'function' ? state(s) : state))
    },
    [timeoutRef],
  )

  const onMouseMove = useCallback(
    (evt: MouseEvent<T>) => {
      handleSetIsHovered(true)
      args?.onMouseMove?.(evt)
    },
    [args?.onMouseMove],
  )
  const onMouseLeave = useCallback(
    (evt: MouseEvent<T>) => {
      const handleLeave = () => {
        handleSetIsHovered(false)
        args?.onMouseLeave?.(evt)
      }
      if (args?.mouseLeaveDelay) {
        timeoutRef.current = setTimeout(handleLeave, args.mouseLeaveDelay)
      } else {
        handleLeave()
      }
    },
    [args?.onMouseLeave, args?.mouseLeaveDelay],
  )

  const hoverProps = useMemo(
    () => ({
      onMouseMove: !args?.isMouseEnter ? onMouseMove : undefined,
      onMouseLeave,
      onMouseEnter: args?.isMouseEnter ? onMouseMove : undefined,
    }),
    [onMouseMove, onMouseLeave, args?.isMouseEnter],
  )

  return {
    isHovered,
    setIsHovered: handleSetIsHovered,
    hoverProps,
  }
}

export default useHover
