import { useCallback, useEffect, useRef } from 'react'
import { useLocation } from 'react-router'

import useProductPage from 'src/hooks/useProductPage'
import googleAnalytics from 'src/utils/GoogleAnalytics'
import { Event, TrackEventCallback, TrackPageViewEventCallback } from 'src/utils/tracking'
import useAccount from './useAccount'

const transformPathname = (pathname: string): string =>
  pathname.endsWith('/') ? pathname : `${pathname}/`

const useTracking = (): {
  trackEvent: TrackEventCallback
  trackPageView: TrackPageViewEventCallback
} => {
  const page = useProductPage()
  const { informations: account } = useAccount()
  const { pathname } = useLocation()

  const pathnameRef = useRef(pathname)

  useEffect(() => {
    pathnameRef.current = pathname
  }, [pathname])

  const trackEvent = useCallback(
    (event: Partial<Omit<Event, 'category'>> & { action: string }) => {
      const defaultOptions = {
        user_id: account?.id,
        dimension1: account?.email,
        dimension2: account?.default_company?.name,
        currency: account?.currency?.code,
        site_url: window.location.hostname,
      } as any

      const { componentSize, ...eventToSend } = event

      if (componentSize) {
        eventToSend['component_size'] = componentSize
      }

      googleAnalytics.event({
        ...defaultOptions,
        category: page.name,
        ...eventToSend,
      } as any)
    },
    [account?.email, account?.default_company?.name],
  )

  const trackPageView = useCallback(
    pageTitle => {
      const formattedPathname = transformPathname(pathnameRef.current)
      const options = {
        user_id: account?.id,
        dimension1: account?.email,
        dimension2: account?.default_company?.name,
        currency: account?.currency?.code,
        site_url: window.location.hostname,
      } as any

      googleAnalytics.config(options)

      if (formattedPathname !== '/') {
        googleAnalytics.trackPageView({
          path: formattedPathname,
          page_title: pageTitle,
          ...options,
        })
      }
    },
    [account?.email, account?.default_company?.name],
  )

  return { trackEvent, trackPageView }
}

export default useTracking
