import cx from 'classnames'
import { FC, SVGProps } from 'react'

import BenchmarkIcon from 'src/components/ui/atoms/Icons/BenchmarkIcon'
import Deal from 'src/components/ui/atoms/Icons/Deal'
import PriceIcon from 'src/components/ui/atoms/Icons/Price'
import ReportIcon from 'src/components/ui/atoms/Icons/Report'
import SafetyBuoyIcon from 'src/components/ui/atoms/Icons/SafetyBuoyIcon'
import { Product } from 'src/utils/product'

import * as styles from './ProductIcon.scss'

export interface ProductIconProps extends SVGProps<SVGSVGElement> {
  productCode: Product | 'sell' | 'highlights'
  isActive?: boolean
}

const ProductIcon: FC<ProductIconProps> = ({ productCode, isActive, className, ...svgProps }) => {
  const classes = cx(styles.ProductIcon, { [styles.ProductIconActive]: isActive }, className)

  switch (productCode) {
    case 'report':
      return <ReportIcon className={classes} {...svgProps} />
    case 'price':
    case Product.PbsYield:
      return <PriceIcon className={classes} {...svgProps} />
    case 'sell':
      return <BenchmarkIcon className={classes} {...svgProps} />
    case 'highlights':
      return <BenchmarkIcon className={classes} {...svgProps} />
    case 'benchmark':
      return <BenchmarkIcon className={classes} {...svgProps} />
    case Product.MARKET_INTEL:
      return <BenchmarkIcon className={classes} {...svgProps} />
    case 'troubleshoot':
      return <SafetyBuoyIcon className={classes} {...svgProps} />
    case 'deal':
      return <Deal className={classes} {...svgProps} />
    case Product.DEAL_V2:
      return <Deal className={classes} {...svgProps} />
    default:
      return null
  }
}

ProductIcon.defaultProps = {
  isActive: false,
}

export default ProductIcon
