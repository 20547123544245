import { useMemo } from 'react'

import { matchProduct } from 'src/utils'
import { PRODUCT } from 'src/utils/constant'
import useProductPage from './useProductPage'

/**
 * Is benchmark or market intel
 */
const useIsMarketIntel = () => {
  const { product } = useProductPage()
  const useIsMarketIntel = useMemo(() => matchProduct(product, PRODUCT.market_intel), [product])
  return useIsMarketIntel
}

export default useIsMarketIntel
