import { lazy } from 'react'

import { CommonPage } from 'src/utils/product'

const selfMappingServiceRoutes = [
  {
    path: `/${CommonPage.SelfServiceMapping}`,
    component: lazy(
      () =>
        /* webpackMode: "lazy", webpackChunkName: "self_service_mapping_page" */
        import('src/components/ui/pages/common/SSMPage'),
    ),
  },
]

export default selfMappingServiceRoutes
