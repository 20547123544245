import cx from 'classnames'
import { isUndefined } from 'lodash'
import { FC, ReactNode } from 'react'

import ComponentColor from 'src/types/ItemColor'

import * as styles from './ToggleButton.scss'

interface ToggleButtonProps {
  children: ReactNode
  id: string
  name: string
  onClick: () => void
  checked: boolean
  readOnly?: boolean
  input?: any
  className?: string
  classes?: { wrapper: string; radio: string; label: string }
  componentColor?: ComponentColor
}

const ToggleButton: FC<ToggleButtonProps> = ({
  children,
  id,
  name,
  className,
  onClick,
  checked,
  readOnly,
  input,
  componentColor,
  classes,
}) => (
  <div className={cx(classes?.wrapper ? classes?.wrapper : className)}>
    <input
      {...input}
      type="radio"
      value={id}
      name={name}
      className={cx(
        {
          [styles.Radio]: isUndefined(classes?.radio),
        },
        classes?.radio,
      )}
      readOnly={readOnly}
      checked={checked}
    />
    <label
      htmlFor={id}
      className={cx(
        {
          [styles.Label]: isUndefined(classes?.label),
          [styles.RadioLabelHover]: !checked && componentColor !== ComponentColor.orange,
          [styles.RadioLabelHoverOrange]: !checked && componentColor === ComponentColor.orange,
          [styles.RadioOrange]: checked && componentColor === ComponentColor.orange,
        },
        classes?.label,
      )}
      onClick={onClick}
    >
      {children}
    </label>
  </div>
)

export default ToggleButton
