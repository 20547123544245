export const snakeToCamel = (str: string): string =>
  str.replace(/([-_][a-z])/g, group => group.toUpperCase().replace('-', '').replace('_', ''))

export const slugify = (string: string): string => {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

export const truncate = (str: string, length: number, to = length, truncateStr = '...'): string =>
  str.length > length ? `${str.substring(0, to)}${truncateStr}` : str

export const getTruncatedLabelWithTruncateStrFn = ({
  label,
  lastSlicedIndex,
  maxNumberOfCharacter,
  truncateStr: truncateStrArg,
}: {
  label: string
  maxNumberOfCharacter: number
  lastSlicedIndex: number
  truncateStr?: string
}) => {
  if (label.length < maxNumberOfCharacter) {
    return label
  }
  const truncateStr = truncateStrArg || '...'
  return `${label.slice(0, maxNumberOfCharacter)}${truncateStr}${label.slice(-lastSlicedIndex)}`
}

/**
 *
 * @param string value
 */
export const capitalize = (value: string): string => value.charAt(0).toUpperCase() + value.slice(1)

/**
 * Build SSM label/name
 * @param string label
 * @param number max
 * @param number slicedStart
 * @param number slicedLast
 */
export const buildSSMLabel = (label: string, max = 45, slicedStart = 35, slicedLast = 10) => {
  const shouldBeTruncate = label.length > max
  const startOfLabel = label?.slice(0, label.length - slicedLast)
  const endOfLabel = label?.slice(-slicedLast)
  const truncatedLabel = shouldBeTruncate
    ? `${truncate(startOfLabel, slicedStart, slicedStart, '...')}${endOfLabel}`
    : label

  return truncatedLabel
}
