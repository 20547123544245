import cx from 'classnames'
import { FC, HTMLProps } from 'react'

import * as styles from './Modal.scss'

interface ModalHeaderProps extends HTMLProps<HTMLDivElement> {
  isColoredHeader?: boolean
}

const ModalHeader: FC<ModalHeaderProps> = ({
  children,
  className,
  isColoredHeader,
  ...divProps
}) => (
  <div
    className={cx(styles.Header, { [styles.HeaderColored]: isColoredHeader }, className)}
    data-testid="modal-header"
    {...divProps}
  >
    {children}
  </div>
)

export default ModalHeader
