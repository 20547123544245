import { FC } from 'react'

import { SettingsCtx } from 'src/components/data/providers/SettingsProvider'
import { parseURL } from 'src/utils/urls'
import SeparatorVertical from '../../atoms/SeparatorVertical'
import Typography from '../../atoms/Typography'

import * as styles from './Header.scss'

export interface HeaderProductTitleProps {
  pathname: string
  settings: Pick<SettingsCtx, 'elementsByCode'>
}

const HeaderProductTitle: FC<HeaderProductTitleProps> = ({ pathname, settings }) => {
  const { product: productFormPathname, page, program } = parseURL(pathname)

  const elementCode = page || productFormPathname
  const programCode = program ? `-${program}` : ''

  const element = settings.elementsByCode[`${elementCode}${programCode}`]

  if (!element) {
    return null
  }

  const programName = element.program?.label || ''

  return (
    <div className={styles.HeaderProductTitle}>
      <Typography
        className={styles.HeaderProductTitleLabel}
        component="span"
        variant="title2"
        data-testid="header-product-title"
      >
        {element.parentName} {programName}
      </Typography>
      <SeparatorVertical className={styles.HeaderProductTitleSeparator} />
    </div>
  )
}

export default HeaderProductTitle
