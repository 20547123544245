import { useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { removeNullProperties } from 'src/utils/object'
import { generate } from 'src/utils/urls'
import { parse, QueryParams } from 'src/utils/urlUtils'

const useQueryParams = <T = any>() => {
  const location = useLocation()
  const history = useHistory()
  const queryParams = parse<T>(history.location.search)

  const setQueryParams = useCallback(
    (nextQueryParams: QueryParams | ((qp: QueryParams) => QueryParams), append = true) => {
      history.push(
        generate(
          removeNullProperties(
            append
              ? {
                  ...parse(history.location.search),
                  ...nextQueryParams,
                }
              : nextQueryParams,
          ),
          location.pathname,
        ),
      )
    },
    [history, location.pathname],
  )

  return { queryParams, setQueryParams }
}

export default useQueryParams
