import cx from 'classnames'
import React, { ReactNode } from 'react'

import * as styles from './TextArea.scss'

interface Props extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  /**
   * Icon
   */
  icon?: ReactNode
  classes?: {
    root?: string
    textArea?: string
  }
  value: string
  className?: string
  maxLength?: number
  count?: boolean
}

const TextArea = React.memo(
  React.forwardRef((props: Props, ref?: any) => {
    const { className, classes = {}, type, icon, value, maxLength, count, ...othersProps } = props

    return (
      <div className={cx(styles.TextAreaWrapper, className, classes.root)}>
        <textarea
          ref={ref}
          value={value}
          className={cx(styles.TextArea, classes.textArea, { [styles.TextAreaIcon]: icon })}
          data-testid="text-area"
          {...othersProps}
          maxLength={maxLength}
        />
        {icon && <div className={styles.Icon}>{icon}</div>}
        {count && (
          <span className={styles.ValueLength}>
            {value.length}/{maxLength}
          </span>
        )}
      </div>
    )
  }),
)

export default TextArea
