import { GOOGLE_ANALYTICS_MEASUREMENT_ID } from 'src/utils/config'

const GOOGLE_ANALYTICS_URL = 'https://www.googletagmanager.com/gtag/js?id='

export class GoogleAnalytics {
  measurementId: string
  isLoaded: boolean

  constructor(measurementId: string) {
    this.measurementId = measurementId
    this.isLoaded = false
  }

  _initGaFunction = () => {
    if (!window.gtag) {
      window.dataLayer = window.dataLayer || []
      window.gtag = function gtag() {
        // eslint-disable-next-line prefer-rest-params
        window.dataLayer.push(arguments)
      }
    }
  }

  gtag = (...args: any) => {
    this._initGaFunction()
    window.gtag(...args)
  }

  initialize = ({
    userEmail,
    sellerId,
    userId,
  }: {
    userEmail?: string
    sellerId?: string
    userId?: number
  }) => {
    if (!this.isLoaded && this.measurementId) {
      this.isLoaded = true
      const script = document.createElement('script')
      script.async = true
      script.src = `${GOOGLE_ANALYTICS_URL}${this.measurementId}`
      document.body.appendChild(script)

      this._initGaFunction()
      this.isLoaded = true
      this.gtag('js', new Date())
      this.gtag('config', this.measurementId, {
        user_id: userId,
        send_page_view: false,
        site_url: window.location.hostname,
        dimension1: userEmail,
        dimension2: sellerId,
      })
    }
  }

  event = ({ action, category, label, value, ...others }) => {
    this.gtag('event', action, {
      event_category: category,
      event_label: label,
      value,
      ...others,
    })
  }

  trackPageView = ({ path, ...others }: Record<string, any> & { path: string }) => {
    this.gtag('event', 'page_view', {
      page_path: path,
      ...others,
    })
  }

  config = (options: Record<string, any>) => {
    this.gtag('config', this.measurementId, options)
  }
}
const googleAnalytics = new GoogleAnalytics(GOOGLE_ANALYTICS_MEASUREMENT_ID)

export default googleAnalytics
