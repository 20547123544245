import cx from 'classnames'
import React, { FC, HTMLProps, ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { MdMoreVert } from 'react-icons/md'

import useHover from 'src/hooks/useHover'
import useWindowSize from 'src/hooks/useWindowSize'
import PopupFixedPropsInterface from 'src/utils/popup/PopupFixedPropsInterface'
import { getElLocationStyles } from 'src/utils/popupUtils'
import PopupButtonProvider from './PopupButtonProvider'

import * as styles from './PopupButton.scss'

interface PopupButtonProps {
  loading?: boolean
  title?: string
  disabled?: boolean
  buttonComponent?: ReactElement<{ toggled: boolean; loading: boolean }>
  popupComponent: ReactElement<{ toggled: boolean; togglePopup: VoidFunction }>
  className?: string
  classes?: {
    Popup?: string
    divHover?: string
  }
  popupFixedProps?: PopupFixedPropsInterface
  togglePopup?: (isOpen: boolean) => void
  isLazy?: boolean
  isVisible?: boolean | null
  buttonProps?: HTMLProps<HTMLDivElement> & { 'data-testid'?: string }
  buttonClassName?: string
  closeOnScroll?: boolean
  needToPassChildToggleProps?: boolean
  openOnHover?: boolean // Open popup on hover
}

const PopupButton: FC<PopupButtonProps> = ({
  loading = false,
  title,
  popupComponent,
  buttonComponent,
  disabled,
  classes = {},
  className,
  popupFixedProps,
  togglePopup: togglePopupProps,
  isLazy = false,
  isVisible = null,
  buttonProps,
  buttonClassName,
  closeOnScroll,
  needToPassChildToggleProps,
  openOnHover,
}) => {
  const [isOpenState, setIsOpenState] = useState(false)
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const buttonRef = useRef<HTMLDivElement | null>(null)

  const { hoverProps: componentHoverProps, isHovered: componentHovered } = useHover()

  useEffect(() => {
    if (isVisible === false) {
      setIsOpenState(false)
    }
  }, [isVisible])

  const isOpen = openOnHover ? componentHovered : isOpenState

  const handleClickOutside = useCallback(
    event => {
      if (
        wrapperRef.current &&
        buttonRef.current &&
        !wrapperRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target) &&
        isOpenState
      ) {
        setIsOpenState(false)
      }
    },
    [wrapperRef, buttonRef, isOpen],
  )

  useEffect(() => {
    if (togglePopupProps) {
      togglePopupProps(isOpen)
    }
  }, [isOpen])

  const togglePopup = () => {
    setIsOpenState(currentIsOpen => !currentIsOpen)
  }

  const handleTogglePopup = useCallback((isOpenArg: boolean) => {
    setIsOpenState(isOpenArg)
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    if (closeOnScroll) {
      document.addEventListener('scroll', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      if (closeOnScroll) {
        document.removeEventListener('scroll', handleClickOutside)
      }
    }
  }, [handleClickOutside, closeOnScroll])

  const buttonClasses = cx(styles.Button, {
    [styles.ButtonActive]: isOpen,
    [styles.ButtonLoading]: loading,
  })

  const boxClasses = cx(
    {
      [styles.Box]: true,
      [styles.BoxOpenOnHover]: openOnHover,
      [styles.BoxOpen]: isOpen,
    },
    classes.Popup,
  )

  const { width } = useWindowSize()

  const fixedStyles = getElLocationStyles(popupFixedProps, buttonRef, width)
  const { hoverProps, isHovered } = useHover()

  const itemProps = needToPassChildToggleProps
    ? {
        toggled: isOpen,
        togglePopup,
      }
    : {}

  return (
    <PopupButtonProvider isHovered={isHovered} isOpen={isOpen} toggle={handleTogglePopup}>
      <div
        {...componentHoverProps}
        className={cx(styles.PopupButton, className)}
        title={title}
        data-testid="popup-button"
      >
        <div
          role="button"
          tabIndex={0}
          onClick={event => {
            event.stopPropagation()
            if (disabled !== true) {
              togglePopup()
            }
          }}
          ref={buttonRef}
          className={cx(
            styles.ButtonWrapper,
            { [styles.ButtonWrapperDisabled]: disabled },
            buttonClassName,
          )}
          aria-label={`${title} button`}
          {...hoverProps}
          {...buttonProps}
        >
          {buttonComponent ? (
            React.cloneElement(buttonComponent, itemProps)
          ) : (
            <div className={buttonClasses}>
              <MdMoreVert />
            </div>
          )}
        </div>
        <div className={boxClasses} ref={wrapperRef} style={fixedStyles}>
          {openOnHover && isOpen && (
            <div className={cx({ [styles.BoxDivHover]: true }, classes?.divHover)} />
          )}
          {isLazy && !isOpen ? null : React.cloneElement(popupComponent, itemProps)}
        </div>
      </div>
    </PopupButtonProvider>
  )
}

PopupButton.defaultProps = {
  needToPassChildToggleProps: true,
}

export default PopupButton
