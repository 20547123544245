import { useMemo } from 'react'

import useSettings from './useSettings'

// For a component code, return its config
const findComponent = (
  componentsTree: ReadonlyArray<object>,
  key: string,
  value: any,
  componentIdentifier?: string,
): object | undefined => {
  let component

  const find = (treeComponent: any) => {
    if (
      treeComponent[key] === value &&
      (!componentIdentifier || treeComponent.component_identifier === componentIdentifier)
    ) {
      component = treeComponent
      return true
    }
    return Array.isArray(treeComponent.children) && treeComponent.children.some(find)
  }

  componentsTree.some(find)
  return component
}

const useComponentConfig = <T,>(componentCode?: string, componentIdentifier?: string): T => {
  const { components } = useSettings()

  const config = useMemo(() => {
    if (components === undefined) {
      return null
    }
    if (!componentCode) return {}

    const component = findComponent(
      Object.values(components),
      'code',
      componentCode,
      componentIdentifier,
    )

    const available_period = (component as any)?.period_availability // ex available_period
    const availablePeriodConfig = available_period ? { available_period } : {}

    return (
      component && {
        ...availablePeriodConfig,
        ...((component as any).config || {}),
        ...((component as any).data ? { data: (component as any).data } : {}),
        ...((component as any).current_month_available !== undefined
          ? { current_month_available: (component as any).current_month_available }
          : {}),
      }
    )
  }, [componentCode, componentIdentifier, components])

  return config
}

export default useComponentConfig
