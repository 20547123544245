import { Icon } from './Icons.scss'

const CompareIcon = props => (
  <svg className={Icon} height="2em" width="2em" viewBox="-5 -10 55 55" {...props}>
    <g data-name="Layer 2">
      <g data-name="Layer 4">
        <path d="M1 5.76a1 1 0 0 1-1-1A4.7 4.7 0 0 1 4.64 0a1 1 0 0 1 0 2A2.7 2.7 0 0 0 2 4.76a1 1 0 0 1-1 1zm41.82 25.68a1 1 0 0 1 0-2 2.71 2.71 0 0 0 2.64-2.75 1 1 0 0 1 2 0 4.7 4.7 0 0 1-4.64 4.75zm-38.18 0A4.7 4.7 0 0 1 0 26.69a1 1 0 0 1 2 0 2.7 2.7 0 0 0 2.64 2.75 1 1 0 0 1 0 2zM46.46 5.76a1 1 0 0 1-1-1A2.71 2.71 0 0 0 42.82 2a1 1 0 0 1 0-2 4.7 4.7 0 0 1 4.64 4.76 1 1 0 0 1-1 1z" />
        <path d="M46.46 27.69a1 1 0 0 1-1-1V4.76a1 1 0 1 1 2 0v21.93a1 1 0 0 1-1 1zm-3.64 3.75H4.64a1 1 0 1 1 0-2h38.18a1 1 0 0 1 0 2zM1 27.69a1 1 0 0 1-1-1V4.76a1 1 0 0 1 2 0v21.93a1 1 0 0 1-1 1zM42.82 2H4.64a1 1 0 0 1 0-2h38.18a1 1 0 0 1 0 2z" />
        <path d="M1 5.76a1 1 0 0 1-1-1A4.7 4.7 0 0 1 4.64 0a1 1 0 0 1 0 2A2.7 2.7 0 0 0 2 4.76a1 1 0 0 1-1 1zm3.64 25.68A4.7 4.7 0 0 1 0 26.69a1 1 0 0 1 2 0 2.7 2.7 0 0 0 2.64 2.75 1 1 0 0 1 0 2zM46.46 5.76a1 1 0 0 1-1-1A2.71 2.71 0 0 0 42.82 2a1 1 0 0 1 0-2 4.7 4.7 0 0 1 4.64 4.76 1 1 0 0 1-1 1zm-3.64 25.68a1 1 0 0 1 0-2 2.71 2.71 0 0 0 2.64-2.75 1 1 0 0 1 2 0 4.7 4.7 0 0 1-4.64 4.75zm-6.54 5.68h-25.1a1 1 0 0 1 0-2h25.1a1 1 0 0 1 0 2z" />
        <path d="M18.82 36.82a1 1 0 0 1-1-1v-4.89a1 1 0 0 1 2 0v4.89a1 1 0 0 1-1 1zm9.82 0a1 1 0 0 1-1-1v-4.89a1 1 0 0 1 2 0v4.89a1 1 0 0 1-1 1zM10.76 23a.5.5 0 0 1-.35-.85l10-9.95a.48.48 0 0 1 .7 0l5.78 5.8 7.8-7.8a.5.5 0 0 1 .71 0 .51.51 0 0 1 0 .71l-8.16 8.15a.5.5 0 0 1-.71 0l-5.82-5.82-9.59 9.59a.51.51 0 0 1-.36.17z" />
        <path d="M36.69 12.73a.52.52 0 0 1-.35-.14L33 9.28a.47.47 0 0 1-.11-.54.5.5 0 0 1 .46-.31h3.3a.5.5 0 0 1 .5.5v3.3a.51.51 0 0 1-.3.47.69.69 0 0 1-.16.03zm-2.09-3.3L36.19 11V9.43z" />
      </g>
    </g>
  </svg>
)

export default CompareIcon
