type CurrencyCode = {
  [key: string]: string
}

const currencyLocales: CurrencyCode = {
  ARS: 'en',
  AUD: 'en-au',
  BRL: 'pt-br',
  CAD: 'fr-ca',
  CHF: 'fr-ch',
  CNY: 'chs',
  COP: 'en-co',
  DKK: 'da-dk',
  EUR: 'fr',
  GBP: 'en-gb',
  IDR: 'rp',
  ILS: 'ls',
  INR: 'ir',
  JPY: 'ja',
  MXN: 'mex',
  MYR: 'rm',
  NOK: 'no',
  NZD: 'en-nz',
  PHP: 'ph',
  RUB: 'ru',
  SEK: 'sek',
  SGD: 'en-sg',
  THB: 'th',
  TRY: 'tr',
  TWD: 'en-tw',
  USD: 'usa',
  KRW: 'sk',
  ALL: 'lek',
  BAM: 'bam',
  BGN: 'bg',
  CZK: 'cs',
  HRK: 'kn',
  HUF: 'hu',
  MKD: 'en',
  PLN: 'pl',
  RON: 'ron',
  RSD: 'ser',
  UAH: 'ru-ua',
  VND: 'vi',
  PEN: 'sol',
  HKD: 'en-hk',
  ZAR: 'en-za',
  AED: 'aed',
  AOA: 'kz',
  BDT: 'bt',
  CLP: 'en',
  DZD: 'dz',
  EGP: 'egy',
  GTQ: 'gtm',
  JMD: 'en-jm',
  KES: 'ksh',
  KWD: 'kow',
  KZT: 'kzt',
  MAD: 'ma',
  NGN: 'nig',
  PKR: 'pk',
  QAR: 'qa',
  SAR: 'sa',
  UYU: 'en-ur',
  TND: 'tn',
}

export default currencyLocales
