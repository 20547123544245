import cx from 'classnames'
import { FC, HTMLAttributes } from 'react'

import * as styles from './NotificationBadgeNumber.scss'

export interface NotificationBadgeNumberProps extends HTMLAttributes<HTMLDivElement> {
  notificationCount: number
}

const NotificationBadgeNumber: FC<NotificationBadgeNumberProps> = ({
  notificationCount,
  className,
  ...divProps
}) => {
  if (notificationCount < 1) return null

  return (
    <div
      data-testid="notification-badge-number"
      className={cx(styles.NotificationBadgeNumber, className)}
      {...divProps}
    >
      {notificationCount} New Dashboard(s) shared with you.
    </div>
  )
}

export default NotificationBadgeNumber
