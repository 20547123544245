import { Icon } from './Icons.scss'

const StackIcon = props => (
  <svg className={Icon} height="2em" width="2em" viewBox="-5 -7 50 50" {...props}>
    <g data-name="Layer 2">
      <g data-name="Layer 4">
        <path d="M40.93 33H6.13a1 1 0 0 1-1-1V1a1 1 0 0 1 2 0v13.3l8.53-7.47a1 1 0 0 1 1.45.17l8 10.35 10-5.13a1 1 0 0 1 1.17.2l5.36 5.5a.94.94 0 0 1 .29.69V32a1 1 0 0 1-1 1zm-33.8-2h32.8V19l-4.56-4.68-10.1 5.16A1 1 0 0 1 24 19.2L16.18 9l-9.05 8z" />
        <path d="M6.13 33H1a1 1 0 0 1 0-2h5.13a1 1 0 0 1 0 2z" />
        <path d="M6.13 37.59a1 1 0 0 1-1-1V32a1 1 0 1 1 2 0v4.57a1 1 0 0 1-1 1.02zm34.8-10.05a.51.51 0 0 1-.36-.15l-5.36-5.5a.5.5 0 1 1 .72-.7l5.36 5.5a.51.51 0 0 1 0 .71.54.54 0 0 1-.36.14z" />
        <path d="M24.82 27.54a.5.5 0 0 1-.23-.95l10.75-5.5a.51.51 0 0 1 .67.22.49.49 0 0 1-.21.67l-10.75 5.5a.49.49 0 0 1-.23.06z" />
        <path d="M24.82 27.54a.51.51 0 0 1-.4-.2l-8.5-11a.5.5 0 0 1 .79-.61l8.5 11a.49.49 0 0 1-.09.7.48.48 0 0 1-.3.11z" />
        <path d="M6.13 25.47a.52.52 0 0 1-.38-.17.51.51 0 0 1 0-.71L16 15.66a.5.5 0 0 1 .66.76l-10.2 8.92a.46.46 0 0 1-.33.13z" />
      </g>
    </g>
  </svg>
)

export default StackIcon
