const DownloadPdfIcon = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="107" height="30" viewBox="0 0 107 30" {...props}>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-762.000000, -393.000000) translate(0.000000, 255.000000) translate(0.000000, 123.000000) translate(340.000000, 0.000000) translate(18.000000, 15.000000) translate(184.000000, 0.000000) translate(220.000000, 0.000000)">
        <text
          fill="currentColor"
          fontFamily="AvenirNext-Medium, Avenir Next"
          fontSize="14"
          fontWeight="400"
        >
          <tspan x="29" y="20">
            Download
          </tspan>
        </text>
        <g>
          <path d="M0 0L16 0 16 16 0 16z" transform="translate(8.000000, 6.000000)" />
          <path
            fill="currentColor"
            fillRule="nonzero"
            d="M12.109 5.571h-1.422V1.93c0-.511-.403-.929-.895-.929H6.215c-.492 0-.895.418-.895.929V5.57H3.898c-.796 0-1.199 1.003-.635 1.588l4.105 4.262c.35.363.913.363 1.261 0l4.106-4.262c.563-.585.17-1.588-.626-1.588zM1 13.93c0 .51.45.928 1 .928h12c.55 0 1-.418 1-.928 0-.511-.45-.929-1-.929H2c-.55 0-1 .418-1 .929z"
            transform="translate(8.000000, 6.000000)"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default DownloadPdfIcon
