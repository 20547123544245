import { FC, SVGProps } from 'react'

const BenchmarkIcon: FC<SVGProps<SVGSVGElement>> = svgProps => (
  <svg height="24" width="24" viewBox="0 0 600 600" {...svgProps}>
    <path
      fill="currentColor"
      d="M395.58,144c-3.34-1.93-7.28-1.81-9.56.3,0,0-120.78,102.31-136.78,130S205,457.79,205,457.79c-.68,3,1.18,6.5,4.52,8.43s7.28,1.8,9.55-.31c0,0,120.78-102.3,136.78-130s44.21-183.47,44.21-183.47C400.77,149.4,398.91,145.92,395.58,144Zm-61,161.11a32,32,0,1,1-32-32A32,32,0,0,1,334.55,305.11Z"
    />
    <path
      fill="currentColor"
      d="M303.51,14.42c-159.66,0-289.09,129.43-289.09,289.09s129.43,289.1,289.09,289.1,289.1-129.43,289.1-289.1S463.18,14.42,303.51,14.42Zm0,510.68c-122.38,0-221.58-99.21-221.58-221.59S181.13,81.93,303.51,81.93,525.1,181.13,525.1,303.51,425.89,525.1,303.51,525.1Z"
    />
  </svg>
)

export default BenchmarkIcon
