import { lazy } from 'react'

import AppRouteInterface from 'src/types/AppRouteInterface'

const publicRoutes: ReadonlyArray<AppRouteInterface> = [
  {
    path: '/login',
    component: lazy(
      () =>
        /* webpackMode: "lazy", webpackChunkName: "login_page" */
        import('src/components/ui/pages/public/LoginPage'),
    ),
  },
  {
    path: '/confirmation',
    component: lazy(
      () =>
        /* webpackMode: "lazy", webpackChunkName: "login_confirmation_page" */
        import('src/components/ui/pages/public/PasswordConfirmationPage'),
    ),
  },
  {
    path: '/forgotten',
    component: lazy(
      () =>
        /* webpackMode: "lazy", webpackChunkName: "login_forgotten_page" */
        import('src/components/ui/pages/public/ForgottenPasswordPage'),
    ),
  },
]

export default publicRoutes
