import { Field } from 'redux-form'

import TextArea from '../TextArea'

const TextAreaField = ({
  name,
  className,
  maxLength,
  count,
  'data-testid': dataTestId,
}: {
  name: string
  className?: string
  maxLength?: number
  count?: boolean
  'data-testid'?: string
}) => (
  <Field
    name={name}
    component={({ input: { value, onChange } }: any) => (
      <div className={className}>
        <TextArea
          value={value}
          onChange={onChange}
          maxLength={maxLength}
          count={count}
          data-testid={dataTestId}
        />
      </div>
    )}
  />
)

export default TextAreaField
