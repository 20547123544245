import { FC } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'

import InfoIcon from 'src/components/ui/atoms/Icons/InfoIcon'
import SearchDropdown, { Option } from 'src/components/ui/molecules/SearchDropDown'
import useAccount from 'src/hooks/useAccount'
import useSettings from 'src/hooks/useSettings'
import useTracking from 'src/hooks/useTracking'
import {
  ComponentsSettingsActionsProps,
  resetComponentsSettings,
  resetSettingsLoadedAction,
} from 'src/redux/actions/componentsSettingsActions'
import { resetLoadingComponents, RootActionsProps } from 'src/redux/actions/rootActions'
import { ComponentsSettingsStoreProps } from 'src/redux/reducers/componentsSettingsReducer'
import { RootStoreProps } from 'src/redux/reducers/root'
import { RootState } from 'src/redux/store'
import { Company, Profile } from 'src/utils/account'

import * as styles from './AccountProfileButtonDropdown.scss'

export const DropDownList = ({
  input: { onChange, name },
  items,
  defaultValue,
  renderItem,
  renderOption,
}: any) => (
  <SearchDropdown
    items={items}
    defaultValue={defaultValue}
    selectItem={onChange}
    renderItem={renderItem}
    renderOption={renderOption}
    isSearchable
    name={name}
  />
)

interface AccountProfileButtonDropdownProps {
  companiesList: Array<Company>
  profilesList: Array<Profile>
  currentProfile: Profile
  defaultCompany: Company
  hasCompanies: boolean
  hasProfile: boolean
}

const AccountProfileButtonDropdown: FC<AccountProfileButtonDropdownProps> = ({
  companiesList,
  profilesList,
  currentProfile,
  defaultCompany,
  hasCompanies,
  hasProfile,
  ...rest
}) => {
  const { changeSeller, changeProfile } = useAccount()
  const { trackEvent } = useTracking()
  const { clearQueryParams } = useSettings()
  const props = {
    ...rest,
  } as ComponentsSettingsActionsProps &
    ComponentsSettingsStoreProps &
    RootStoreProps &
    RootActionsProps

  const onSubmit = async (data: any) => {
    const { company, profile } = data
    const promises: Promise<any>[] = []
    const cb = () => {
      clearQueryParams({ from: '' })
      props.resetLoadingComponents()
      props.resetComponentsSettings()
      props.resetSettingsLoaded()
    }
    if (company && company.id !== defaultCompany.id) {
      promises.push(changeSeller(company.id, cb))
      trackEvent({ action: 'Selected account', label: company.name, category: 'Header' })
    }
    if (profile && profile.id !== currentProfile.id) {
      promises.push(changeProfile(profile.id, cb))
      trackEvent({ action: 'Selected profile', label: profile.name, category: 'Header' })
    }
    await Promise.all(promises)
  }

  const renderOption = (item: any) => <Option item={item} />

  const FieldInput: FC<{
    name: string
    items: Profile[] | Company[]
    defaultValue: string
  }> = ({ name, items, defaultValue }) => {
    return (
      <Field
        name={name}
        // Force form submit on company change
        onChange={(value: any) => onSubmit({ [name]: value })}
        component={DropDownList}
        items={items}
        defaultValue={defaultValue}
        renderOption={renderOption}
      />
    )
  }

  return (
    <div className={styles.AccountProfileButtonDropdown}>
      <form noValidate style={{ width: '100%' }}>
        <div className={styles.Dropdown}>
          <div className={styles.Title}>Account</div>
          {hasCompanies ? (
            <FieldInput name="company" items={companiesList} defaultValue={defaultCompany.name} />
          ) : (
            <div className={styles.Default}>{defaultCompany.name}</div>
          )}
        </div>
        <div className={styles.Dropdown}>
          <div className={styles.Title}>Profile</div>
          {hasProfile ? (
            <FieldInput name="profile" items={profilesList} defaultValue={currentProfile.name} />
          ) : currentProfile.master ? (
            <div className={styles.NoProfile}>
              <InfoIcon />
              <div className={styles.NoProfileText}>
                Create profiles to customize your experience.
                <br />
                Reach out to your CSM for activation.
              </div>
            </div>
          ) : (
            <div className={styles.Default}>{currentProfile.name}</div>
          )}
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  settings: state.componentsSettings.settings,
  oldSettings: state.componentsSettings.oldSettings,
})
const mapDispatchToProps = {
  resetComponentsSettings,
  resetSettingsLoaded: resetSettingsLoadedAction,
  resetLoadingComponents,
}

export default reduxForm({
  form: 'profileForm',
})(connect(mapStateToProps, mapDispatchToProps)(AccountProfileButtonDropdown))
