import useAccount from '../useAccount'

const useHasMappingRightAccess = (isAdomikOnly?: boolean) => {
  const {
    informations: { mapping_right },
  } = useAccount()

  if (isAdomikOnly) {
    return mapping_right === 'adomik'
  }

  return mapping_right === 'adomik' || mapping_right === 'write'
}

export default useHasMappingRightAccess
