import { JSXElementConstructor, PropsWithChildren, ReactNode } from 'react'

interface Props {
  components: Array<JSXElementConstructor<PropsWithChildren<any>>>
  children: ReactNode
}

const Compose = ({ components = [], children }: Props) => (
  <>
    {components.reduceRight((acc, Comp: any) => {
      return <Comp>{acc}</Comp>
    }, children)}
  </>
)

export default Compose
