import { useAppSelector } from 'src/redux/hooks'
import { ComponentCode } from 'src/utils/settings'
import useProductPage from './useProductPage'

type SubSettings = 'tables' | 'charts'

export const useComponentCodeStateSettings = (
  componentCode: ComponentCode,
  componentItem?: SubSettings,
) => {
  const { dashboardKey } = useProductPage()

  const settings = useAppSelector(s => {
    if (!componentItem) {
      return s.componentsSettings.settings?.[dashboardKey]?.[componentCode]
    }
    return s.componentsSettings.settings?.[dashboardKey]?.[componentCode]?.[componentItem]
  })

  return settings
}
