const Copy = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M0 0L16.146 0 16.146 16 0 16z"
        transform="translate(-580.000000, -1688.000000) translate(3.000000, 1121.000000) translate(261.500000, 402.000000) translate(25.000000, 11.000000) translate(0.000000, 11.000000) translate(265.000000, 143.000000) translate(26.237805, 0.000000)"
      />
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M10.304 1H3.293c-.701 0-1.275.573-1.275 1.273v8.272c0 .35.287.637.638.637.35 0 .637-.287.637-.637V2.91c0-.35.287-.636.637-.636h6.374c.35 0 .637-.287.637-.637 0-.35-.287-.636-.637-.636zm.376 2.92l3.078 3.075c.236.235.37.56.37.897v5.835c0 .7-.574 1.273-1.275 1.273H5.836c-.701 0-1.268-.573-1.268-1.273l.006-8.909c0-.7.567-1.273 1.268-1.273h3.933c.338 0 .663.134.905.376zM10.304 8h2.868L9.667 4.5v2.864c0 .35.286.636.637.636z"
        transform="translate(-580.000000, -1688.000000) translate(3.000000, 1121.000000) translate(261.500000, 402.000000) translate(25.000000, 11.000000) translate(0.000000, 11.000000) translate(265.000000, 143.000000) translate(26.237805, 0.000000)"
      />
    </g>
  </svg>
)

export default Copy
