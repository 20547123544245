const MetricBoxArrowUp = props => (
  <svg
    id="master-artboard"
    version="1.1"
    x="0px"
    height="0.65em"
    xmlns="http://www.w3.org/2000/svg"
    width="0.375em"
    viewBox="0 0 6 8"
    {...props}
  >
    <path
      fill="#75D052"
      fillRule="evenodd"
      d="M5 7v8c0 .6.4 1 1 1s1-.4 1-1V7h5L6 0 0 7h5z"
      transform="matrix(0.5, 0, 0, 0.5, 0, 0)"
    />
  </svg>
)

export default MetricBoxArrowUp
