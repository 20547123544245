import { createContext, FC, ReactNode, useContext } from 'react'

import { UserInterface } from 'src/types/UserInterface'

interface ShareDashboardProviderType {
  users: UserInterface[]
}

const ShareDashboardContext = createContext<ShareDashboardProviderType | undefined>(undefined)

interface ShareDashboardProviderProps extends ShareDashboardProviderType {
  children: ReactNode
}

const ShareDashboardProvider: FC<ShareDashboardProviderProps> = ({ children, ...values }) => {
  return <ShareDashboardContext.Provider value={values}>{children}</ShareDashboardContext.Provider>
}

const useShareDashboardContext = () => {
  const context = useContext(ShareDashboardContext)

  if (!context) {
    throw new Error('useShareDashboardContext must be used within a ShareDashboardProvider')
  }

  return context
}

export { useShareDashboardContext }

export default ShareDashboardProvider
