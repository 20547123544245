import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import { memo, useEffect, useState } from 'react'

import DropdownArrowIcon from 'src/components/ui/atoms/Icons/DropdownArrowIcon'
import PopupButton from 'src/components/ui/atoms/PopupButton'
import { usePopupButtonContext } from 'src/components/ui/atoms/PopupButton/PopupButtonProvider'
import SeparatorVertical from 'src/components/ui/atoms/SeparatorVertical/SeparatorVertical'
import Typography from 'src/components/ui/atoms/Typography/Typography'
import AccountProfileButtonDropdown from 'src/components/ui/organisms/AccountProfileButtonDropdown/AccountProfileButtonDropdown'
import useAccount from 'src/hooks/useAccount'
import useFetch from 'src/hooks/useFetch'
import usePrinter from 'src/hooks/usePrinter'
import { getApiUrl } from 'src/utils'
import { Company } from 'src/utils/account'

import * as styles from './AccountProfileButtonContainer.scss'

const AccountProfileButtonContainer = () => {
  const { printMode } = usePrinter()

  const [, executeGetCompanies] = useFetch<ReadonlyArray<Company>>({
    url: `${getApiUrl()}/api/admin/companies/seller_groups`,
    method: 'GET',
  })

  const [companiesList, setCompaniesList] = useState<ReadonlyArray<Company>>([])

  const { informations } = useAccount()
  const {
    profiles: profilesList,
    current_profile: currentProfile,
    default_company: defaultCompany,
  } = informations

  const getCompanies = async () => {
    const { data } = await executeGetCompanies()
    setCompaniesList(data)
  }

  useEffect(() => {
    getCompanies()
  }, [])

  const hasCompanies = !isEmpty(companiesList) && companiesList.length > 1
  const hasProfile = !isEmpty(profilesList) && profilesList.length > 1

  const ButtonComponent = ({ toggled }: { toggled?: boolean }) => {
    const { isHovered, isOpen } = usePopupButtonContext()

    const isHoveredOrOpen = isHovered || isOpen

    return (
      <div className={styles.rightContent}>
        <Typography
          variant="title2"
          className={cx(styles.rightContentProfile, {
            [styles.rightContentProfileHovered]: isHoveredOrOpen,
          })}
        >
          {defaultCompany.name}
        </Typography>
        {hasProfile && (
          <>
            <SeparatorVertical className={styles.rightContentSeparator} />
            <Typography
              variant="title2"
              className={cx(styles.rightContentProfile, {
                [styles.rightContentProfileHovered]: isHoveredOrOpen,
              })}
            >
              {currentProfile.name}
            </Typography>
          </>
        )}
        {!printMode && (
          <div className={styles.rightContentIcon}>
            <DropdownArrowIcon
              className={cx({
                [styles.rightContentIconHovered]: isHoveredOrOpen,
              })}
              direction={toggled ? 'up' : 'down'}
            />
          </div>
        )}
      </div>
    )
  }

  return (
    <PopupButton
      title="Account profile button"
      buttonComponent={<ButtonComponent />}
      popupComponent={
        <AccountProfileButtonDropdown
          companiesList={companiesList}
          profilesList={profilesList}
          currentProfile={currentProfile}
          defaultCompany={defaultCompany}
          hasCompanies={hasCompanies}
          hasProfile={hasProfile}
          history={history}
        />
      }
      className={styles.PopUp}
      classes={{
        Popup: styles.PopUpBox,
      }}
      popupFixedProps={{
        anchorOrigin: 'right',
      }}
      buttonProps={{
        'data-testid': 'account-profile-button',
      }}
    />
  )
}

export default memo(AccountProfileButtonContainer)
