import { Icon } from './Icons.scss'

const PercentIcon = props => (
  <svg className={Icon} height="2em" width="2em" viewBox="-7 -7 50 50" {...props}>
    <g data-name="Layer 2">
      <g data-name="Layer 4">
        <path d="M18.63 37.26a18.63 18.63 0 1 1 18.63-18.63 18.65 18.65 0 0 1-18.63 18.63zm0-35.26a16.63 16.63 0 1 0 16.63 16.63A16.65 16.65 0 0 0 18.63 2z" />
        <path d="M18.63 25.8a7.17 7.17 0 1 1 7.17-7.17 7.18 7.18 0 0 1-7.17 7.17zm0-12.34a5.17 5.17 0 1 0 5.17 5.17 5.18 5.18 0 0 0-5.17-5.17z" />
        <path d="M6.18 31.58a.51.51 0 0 1-.36-.15.5.5 0 0 1 0-.7l8.11-8.11a.5.5 0 0 1 .71 0 .51.51 0 0 1 0 .71l-8.11 8.1a.5.5 0 0 1-.35.15zm8.14-16.76a.51.51 0 0 1-.36-.15L5.81 6.52a.5.5 0 0 1 0-.71.51.51 0 0 1 .71 0L14.67 14a.5.5 0 0 1 0 .71.49.49 0 0 1-.35.11zM18.63 13a.5.5 0 0 1-.5-.5V1a.5.5 0 0 1 .5-.5.5.5 0 0 1 .5.5v11.46a.5.5 0 0 1-.5.54z" />
      </g>
    </g>
  </svg>
)

export default PercentIcon
