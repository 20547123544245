import { API } from './config'
import { SNOWFLAKE_PRODUCTS } from './constants/snowflakeProducts'
import { SubProduct } from './product'

/**
 * Get all snowflake products if env SNOWFLAKE_REPORTING_URL is set
 */
export const getSnowflakeProductsFn = () => {
  return SNOWFLAKE_PRODUCTS
}

/**
 * Check if sub product is snowflake product
 */
export const isSnowflakeProductFn = (code: SubProduct) => getSnowflakeProductsFn().includes(code)

export const getAdomikOriginFn = (code: string) => {
  return isSnowflakeProductFn(code as SubProduct) ? 'snowflake' : 'pg'
}

/**
 * Get snowflake api url
 * NOTE: Return data api url if SNOWFLAKE_REPORTING_URL is not set
 */
export const getProductApiUrlFn = (isSnowflakeProduct: boolean) =>
  isSnowflakeProduct ? API.SNOWFLAKE_REPORTING_URL || API.DATA : API.DATA
