export enum PageLoadingContextEnum {
  savedDashboard = 'saved dashboard',
  pdfPreview = 'pdf preview',
  scheduleDashboard = 'schedule dashboard',
  standardDashboard = 'standard dashboard',
}

export interface PageLoadingInterface {
  adomikNavigationId: string | null
  adomikPlatformContext: PageLoadingContextEnum | null
  adomikPlatformContextId: string | null
  globalRequestId: string | null
}
