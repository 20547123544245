import { lazy, memo, Suspense } from 'react'
import { Redirect, Switch, useLocation } from 'react-router-dom'

import AdomikLoader from 'src/components/ui/atoms/PlaceHolder/AdomikLoader'
import PrivateRoute from 'src/components/ui/atoms/Router/PrivateRoute'
import Layout from 'src/components/ui/molecules/Layout'
import NotFound from 'src/components/ui/organisms/NotFound'
import commonRoutes from 'src/routes/commonRoutes'
import productsRoutes from 'src/routes/productsRoutes'
import publicRoutes from 'src/routes/publicRoutes'
import Route from './components/ui/atoms/Router/Route'
import useAccount from './hooks/useAccount'
import useHasMappingRightAccess from './hooks/useHasMappingRightAccess'
import useSettings from './hooks/useSettings'
import selfMappingServiceRoutes from './routes/selfMappingServiceRoutes'
import { isOnAppRoutesFn } from './utils/routeUtils'

const LOGGED_ROUTES = [...productsRoutes, ...commonRoutes]
const RefinerLoader = lazy(() => import('./components/ui/molecules/RefinerLoader'))
const PendoLoader = lazy(() => import('./components/ui/molecules/PendoLoader'))

const AuthenticatedRouter = () => {
  const { pathname } = useLocation()
  const { elements } = useSettings()
  const { informations: account, isLogged } = useAccount()
  const hasMappingAccess = useHasMappingRightAccess()

  // Has right access to self service mapping page
  const selfMappingRoutes = hasMappingAccess ? selfMappingServiceRoutes : []

  // Check if the route typed in the url exist
  const allowedRoutes = [
    ...productsRoutes.filter(route => {
      const product = route.path.split('/')[1]
      const subProduct = route.path.split('/')[2]
      const program = route.path.split('/')[3]

      if (product && subProduct?.startsWith(':')) {
        return elements.some(element => {
          if (product !== element.code) {
            return false
          }
          if (program) {
            return element.children?.some(child => Boolean(child.program))
          }
          return Boolean(element.children)
        })
      }

      if (product && subProduct) {
        return elements
          .find(element => product === element.code)
          ?.children?.find((child: { code: string }) => child.code === subProduct)
      }

      return false
    }),
    ...commonRoutes,
    ...selfMappingRoutes,
  ]

  const currentPath =
    pathname.charAt(pathname.length - 1) === '/' ? pathname.slice(0, -1) : pathname

  // Check if the user has access to the route
  const isRouteNotAllowed =
    isOnAppRoutesFn(LOGGED_ROUTES, currentPath) && !isOnAppRoutesFn(allowedRoutes, currentPath)

  const isPublicRoute = publicRoutes.some(route => route.path === currentPath)
  let defaultRoute = allowedRoutes[0]?.path

  // If user tried to access a public route (login, confirmation password or forgotten password)
  // redirect him to logged route
  if (isPublicRoute) return <Redirect to={defaultRoute || '/account/user'} />
  // If route is not allowed or user has no profile, redirect him to account page
  if (!account.current_profile || isRouteNotAllowed) return <Redirect to="/account/user" />

  if (defaultRoute) {
    const subProduct = defaultRoute.split('/')[2]
    if (subProduct?.startsWith(':')) {
      const productCode = defaultRoute.split('/')[1]
      const product = elements.filter(e => e.code === productCode)?.[0]
      if (product) {
        const firstChild = product.children?.[0]
        if (firstChild) {
          const { code, program } = firstChild
          const programParam = program ? `/${program.id}` : ''
          defaultRoute = `/${productCode}/${code}${programParam}`
        }
      }
    }
  }

  return (
    <Layout>
      <Switch>
        {allowedRoutes.map(({ path, component }) => (
          <PrivateRoute key={path} path={path} component={component} />
        ))}
        <Redirect from="/" exact to={defaultRoute} />
        <Route component={NotFound as any} />
      </Switch>
      <Suspense fallback={null}>{isLogged && <RefinerLoader />}</Suspense>
      <Suspense fallback={null}>{isLogged && <PendoLoader />}</Suspense>
    </Layout>
  )
}

const AppRouteInterfacer = memo(() => {
  const { isLogged } = useAccount()

  return (
    <Suspense fallback={<AdomikLoader />}>
      <Switch>
        {/* Publics routes declaration */}
        {!isLogged &&
          publicRoutes.map(({ path, component }) => (
            <Route key={path} path={path} component={component} />
          ))}

        <PrivateRoute path="/" component={AuthenticatedRouter} />
      </Switch>
    </Suspense>
  )
})

export default AppRouteInterfacer
