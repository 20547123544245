import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { PRODUCT_PATHS, SubProduct } from 'src/utils/product'
import { getSnowflakeProductsFn } from 'src/utils/snowflakeProductsUtils'
import useProductPage from '../useProductPage'

const useIsSnowflakeProduct = () => {
  const { code } = useProductPage()
  const { pathname } = useLocation()

  const isSnowflakeProduct = useMemo(() => {
    const snowflakeProducts = getSnowflakeProductsFn()

    if (code) {
      return snowflakeProducts.includes(code as SubProduct)
    }

    const reversedProductPaths = Object.entries(PRODUCT_PATHS).reduce((acc, [key, value]) => {
      acc[value] = key
      return acc
    }, {})
    // Remove last slash
    const product = reversedProductPaths[pathname.replace(/\/$/, '')]
    return snowflakeProducts.includes(product as SubProduct)
  }, [code, pathname])

  return isSnowflakeProduct
}

export default useIsSnowflakeProduct
