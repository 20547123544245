/**
 * Copy text to clipboard
 */
export const copyTextToClipboardFn = (text: string) => {
  if (navigator?.clipboard?.writeText) {
    return navigator.clipboard.writeText(text)
  }
  // Create a text area element
  const textArea = document.createElement('textarea')
  textArea.value = text

  // Append the text area to the document
  document.body.appendChild(textArea)

  // Select the text in the text area
  textArea.select()

  // Execute the copy command
  document.execCommand('copy')

  // Remove the temporary text area
  document.body.removeChild(textArea)
}
