import cx from 'classnames'
import { FC, HTMLAttributes } from 'react'

import AdomikLogoBlueVect from 'src/assets/images/adomik-logo-blue-vect.png'
import { useAppSelector } from 'src/redux/hooks'

import * as styles from './AdomikLoader.scss'

interface AdomikLoader extends HTMLAttributes<HTMLDivElement> {
  className?: string
}

const AdomikLoader: FC<AdomikLoader> = ({ className, ...otherProps }) => {
  const { loadingMessage } = useAppSelector(state => state.adomikLoading)

  return (
    <div
      className={cx({
        [styles.loaderWrapper]: true,
        className,
      })}
      {...otherProps}
    >
      <div className={styles.loaderMessage}>{loadingMessage}</div>
      <img className={styles.imgLoader} alt="adomik-logo" src={AdomikLogoBlueVect} />
    </div>
  )
}

export default AdomikLoader
