// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Separator__Separator___J29Vj{border-top:.0625rem solid #edf0f3;margin:1.125rem 0;width:100%}.Separator__SeparatorWithoutMargin___vBPHe{margin:0}`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/Separator/Separator.scss"],"names":[],"mappings":"AAEA,8BACE,iCAAA,CACA,iBAAA,CACA,UAAA,CAEA,2CACE,QAAA","sourcesContent":["@use '../../../../../src/styles/variables';\n\n.Separator {\n  border-top: 0.0625rem solid variables.$light-grey;\n  margin: 1.125rem 0;\n  width: 100%;\n\n  &WithoutMargin {\n    margin: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var Separator = `Separator__Separator___J29Vj`;
export var SeparatorWithoutMargin = `Separator__SeparatorWithoutMargin___vBPHe`;
export default ___CSS_LOADER_EXPORT___;
