// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QueryModal__Editor___kxk31{width:20rem;padding:0 1rem .2rem .6rem}.QueryModal__Input___u_I1U{font-family:"Avenir Next",sans-serif;font-weight:500;font-size:.875rem;width:14rem}.QueryModal__ModalContent___KseHA{display:flex;margin-left:.6rem}.QueryModal__StartIcon___XuBLu{margin:1rem 0 0 .325rem}`, "",{"version":3,"sources":["webpack://./src/components/ui/molecules/InputModal/QueryModal.scss","webpack://./src/styles/_typography.scss"],"names":[],"mappings":"AAEA,4BACE,WAAA,CACA,0BAAA,CAGF,2BCLI,oCAAA,CACA,eAAA,CDOF,iBAAA,CACA,WAAA,CAGF,kCACE,YAAA,CACA,iBAAA,CAGF,+BACE,uBAAA","sourcesContent":["@use '../../../../../src/styles/typography';\n\n.Editor {\n  width: 20rem;\n  padding: 0 1rem 0.2rem 0.6rem;\n}\n\n.Input {\n  @include typography.typography('medium');\n\n  font-size: 0.875rem;\n  width: 14rem;\n}\n\n.ModalContent {\n  display: flex;\n  margin-left: 0.6rem;\n}\n\n.StartIcon {\n  margin: 1rem 0 0 0.325rem;\n}\n","@mixin typography($size) {\n  @if $size == 'medium' {\n    font-family: 'Avenir Next', sans-serif;\n    font-weight: 500;\n  }\n\n  @else if $size == 'demi-bold' {\n    font-family: 'Avenir Next', sans-serif;\n    font-weight: 600;\n  }\n\n  @else if $size == 'bold' {\n    font-family: 'Avenir Next', sans-serif;\n    font-weight: 700;\n  }\n\n  @else if $size == 'regular' {\n    font-family: 'Avenir Next', sans-serif;\n    font-weight: 400;\n  }\n\n  @else if $size == 'light' {\n    font-family: 'Avenir Next', sans-serif;\n    font-weight: 300;\n  }\n\n  @else {\n    font-family: 'Avenir Next', sans-serif;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var Editor = `QueryModal__Editor___kxk31`;
export var Input = `QueryModal__Input___u_I1U`;
export var ModalContent = `QueryModal__ModalContent___KseHA`;
export var StartIcon = `QueryModal__StartIcon___XuBLu`;
export default ___CSS_LOADER_EXPORT___;
