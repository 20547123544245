import { FC, memo, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import useAccount from 'src/hooks/useAccount'
import usePageLoading from 'src/hooks/usePageLoading'
import usePrinter from 'src/hooks/usePrinter'
import { PageLoadingContextEnum } from 'src/types/PageLoadingInterface'
import SaveDashboardItemInterface from 'src/types/SaveDashboardItemInterface'
import { removeLastSlash } from 'src/utils/routeUtils'

interface PageLoadingHandlerProps {
  selectedSavedDashboard: SaveDashboardItemInterface | null
  exportId: string | null
}

const PageLoadingHandler: FC<PageLoadingHandlerProps> = ({ selectedSavedDashboard, exportId }) => {
  const { pathname } = useLocation()
  const { printMode } = usePrinter()
  const { informations: account } = useAccount()

  const pathNameWithoutSlash = removeLastSlash(pathname)

  const {
    updatePageLoadingAdomikNavigationId,
    setPageLoadingAdomikPlatformContext,
    setPageLoadingAdomikPlatformContextId,
  } = usePageLoading()

  // Update page navigation id
  useEffect(() => {
    updatePageLoadingAdomikNavigationId()
  }, [pathNameWithoutSlash, updatePageLoadingAdomikNavigationId, account])

  // Update page context
  useEffect(() => {
    // If in scheduled dashboard
    if (exportId) {
      setPageLoadingAdomikPlatformContext(PageLoadingContextEnum.scheduleDashboard)
      setPageLoadingAdomikPlatformContextId(exportId)
      return
    }

    if (selectedSavedDashboard) {
      const { id } = selectedSavedDashboard
      const isStandardDashboard = id === 'default_dashboard'

      // is download mode
      if (printMode) {
        setPageLoadingAdomikPlatformContext(PageLoadingContextEnum.pdfPreview)
        if (!isStandardDashboard) {
          setPageLoadingAdomikPlatformContextId(id)
        }
        return
      }

      // is standard dashboard
      if (isStandardDashboard) {
        setPageLoadingAdomikPlatformContext(PageLoadingContextEnum.standardDashboard)
        setPageLoadingAdomikPlatformContextId(null)
        return
      }
      // is saved dashboard
      setPageLoadingAdomikPlatformContext(PageLoadingContextEnum.savedDashboard)
      setPageLoadingAdomikPlatformContextId(id)
    }
  }, [selectedSavedDashboard, updatePageLoadingAdomikNavigationId, printMode, exportId])

  return null
}

export default memo(PageLoadingHandler)
