/* eslint-disable no-console */
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import React, { useCallback, useState } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import useFetch from 'src/hooks/useFetch'
import {
  resetComponentsSettings,
  setComponentsSettingsLoading,
} from 'src/redux/actions/componentsSettingsActions'
import { RootState } from 'src/redux/store'
import { AccountInformations } from 'src/utils/account'
import { API, CROSS_STORAGE_PLATFORM_URL } from 'src/utils/config'
import storageUtils from 'src/utils/storageUtils'
import { LOGIN_ROOT } from 'src/utils/urls'

const CROSS_STORAGE_CLEAR_TIMEOUT = 1000

type LoginFn = (email: string, password: string, remember: boolean) => Promise<any>
type LoginWithoutPromptFn = () => Promise<any>
type LogoutFn = (needToCallApi?: boolean) => Promise<any>
type ResetPasswordFn = (email: string) => Promise<any>
type ConfirmPasswordFn = (newPassword: string, passwordConfirm: string) => Promise<any>
type UpdatePasswordFn = (
  currentPassword: string,
  password: string,
  passwordConfirm: string,
) => Promise<any>
export type UpdateProfileFn = (profileId: string, cb?: () => void) => Promise<any>
type UpdateSettingsFn = (user: any) => Promise<any>
export type ConnectAsFn = (sellerId: string, cb?: () => void) => Promise<any>

export interface AccountCtx {
  informations: AccountInformations
  isLogged: boolean
  loadingSeller: boolean
  login: LoginFn
  loginWithoutPrompt: LoginWithoutPromptFn
  logout: LogoutFn
  resetPassword: ResetPasswordFn
  confirmPassword: ConfirmPasswordFn
  updatePassword: UpdatePasswordFn
  updateProfile: UpdateProfileFn
  updateSettings: UpdateSettingsFn
  connectAs: ConnectAsFn
  getCurrentUser: () => Promise<AccountInformations>
  setLoadingSeller: (loading: boolean) => void
  logoutLoading: boolean
}

export const AccountContext = React.createContext<AccountCtx>({} as AccountCtx)

const AccountProvider = ({ children }) => {
  const history = useHistory()
  const [account, setAccount] = useState<AccountInformations | undefined>()
  const [isLogged, setIsLogged] = useState<boolean>(false)
  const [loadingSeller, setLoadingSeller] = useState(false)
  const [logoutLoading, setLogoutLoading] = useState(false)

  const [, executeLogin] = useFetch(
    { url: API.USER_LOGIN, method: 'POST' },
    { manual: true },
    false,
  )

  const [, executeLogout] = useFetch(
    { url: API.USER_LOGOUT, method: 'DELETE' },
    { manual: true },
    false,
  )
  const [, executeResetPassword] = useFetch(
    { url: API.USER_PASSWORD, method: 'POST' },
    { manual: true },
    false,
  )
  const [, executeConfirmPassword] = useFetch(
    { url: API.USER_PASSWORD, method: 'PUT' },
    { manual: true },
    false,
  )
  const [, executeUpdatePassword] = useFetch(
    { url: API.USER_AUTH, method: 'PATCH' },
    { manual: true },
    false,
  )
  const [, executeUpdateProfile] = useFetch(
    { url: API.USER_PROFILES_SWITCH, method: 'PUT' },
    { manual: true },
  )
  const [, executeUpdateSettings] = useFetch(
    { url: API.USER_AUTH, method: 'PATCH' },
    { manual: true },
  )
  const [, executeGetCurrentUser] = useFetch<AccountInformations>(
    { url: API.USER_INFORMATIONS, method: 'GET' },
    { manual: true },
  )

  const [, executeConnectAs] = useFetch<any>(
    { url: API.USER_CONNECT_AS, method: 'PUT' },
    { manual: true },
    false,
  )

  const getCurrentUser = useCallback(async () => {
    try {
      const { data } = await executeGetCurrentUser()

      setAccount(data)

      return data
    } catch (error) {
      console.error(error)
      throw error
    }
  }, [executeGetCurrentUser])

  const login: LoginFn = useCallback(
    async (email, password, remember) => {
      try {
        const loginResponse = await executeLogin({ data: { user: { email, password, remember } } })

        const token = loginResponse.headers['authorization']

        if (!token) {
          throw new Error('No token found')
        }

        storageUtils.setAuthToken(token)

        let redirectPath = localStorage.getItem('redirect')

        if (
          redirectPath &&
          (decodeURIComponent(redirectPath).startsWith('http://') ||
            decodeURIComponent(redirectPath).startsWith('https://'))
        ) {
          redirectPath = '/'
          localStorage.setItem('redirect', redirectPath)
        }
        const path =
          isEmpty(redirectPath) || isNull(redirectPath) || redirectPath === '/'
            ? '/report/dashboard'
            : redirectPath

        window.location.href = path
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    [executeLogin],
  )

  const loginWithoutPrompt: LoginWithoutPromptFn = useCallback(async () => {
    try {
      const { data } = await executeGetCurrentUser()

      ReactDOM.unstable_batchedUpdates(() => {
        setAccount(data)
        setIsLogged(true)
      })

      return data
    } catch (error) {
      console.error(error)
      throw error
    }
  }, [executeGetCurrentUser])

  const logout: LogoutFn = useCallback(
    async (needToCallApi = true) => {
      const redirect = encodeURIComponent(history.location.pathname + history.location.search)
      const redirectionPath =
        !history.location.pathname.startsWith(LOGIN_ROOT) && redirect
          ? `/login/?redirect=${redirect}`
          : '/login'

      setLogoutLoading(true)
      try {
        let logoutData: any = null
        if (needToCallApi) {
          const { data } = await executeLogout().catch(() => ({ data: null }))
          logoutData = data
        }

        storageUtils.logout()

        localStorage.setItem('redirect', redirectionPath)

        if (CROSS_STORAGE_PLATFORM_URL) {
          // Wait for cross-storage logout to be done
          await new Promise(resolve => setTimeout(resolve, CROSS_STORAGE_CLEAR_TIMEOUT))
        }
        return logoutData
      } catch (error) {
        console.error(error)
        throw error
      } finally {
        ReactDOM.unstable_batchedUpdates(() => {
          setAccount(undefined)
          setIsLogged(false)
        })
        window.location.href = redirectionPath
        setLogoutLoading(false)
      }
    },
    [executeLogout, history.location.pathname, history.location.search],
  )

  const resetPassword: ResetPasswordFn = useCallback(
    async email => {
      try {
        const { data } = await executeResetPassword({ data: { user: { email } } })

        return data
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    [executeResetPassword],
  )

  const confirmPassword: ConfirmPasswordFn = useCallback(
    async (newPassword, passwordConfirm) => {
      try {
        const { data: account } = await executeConfirmPassword({
          data: {
            user: {
              password: newPassword,
              password_confirmation: passwordConfirm,
            },
          },
        })

        setAccount(account as AccountInformations)

        return account
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    [executeConfirmPassword],
  )

  const updatePassword: UpdatePasswordFn = useCallback(
    async (currentPassword, password, passwordConfirm) => {
      try {
        const { data } = await executeUpdatePassword({
          data: {
            user: {
              current_password: currentPassword,
              password,
              password_confirmation: passwordConfirm,
            },
          },
        })

        setAccount(data as AccountInformations)
        return data
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    [executeUpdatePassword],
  )

  const updateProfile: UpdateProfileFn = useCallback(
    async profileId => {
      try {
        const { data } = await executeUpdateProfile({
          data: {
            profile_id: profileId,
          },
        })

        return data
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    [executeUpdateProfile],
  )

  const updateSettings: UpdateSettingsFn = useCallback(
    async user => {
      try {
        const { data } = await executeUpdateSettings({
          data: { user },
        })

        return data
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    [executeUpdateSettings],
  )

  const connectAs: ConnectAsFn = useCallback(
    async sellerId => {
      try {
        const { data } = await executeConnectAs({
          data: {
            user: {
              default_company_id: sellerId,
            },
          },
        })

        return data
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    [executeConnectAs],
  )

  const contextValue = {
    informations: account as AccountInformations,
    isLogged,
    loadingSeller,
    logoutLoading,
    setLoadingSeller,
    login,
    loginWithoutPrompt,
    logout,
    getCurrentUser,
    resetPassword,
    confirmPassword,
    updatePassword,
    updateProfile,
    updateSettings,
    connectAs,
  }

  return <AccountContext.Provider value={contextValue}>{children}</AccountContext.Provider>
}

const mapStateToProps = (state: RootState) => ({
  settings: state.componentsSettings.settings,
  oldSettings: state.componentsSettings.oldSettings,
})
const mapDispatchToProps = {
  resetComponentsSettings,
  setComponentsSettingsLoading,
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountProvider)
