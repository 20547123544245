import { SubProduct } from '../product'

/**
 * List of snowflake product
 */
export const SNOWFLAKE_PRODUCTS = [
  SubProduct.Dashboard,
  SubProduct.DataDownload,
  SubProduct.AnalyticsV2,
  SubProduct.MarketAlertsV2,
  SubProduct.DealAlertsV2,
  SubProduct.DealCenterV2,
]
