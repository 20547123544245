import { AnyAction } from 'redux'

import { ComponentCode } from 'src/utils/settings'
import { RESET_COMPONENTS_LOADING, SET_COMPONENTS_LOADING } from '../constants/constants'

interface LoadingComponentsProps {
  componentCode: ComponentCode
  loading: boolean
  error?: Error
}

export interface RootStoreProps {
  loadingComponents: LoadingComponentsProps[]
}

const initialState: RootStoreProps = {
  loadingComponents: [],
}

export default function rootReducer(
  state: RootStoreProps = initialState,
  action: AnyAction,
): RootStoreProps {
  if (action.type === SET_COMPONENTS_LOADING) {
    const { loading, componentCode, error } = action.payload
    const find = state.loadingComponents.find(c => c.componentCode === componentCode)
    let loadingComponents = [...state.loadingComponents]

    if (!find) {
      loadingComponents.push({ loading, componentCode, error })
    } else {
      loadingComponents = loadingComponents.map(c => {
        if (c.componentCode === componentCode) {
          c.loading = loading
          c.error = error
        }
        return c
      })
    }

    return { ...state, loadingComponents }
  } else if (action.type === RESET_COMPONENTS_LOADING) {
    return { ...state, loadingComponents: [] }
  }

  return state
}
