import useFetch from 'src/hooks/useFetch'
import { TranslateTag } from 'src/utils/search'
import useApiUrl from './useApiUrl'
import useProductPage from './useProductPage'

const useGetTagDetails = (url?: string) => {
  const page = useProductPage()
  const apiUrl = useApiUrl()
  const [, executeGetTagDetails] = useFetch(
    {
      url: `${apiUrl}/${url ? url : page.api.translate}`,
      method: 'POST',
    },
    { manual: true, autoCancel: false },
  )

  async function getTagDetails(entities: {
    [x: string]: ReadonlyArray<string>
  }): Promise<TranslateTag> {
    const { data } = await executeGetTagDetails({ data: { request: { entities } } })
    return data as TranslateTag
  }

  return getTagDetails
}

export default useGetTagDetails
