import { FC, SVGProps } from 'react'

const InfoIcon: FC<SVGProps<SVGSVGElement>> = svgProps => (
  <svg
    width="16"
    height="16"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="info-icon"
    {...svgProps}
  >
    <path
      d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm1 12H7V7h2v5zM8 6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
)

export default InfoIcon
