const Deal = (props: any) => (
  <svg height="24" width="24" viewBox="150 0 600 600" fill="currentColor" {...props}>
    <g>
      <path
        d="M637.1,586.8l-10.6-64c-1.7-10.4-10.8-18.1-21.3-18.1h-53v-22.5h-0.7c11.9,0,21.6-9.7,21.6-21.6v0
		c0-11.9-9.7-21.6-21.6-21.6H396.9c-11.9,0-21.6,9.7-21.6,21.6v0c0,11.9,9.7,21.6,21.6,21.6h-0.7v22.5h-52.8
		c-10.6,0-19.6,7.6-21.3,18.1l-10.6,64c-2.2,13.2,8,25.2,21.3,25.2h283C629.2,612,639.3,600,637.1,586.8z"
      />
      <path
        d="M298,274.2c57.9,128.3,152.4,115.1,152.4,115.1S390.9,170.6,211.6,56.5c0,0,181.2,61.1,275.7,355.6l8,26.9
		h30.4l-17.7-59.2c0,0,47.6-59.3-51.9-181.5S154.6,0,154.6,0l21.3,70.2C175.9,70.2,252.6,173.4,298,274.2z"
      />
    </g>
  </svg>
)

export default Deal
