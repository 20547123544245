import { isUndefined, map, omit } from 'lodash'
import { useCallback } from 'react'

import { useAppSelector } from 'src/redux/hooks'
import { DataDownloadOptionConfigInterface } from 'src/types/DataDownloadOptionConfigInterface'
import { ECOSYSTEM_BIG_WIDTH_TRENDS_ITEMS_KEY } from 'src/utils/constant'
import {
  SubProduct,
  subProductsCheckboxes,
  subProductsWithoutEcosystems,
  subProductsWithoutMetrics,
} from 'src/utils/product'
import { ComponentCode } from 'src/utils/settings'
import useProductPage from './useProductPage'
import { useSetComponentSettingsAction } from './useSetComponentSettingsAction'

/**
 * This hook allows to manage the settings. This is where we manage the different objects that will determine if an element supported by the
 * "componentsSettings" should change on the page (e.g. the "scopes" of the filterBar, the "metric" selected in Key Metrics ... etc). We return for each
 * object, only the properties we need to make our different comparisons.
 */
const useComponentSettings = () => {
  const { dashboardKey, code } = useProductPage()

  const { settings, oldSettings, settingsLoaded } = useAppSelector(s => ({
    settings: s.componentsSettings.settings?.[dashboardKey],
    oldSettings: s.componentsSettings.oldSettings?.[dashboardKey],
    settingsLoaded: s.componentsSettings.settingsLoaded?.[dashboardKey],
  }))

  const setComponentsSettingsAction = useSetComponentSettingsAction()

  const getCheckboxesSettings = useCallback(
    (s: any) => s?.[ComponentCode.CheckboxesGroup]?.data,
    [],
  ) // Retrieve only checkboxes group settings (DataDownload)

  const getMultiChartsSettings = useCallback((s: any) => s?.[ComponentCode.MultiCharts]?.data, []) // Retrieve only MultiCharts settings

  const getFilterBarSettings = useCallback((s: any) => {
    let filterBar = s?.[ComponentCode.FilterBar]
    if (filterBar?.scopes) {
      const scopes = filterBar.scopes.map(s => ({ exclude: s.exclude, id: s.id }))
      filterBar = { ...filterBar, scopes }
    }
    return filterBar
  }, []) // Retrieve only filters bar settings

  const getKeyMetricsSettings = useCallback((s: any) => {
    const keyMetrics = s?.[ComponentCode.KeyMetrics]
    let metric = keyMetrics?.metric
    if (metric) {
      metric = { code: metric?.code, label: metric?.label, type: metric?.type }
    }
    return { ...keyMetrics, metric }
  }, []) // Retrieve only Key Metrics settings

  const getEcosystemsSettings = useCallback(
    (s: any) =>
      s?.[ComponentCode.Ecosystems]?.tables?.map((ecoSettings: any) => {
        let ecosystems = {
          chartmode: ecoSettings.chartmode,
          primaryDimension: ecoSettings.primaryDimension,
          template: ecoSettings.template,
          [ECOSYSTEM_BIG_WIDTH_TRENDS_ITEMS_KEY]:
            ecoSettings[ECOSYSTEM_BIG_WIDTH_TRENDS_ITEMS_KEY] || [],
        }

        if (ecoSettings.secondDimension) {
          ecosystems['secondDimension'] = ecoSettings.secondDimension
        }

        const templateId = ecoSettings?.template?.id

        if (templateId && ecoSettings[templateId]) {
          const filters = map(ecoSettings[templateId].filters, filter => {
            const omitProps = omit(filter, ['adapter', 'check', 'config.min', 'config.max'])
            return omitProps
          })
          ecosystems = Object.assign(ecosystems, {
            [templateId]: {
              columns: ecoSettings[templateId]?.columns,
              filters,
              options: ecoSettings[templateId]?.options,
              sort: ecoSettings[templateId]?.sort,
            },
          })
        }
        return ecosystems
      }),
    [],
  ) // Retrieve only Ecosystems settings

  /** Clean object */
  const cleanData = useCallback((data: any) => data && JSON.parse(JSON.stringify(data)), [])

  const ecosystemsLoaded = settingsLoaded?.[ComponentCode.Ecosystems]
  const filterBarLoaded = settingsLoaded?.[ComponentCode.FilterBar]
  const checkboxesLoaded = settingsLoaded?.[ComponentCode.CheckboxesGroup]
  const keyMetricsLoaded = settingsLoaded?.[ComponentCode.KeyMetrics]
  const multiChartsLoaded = settingsLoaded?.[ComponentCode.MultiCharts]
  // This will mean that the current page settings (page.code) is already loaded if "let loaded === true"
  let loaded = filterBarLoaded && ecosystemsLoaded && (keyMetricsLoaded || multiChartsLoaded)

  if (subProductsWithoutMetrics.includes(code as SubProduct)) {
    loaded = filterBarLoaded && ecosystemsLoaded
  } else if (subProductsWithoutEcosystems.includes(code as SubProduct)) {
    loaded = filterBarLoaded

    if (subProductsCheckboxes.includes(code as SubProduct)) {
      loaded = filterBarLoaded && checkboxesLoaded
    }
  }

  /**
   * Update settings and old settings (First load only except "Ecosystems, FilterBar")
   * @param object value
   * @param ComponentCode componentCode
   */
  const setSettings = useCallback(
    (value: object, componentCode: ComponentCode) => {
      if (isUndefined(loaded)) {
        // First load
        setComponentsSettingsAction({ settings: value, componentCode })
        setComponentsSettingsAction({ settings: value, componentCode, isOld: true })
      } else {
        setComponentsSettingsAction({ settings: value, componentCode })
      }
    },
    [loaded, dashboardKey, setComponentsSettingsAction],
  )

  return {
    filterBarSettings: cleanData(getFilterBarSettings(settings)),
    filterBarOldSettings: cleanData(getFilterBarSettings(oldSettings)),
    checkboxesSettings: cleanData(
      getCheckboxesSettings(settings),
    ) as DataDownloadOptionConfigInterface[],
    checkboxesOldSettings: cleanData(getCheckboxesSettings(oldSettings)),
    keyMetricsSettings: cleanData(getKeyMetricsSettings(settings)),
    keyMetricsOldSettings: cleanData(getKeyMetricsSettings(oldSettings)),
    ecosystemsSettings: cleanData(getEcosystemsSettings(settings)),
    ecosystemsOldSettings: cleanData(getEcosystemsSettings(oldSettings)),
    multiChartsSettings: cleanData(getMultiChartsSettings(settings)),
    multiChartsSettingsOld: cleanData(getMultiChartsSettings(oldSettings)),
    settingsLoaded,
    setSettings,
  }
}

export default useComponentSettings
