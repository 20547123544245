import cx from 'classnames'
import { FC, HTMLProps } from 'react'

import * as styles from './BadgeNew.scss'

export interface BadgeNewProps extends HTMLProps<HTMLDivElement> {
  label?: string
  size?: number
}

const BadgeNew: FC<BadgeNewProps> = ({ label, className, size = 24, style, ...divProps }) => {
  return (
    <div
      style={{
        width: size,
        height: size,
        minWidth: size,
        minHeight: size,
        ...style,
      }}
      className={cx(styles.BadgeNew, className)}
      data-testid="badge-new"
      {...divProps}
    >
      <span className={styles.BadgeNewText}>{label || 'NEW'}</span>
    </div>
  )
}

export default BadgeNew
