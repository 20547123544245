import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'

import useSettings from 'src/hooks/useSettings'
import { useAppSelector } from 'src/redux/hooks'
import useComponentSettings from './useComponentSettings'
import useProductPage from './useProductPage'

/**
 * Determines if there are any changes on the page by comparing the different objects
 */
const useHasChangeOnPage = () => {
  const {
    filterBarSettings,
    filterBarOldSettings,
    keyMetricsSettings,
    keyMetricsOldSettings,
    checkboxesSettings,
    checkboxesOldSettings,
    ecosystemsSettings,
    ecosystemsOldSettings,
    multiChartsSettings,
    multiChartsSettingsOld,
  } = useComponentSettings()
  const { selectedsDashboards } = useSettings()
  const [hasChangesOnDashboard, setHasChangesOnDashboard] = useState(false)
  const { dashboardKey } = useProductPage()
  const settings = useAppSelector(state => state.componentsSettings.settings)

  useEffect(() => {
    let hasChange = false
    const selectedDashboard = selectedsDashboards[dashboardKey]
    if (selectedDashboard?.id !== 'default_dashboard' && !selectedDashboard?.default_dashboard) {
      const filterBarIsEqual = isEqual(filterBarSettings, filterBarOldSettings)
      const keysMetricsIsEqual = isEqual(keyMetricsSettings, keyMetricsOldSettings)
      const ecosystemsIsEqual = isEqual(ecosystemsSettings, ecosystemsOldSettings)
      const checkboxesGroupIsEqual = isEqual(checkboxesSettings, checkboxesOldSettings)
      const multiChartsIsEqual = isEqual(multiChartsSettings, multiChartsSettingsOld)

      hasChange =
        !filterBarIsEqual ||
        !keysMetricsIsEqual ||
        !ecosystemsIsEqual ||
        !checkboxesGroupIsEqual ||
        !multiChartsIsEqual
    }
    setHasChangesOnDashboard(hasChange)
  }, [
    settings,
    selectedsDashboards,
    dashboardKey,
    filterBarSettings,
    filterBarOldSettings,
    keyMetricsSettings,
    keyMetricsOldSettings,
    ecosystemsSettings,
    ecosystemsOldSettings,
    checkboxesSettings,
    checkboxesOldSettings,
    multiChartsSettings,
    multiChartsSettingsOld,
  ])

  return { hasChangesOnDashboard, setHasChangesOnDashboard }
}

export default useHasChangeOnPage
