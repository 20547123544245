/* eslint-disable @typescript-eslint/no-require-imports */
if (process.env.NODE_ENV === 'production') {
  require('core-js/stable')
  require('regenerator-runtime/runtime')
}

import { createBrowserHistory } from 'history'
import { render } from 'react-dom'

import AppProvider from './AppProvider'
import { initSentryFn } from './utils/sentryUtils'

const history = createBrowserHistory()

// Init sentry
initSentryFn(history)

const root = document.getElementById('root')

const renderApp = () => render(<AppProvider history={history} />, root)

if (module.hot) {
  module.hot.accept('./App', renderApp)
}

renderApp()
