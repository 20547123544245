import cx from 'classnames'
import { FC, ReactNode } from 'react'
import { createPortal } from 'react-dom'
import ReactTooltip, { Props } from 'react-tooltip'

import * as styles from './Tooltip.scss'

export interface TooltipProps extends Omit<Props, 'children'> {
  className?: string
  external?: boolean
  fixedWidth?: boolean
  children?: ReactNode
  minLeft?: number
}

const DEFAULT_DELAY_HIDE = 300
const DEFAULT_DELAY_SHOW = 300

const Tooltip: FC<TooltipProps> & { rebuild: () => void } = ({
  className,
  external,
  place = 'bottom',
  fixedWidth,
  children,
  clickable,
  delayHide,
  delayShow,
  minLeft,
  ...otherProps
}) => {
  const Content = (
    <ReactTooltip
      place={place}
      delayShow={delayShow === undefined ? DEFAULT_DELAY_SHOW : delayShow}
      effect="solid"
      className={cx(
        {
          [styles.Tooltip]: true,
          [styles.TooltipWithArrowOffset]: place === 'bottom' || place === 'top',
        },
        className,
        { [styles.TooltipFixedWidth]: fixedWidth },
      )}
      role="tooltip"
      clickable={clickable ?? true}
      delayHide={delayHide === undefined ? DEFAULT_DELAY_HIDE : delayHide}
      overridePosition={(position, _, __, node: HTMLDivElement) => {
        const { left } = position
        const newLeftPosition = minLeft ? Math.max(minLeft, left) : left
        const arrowOffset = left !== newLeftPosition ? left - newLeftPosition : 0
        node.style?.setProperty('--arrow-offset', `${arrowOffset}px`)

        return {
          ...position,
          left: minLeft ? Math.max(minLeft, left) : left,
        }
      }}
      {...otherProps}
    >
      {children as any}
    </ReactTooltip>
  )

  if (external) {
    return createPortal(Content, document.body)
  }
  return Content
}

Tooltip.rebuild = () => {
  ReactTooltip.rebuild()
}

export default Tooltip
