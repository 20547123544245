import { matchPath } from 'react-router-dom'

import AppRouteInterface from 'src/types/AppRouteInterface'

// Remove last / from pathname if exist
export const removeLastSlash = (pathname: string) => {
  if (pathname.endsWith('/')) {
    return pathname.slice(0, -1)
  }
  return pathname
}
// Get pathname without query string

export const isOnAppRoutesFn = (routes: AppRouteInterface[], pathname: string): boolean => {
  return routes.some(route => {
    if (route.path === pathname) {
      return true
    }

    return matchPath(pathname, { path: route.path, exact: true })
  })
}
