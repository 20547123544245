const SafetyBuoy = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" {...props}>
    <path
      fillRule="nonzero"
      fill="currentColor"
      d="M10.8 0C4.835 0 0 4.835 0 10.8c0 5.965 4.835 10.8 10.8 10.8 5.965 0 10.8-4.835 10.8-10.8C21.594 4.838 16.762.006 10.8 0zm3.9 2a9.58 9.58 0 0 1 5.06 5.32L16.4 8.65a6 6 0 0 0-3.16-3.33L14.7 2zm-7.34-.13L8.65 5.2a6 6 0 0 0-3.33 3.16L2 6.9a9.51 9.51 0 0 1 5.36-5.03zm-.46 17.7a9.57 9.57 0 0 1-5.06-5.33L5.2 13a6 6 0 0 0 3.16 3.33L6.9 19.57zM6 10.8a4.8 4.8 0 1 1 9.6 0 4.8 4.8 0 0 1-9.6 0zm8.24 9L13 16.38a6 6 0 0 0 3.33-3.14l3.29 1.46a9.52 9.52 0 0 1-5.38 5.05v.05z"
    />
  </svg>
)

export default SafetyBuoy
