const Save = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="65" height="30" viewBox="0 0 65 30" {...props}>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-629.000000, -1037.000000) translate(0.000000, 940.000000) translate(0.000000, 82.000000) translate(340.000000, 0.000000) translate(18.000000, 15.000000) translate(184.000000, 0.000000) translate(87.000000, -0.000000)">
        <text
          fill="currentColor"
          fontFamily="AvenirNext-Medium, Avenir Next"
          fontSize="14"
          fontWeight="400"
        >
          <tspan x="30" y="20">
            Save
          </tspan>
        </text>
        <g>
          <path d="M0 0L16 0 16 16 0 16z" transform="translate(7.000000, 7.000000)" />
          <path
            fill="currentColor"
            fillRule="nonzero"
            d="M12.193 1.692c-.285-.284-.668-.442-1.066-.442H2.75c-.833 0-1.5.675-1.5 1.5v10.5c0 .825.675 1.5 1.5 1.5h10.5c.825 0 1.5-.675 1.5-1.5V4.872c0-.397-.158-.78-.443-1.057l-2.114-2.123zM8 13.25c-1.245 0-2.25-1.005-2.25-2.25S6.755 8.75 8 8.75s2.25 1.005 2.25 2.25S9.245 13.25 8 13.25zM9 6H4.5C3.675 6 3 5.325 3 4.5S3.675 3 4.5 3H9c.825 0 1.5.675 1.5 1.5S9.825 6 9 6z"
            transform="translate(7.000000, 7.000000)"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default Save
