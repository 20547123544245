import { parse as qsParse, stringify as qsStringify } from 'qs'

export interface QueryParams {
  [key: string]: any
}

const decoder = (value: any) => {
  if (/^(\d+|\d*\.\d+)$/.test(value)) {
    return parseFloat(value)
  }

  const keywords: any = {
    false: false,
    null: null,
    true: true,
    undefined,
  }

  if (value in keywords) {
    return keywords[value]
  }

  return decodeURIComponent(value)
}

export const parse = <T = QueryParams>(search: string): T =>
  qsParse(search, {
    decoder,
    ignoreQueryPrefix: true,
  }) as any

export const stringify = qsStringify

/**
 * Check if we need to clear query params from url
 */
export const isNeedToClearQueryFn = (from: string) => {
  const excludedFromList = ['launch_in', 'shared_link', 'ecosystem_download', 'cross_launch_in']
  return !excludedFromList.includes(from)
}
