/**
 * Wait for a delay in milliseconds before resolving the promise
 * @param delay - The delay in milliseconds
 */
export const waitForDelayFn = async (delay: number): Promise<true> => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(true)
    }, delay)
  })
}
