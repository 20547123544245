const Send = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="68" height="30" viewBox="0 0 68 30" {...props}>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <g transform="translate(-694.000000, -190.000000) translate(0.000000, 175.000000) translate(340.000000, 0.000000) translate(18.000000, 15.000000) translate(184.000000, 0.000000) translate(152.000000, 0.000000)">
                        <text
                          fill="currentColor"
                          fontFamily="AvenirNext-Medium, Avenir Next"
                          fontSize="14"
                          fontWeight="400"
                        >
                          <tspan x="31" y="20">
                            Send
                          </tspan>
                        </text>
                        <g fill="currentColor" fillRule="nonzero">
                          <path
                            d="M15.938 1.22l-2.412 11.375c-.182.803-.657 1.003-1.331.625L8.52 10.51l-1.773 1.706c-.197.196-.36.36-.739.36l.264-3.742 6.811-6.155c.297-.264-.064-.41-.46-.147l-8.42 5.302L.577 6.701c-.788-.246-.802-.789.165-1.167L14.92.071c.657-.246 1.231.147 1.017 1.15z"
                            transform="translate(8.000000, 8.000000)"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Send
