import cx from 'classnames'
import { forwardRef, HTMLProps } from 'react'

import * as styles from '../Forms.scss'

export interface InputFieldProps extends HTMLProps<HTMLInputElement> {
  input?: HTMLProps<HTMLInputElement>
  meta?: {
    error?: string
    touched?: boolean
  }
  hideError?: boolean
  'data-testid'?: string
  error?: boolean
  fullWidth?: boolean
  containerClassName?: string
}

const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      type = 'text',
      placeholder,
      input,
      disabled = false,
      meta,
      hideError,
      className,
      'data-testid': dataTestId,
      error: errorProp,
      fullWidth,
      containerClassName,
      ...inputProps
    },
    ref,
  ) => {
    const { error, touched } = meta || {}
    const isError = errorProp || (touched && error)

    const inputClass = cx({
      [styles.formControl]: true,
      [styles.error]: isError,
    })

    return (
      <div className={cx(styles.Field, { [styles.FullWidth]: fullWidth }, containerClassName)}>
        <input
          ref={ref}
          type={type}
          {...inputProps}
          {...input}
          className={cx(inputClass, styles.TextInput, className, {
            [styles.TextInputDisabled]: disabled,
            [styles.FullWidth]: fullWidth,
          })}
          placeholder={placeholder}
          disabled={disabled}
          autoComplete="off"
          data-testid={dataTestId}
        />
        {!hideError && isError && <span className={styles.ErrorField}>{error}</span>}
      </div>
    )
  },
)

export default InputField
