import { AnyAction } from 'redux'

import { Dimension, TimePeriod } from 'src/types/Filters'
import {
  FILTER_BAR_FIXED_POSITION_CHANGED,
  FILTER_BAR_HOVERED,
  FILTER_BAR_SET_CURRENT_TAGS,
  FILTER_BAR_SET_IS_TIME_PERIOD_INVALID,
  FILTER_BAR_SET_MISSING_LAUNCHING_DIMENSIONS,
  TIME_PERIOD_SET,
} from '../constants/constants'

export interface EcosystemSelectedScopesTag {
  id: string
  name: string
  platform: string
  dimension: Dimension
  exclude: boolean
  from?: 'launch_in' | ''
  isParent?: boolean
  parentEntity?: EcosystemSelectedScopesTag
  isLaunching?: boolean
}

export interface FilterBarStoreProps {
  isHovered: boolean
  isFixed: boolean
  currentTags: Readonly<EcosystemSelectedScopesTag[]>
  timePeriod: TimePeriod | null
  isTimePeriodInvalid: boolean
  missingLaunchingDimensions: string[]
}

export const initState: FilterBarStoreProps = {
  isHovered: false,
  isFixed: false,
  currentTags: [],
  timePeriod: null,
  isTimePeriodInvalid: false,
  missingLaunchingDimensions: [],
}

export default function filterBarReducer(state = initState, action: AnyAction) {
  switch (action.type) {
    case FILTER_BAR_HOVERED: {
      const { isHovered } = action.payload as { isHovered: boolean }
      return { ...state, isHovered }
    }

    case FILTER_BAR_FIXED_POSITION_CHANGED: {
      const { isFixed } = action.payload as { isFixed: boolean }
      return { ...state, isFixed }
    }

    case FILTER_BAR_SET_CURRENT_TAGS: {
      const { currentTags } = action.payload as {
        currentTags: FilterBarStoreProps['currentTags']
      }
      return { ...state, currentTags }
    }
    case TIME_PERIOD_SET: {
      const { timePeriod } = action.payload as {
        timePeriod: FilterBarStoreProps['timePeriod']
      }
      return { ...state, timePeriod }
    }
    case FILTER_BAR_SET_IS_TIME_PERIOD_INVALID: {
      const { isTimePeriodInvalid } = action.payload as {
        isTimePeriodInvalid: FilterBarStoreProps['isTimePeriodInvalid']
      }
      return { ...state, isTimePeriodInvalid }
    }

    case FILTER_BAR_SET_MISSING_LAUNCHING_DIMENSIONS: {
      const { missingLaunchingDimensions } = action.payload as {
        missingLaunchingDimensions: FilterBarStoreProps['missingLaunchingDimensions']
      }
      return { ...state, missingLaunchingDimensions }
    }

    default:
      return state
  }
}
