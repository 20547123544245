import { FC } from 'react'

import Currency from 'src/components/ui/atoms/Currency'
import Number from 'src/components/ui/atoms/Number'
import Percentage from 'src/components/ui/atoms/Percentage'
import useAccount from 'src/hooks/useAccount'
import ComponentColor from 'src/types/ItemColor'
import { Currency as ICurrency } from 'src/utils/currency'
import { Metric } from 'src/utils/metric'
import { ShapeNumberOptions } from 'src/utils/shapeNumber/number'
import { NumberProps } from '../Number/Number'

export interface MetricValueProps extends Pick<NumberProps, 'style' | 'isBadgeHovered'> {
  metric: Pick<Metric, 'type' | 'raw'>
  isPositive?: boolean
  variation?: boolean
  shapeNumberOptions?: ShapeNumberOptions
  variationSymbol?: string
  equal?: boolean
  currency?: ICurrency
  neutral?: boolean
  badge?: boolean
  bold?: boolean
  parenthesis?: boolean
  className?: string
  formatCurrency?: boolean
  isApproximation?: boolean
  componentColor?: ComponentColor
  infiniteThresold?: number
}

const MetricValue: FC<MetricValueProps> = ({
  metric,
  isPositive,
  shapeNumberOptions,
  variation = false,
  variationSymbol = 'plus_minus',
  equal,
  currency,
  badge,
  neutral = false,
  className,
  formatCurrency,
  componentColor,
  infiniteThresold,
  ...otherProps
}) => {
  const { informations } = useAccount()
  const numberProps = {
    isPositive,
    type: metric.type === 'rawPercentage' ? 'percentage' : metric.type,
    number: metric.raw,
    shapeNumberOptions: shapeNumberOptions || {},
    variation,
    variationSymbol,
    equal,
    neutral,
    badge,
    className,
    componentColor,
    ...otherProps,
  }

  switch (numberProps.type) {
    case 'currency':
      return (
        <Currency
          {...numberProps}
          currency={currency || informations.currency}
          formatCurrency={formatCurrency}
        />
      )
    case 'percentage':
      return <Percentage infiniteThresold={infiniteThresold} {...numberProps} />
    default:
      return <Number {...numberProps} formatCurrency={formatCurrency} />
  }
}

export default MetricValue
