import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

import Raven from 'raven-js'
import { useEffect, useState } from 'react'

import AdomikLoader from 'src/components/ui/atoms/PlaceHolder/AdomikLoader'
import useQueryParams from 'src/hooks/useQueryParams'
import { parseURL } from 'src/utils/urls'
import AppRouter from './AppRouter'
import useAccount from './hooks/useAccount'
import useProductMatchParams from './hooks/useProductMatchParams'
import useSettings from './hooks/useSettings'
import googleAnalytics from './utils/GoogleAnalytics'
import storageUtils from './utils/storageUtils'
import { isNeedToClearQueryFn } from './utils/urlUtils'

const App = () => {
  const {
    components,
    elements,
    getComponents,
    getMenu,
    getDimensionsColors,
    getDashboards,
    getFavoriteDashboardSettings,
    clearQueryParams,
  } = useSettings()
  const { queryParams, setQueryParams } = useQueryParams()
  const { informations: account, loginWithoutPrompt, isLogged } = useAccount()
  const [isDataInitialized, setIsDataInitialized] = useState(false)
  const [initialized, setInitialized] = useState(false)

  const routeMatch = useProductMatchParams()

  const initialize = async () => {
    let userAccount = account

    try {
      // Try to log the user
      if (storageUtils.getAuthToken()) {
        userAccount = await loginWithoutPrompt()

        Raven.setUserContext({
          email: userAccount.email,
          id: userAccount.id,
          name: `${userAccount.first_name} ${userAccount.last_name}`,
          currency: userAccount?.currency?.code,
          company: userAccount.default_company,
        })

        localStorage.setItem('redirect', '')
      }

      // Init google analytics 4
      googleAnalytics.initialize({
        userEmail: userAccount?.email,
        sellerId: userAccount?.default_company?.name,
        userId: userAccount?.id,
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    } finally {
      setInitialized(true)
    }
  }

  useEffect(() => {
    setTimeout(async () => await initialize(), 0)
  }, [])

  // Load data after login
  useEffect(() => {
    const handleInitializeData = async () => {
      const promises: Promise<any>[] = [getMenu(), getComponents(), getDimensionsColors()]

      const pathname = window.location.pathname

      const { page } = parseURL(pathname)
      const from = queryParams?.from
      const downloadPdf = queryParams.download_pdf

      // If we come from the "launch_in (Ecosystems) or shared_link", no need to clear the queryParams, we need It !
      isNeedToClearQueryFn(from) && clearQueryParams()
      const programId = routeMatch?.params?.program
      // If we come from the "download PDF" mode, there is no need to make a new requests.
      if (from !== 'download_pdf' && !downloadPdf) {
        promises.push(
          getDashboards({
            subProduct: page,
            reset: true,
            programId,
          }),
          getFavoriteDashboardSettings({
            subProduct: page,
            enableLoading: false,
            resetSettings: true,
            programId,
          }),
        )
      }

      await Promise.all(promises)

      // Reset certains queries params
      isNeedToClearQueryFn(from) && setQueryParams({ from: '' })
      downloadPdf && setQueryParams({ download_pdf: false })
    }

    if (initialized && !isDataInitialized) {
      if (account) {
        handleInitializeData().finally(() => setIsDataInitialized(true))
        return
      }
      setIsDataInitialized(true)
    }
  }, [initialized, account, queryParams?.from, queryParams.download_pdf, isDataInitialized])

  return !isDataInitialized || (isLogged && (!components || !elements || !account)) ? (
    <AdomikLoader />
  ) : (
    <AppRouter />
  )
}

export default App
