import moment, { Moment } from 'moment'

import CalendarViewType from 'src/types/CalendarViewType'
import { subtractToUtcOffsetFn } from 'src/utils/utcUtils'

const COMPARISON_DISABLED = 'comparison_disabled'

/**
 * is hour comparison disabled
 */
export const isHoursComparisonDisabled = (args: {
  numberOfAllowedDate: number
  startDate?: Moment
  endDate?: Moment
  startCompareDate?: Moment
  endCompareDate?: Moment
  enableCompare: boolean
}) => {
  const utcNow = subtractToUtcOffsetFn(moment())
  const { numberOfAllowedDate, endCompareDate, startCompareDate, enableCompare } = args
  if (startCompareDate && endCompareDate && numberOfAllowedDate && enableCompare) {
    if (startCompareDate.isBefore(utcNow.subtract(numberOfAllowedDate, 'hour'))) {
      return COMPARISON_DISABLED
    }
  }
  return null
}

/**
 * Get date frequency
 */
export const getDateFrequency = (view: CalendarViewType): 'hours' | 'days' | 'months' => {
  switch (view) {
    case 'monthly':
      return 'months'
    case 'hourly':
      return 'hours'
    default:
      return 'days'
  }
}
