import pick from 'lodash/pick'
import { FC, forwardRef } from 'react'

import LoadingProps from 'src/types/LoadingProps'

type ReturnType<T, K> = FC<
  | (T & { loading?: false })
  | ({ loading: true; className?: string } & Partial<Omit<T, 'loading'> & K>)
>

const withLoading = <T, K>(
  Component: FC<T & { loading: false }>,
  LoadingComponent: FC<LoadingProps & Partial<T> & K>,
  loadingPropKeys: (keyof K)[] = [],
): ReturnType<T, K> => {
  const LoadableComponent: ReturnType<T, K> = forwardRef((props: any, ref) => {
    const { loading, ...otherProps } = props
    if (loading) {
      const divProps = pick(otherProps, [
        'className',
        'style',
        'data-testid',
        'role',
        'aria-label',
        ...loadingPropKeys,
      ])
      return <LoadingComponent ref={ref} {...(divProps as any)} />
    }
    return <Component ref={ref} {...otherProps} />
  }) as any
  LoadableComponent.displayName = `Loadable${Component.displayName || Component.name}`
  return LoadableComponent
}

export default withLoading
