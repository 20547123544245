import { Moment } from 'moment'
import { Area, AreaChart, Bar, BarChart, Line, LineChart } from 'recharts'

import { moment } from 'src/utils'
import { Metric, MetricChartConfig } from 'src/utils/metric'
import { TimePeriod } from '../types/Filters'

export enum ChartType {
  Area = 'Area',
  Bar = 'Bar',
  Line = 'Line',
  Pie = 'Pie',
  VerticalComposed = 'VerticalComposed',
}

export enum XAxisType {
  Date = 'date',
  Number = 'number',
  String = 'string',
}

export type XAxisValues = ReadonlyArray<string | number | Moment>

export const ChartsComponents = {
  [ChartType.Area]: AreaChart,
  [ChartType.Bar]: BarChart,
  [ChartType.Line]: LineChart,
}

// charts type with no legends at bottom
export const CHARTS_WITH_NO_LEGENDS = [ChartType.VerticalComposed, ChartType.Pie]

export const SeriesComponents = {
  [ChartType.Area]: Area,
  [ChartType.Bar]: Bar,
  [ChartType.Line]: Line,
}

export interface ChartConfig {
  id: string
  order: number
  yAxisShape: string
  label: string
  metric: string
  type: ChartType
  category: 'over_time' | 'aggregate' | 'time_periods_comparison'
  disabled: boolean
  stacked: boolean
  compare: boolean
  variant?: 'default' | 'impact' | 'variation'
  brush?: boolean
  sortBreakdowns?: boolean
  displayBreakdownsValues?: boolean
  raw_shape?: {
    decimals?: number // Decimal to show
  }
  //
  total: true
}

export interface CategoryOption {
  id: string
  label: string
}

export interface MetricOption {
  id: string
  label: string
  type: string
  legend: boolean
  total: boolean
  main: string // old config
  child: string // old config
  active_modes: string[]
  default?: {
    child: string
    main: string
    child_on_comparison_disabled: string
    main_on_comparison_disabled: string
  }
  is_ad_request?: boolean
  order?: number
}

export interface ModeOption {
  id: string
  label: string
  type: string
  yAxisShape: string
  stacked: boolean
  compare: boolean
  category?: string
  total?: boolean
  disable_truncated?: boolean
  // ↓ these fields do not seem to be valid in the application anymore. Delete as soon as possible these fields and the places in the code where they are used. ↓
  brush?: boolean
  sortBreakdowns?: boolean
  variant?: 'default' | 'impact' | 'variation'
}

export interface ChartsModesConfigs {
  modes: ModeOption[]
  categories: CategoryOption[]
  metrics: MetricOption[]
}

export interface ChartsConfig {
  [metricCode: string]: MetricChartConfig
}

/**
 * Get specific chart config related to metric
 * Products: TS & Yield
 */
export const getChartConfig = (chartsConfig: ChartsConfig, metric: Metric, main = true) => {
  const metricCode = metric.code
  const defaultConfig = chartsConfig[metricCode][main ? 'main' : 'child']

  return chartsConfig[metricCode][defaultConfig] as ChartConfig
}

/**
 * Get chart mode config key
 */
const getChartDefaultConfigKey = (main: boolean, enableCompare: boolean, isNewConfig: boolean) => {
  if (!isNewConfig) {
    return main ? 'main' : 'child'
  }
  if (main) {
    return enableCompare ? 'main' : 'main_on_comparison_disabled'
  }
  return enableCompare ? 'child' : 'child_on_comparison_disabled'
}

/**
 * Get specific chart config related to metric
 * Products: REPORT
 */
export const getChartMode = (
  config: ChartsModesConfigs,
  selectedMetric: { code: string },
  main = true,
  enableCompare = true,
): ModeOption => {
  const modes = config.modes
  const metrics = config.metrics
  const metric = metrics.find(m => m.id === selectedMetric.code)

  const chartModeConfigKey = getChartDefaultConfigKey(main, enableCompare, Boolean(metric?.default))

  const metricMode = metric?.default
    ? metric.default[chartModeConfigKey]
    : metric?.[chartModeConfigKey] // old config

  const mode = modes.find(m => m.id === metricMode) as ModeOption

  return {
    ...mode,
    total: mode?.total && metric?.total,
    // legend: mode.legend && metric?.legend,
  }
}

export const generateXAxisDatesRange = (
  startDate: Moment,
  length: number,
  frequency: 'days' | 'months' | 'hours',
): ReadonlyArray<Moment> => {
  const datesRange = moment.range(startDate, startDate.clone().add(length, frequency))

  return Array.from(datesRange.by(frequency))
}

export const generateXAxisValues = ({
  values,
  xAxisType,
  timePeriod,
  compare = false,
}: {
  values?: ReadonlyArray<string | number>
  xAxisType: XAxisType
  timePeriod: TimePeriod
  compare?: boolean
}) => {
  const { startDate, endDate, startDateCompare, endDateCompare, view } = timePeriod
  const frequency = view === 'monthly' ? 'months' : view === 'daily' ? 'days' : 'hours'

  // If comparison mode, we must manually build x axis values based on the longest date range (base and comparison)
  if (compare) {
    const xAxisLength = Math.max(
      endDate.diff(startDate, frequency),
      endDateCompare.diff(startDateCompare, frequency),
    )

    return generateXAxisDatesRange(startDate, xAxisLength, frequency)
  }

  // If x axis values are existing in data
  if (values) {
    // If date type, generate moment objects based on values
    if (xAxisType === XAxisType.Date) {
      return values.map(date => moment(date, view === 'hourly' ? 'YYYY-MM-DD-HH' : 'YYYY-MM-DD'))
    }
    return values
  }

  // By default, generate x axis values based on time period
  return generateXAxisDatesRange(startDate, endDate.diff(startDate, frequency), frequency)
}

export const GAModesLabelMapping = {
  stack_area: 'Stacked areas',
  stack_column: 'Stacked columns',
  percent_area: '100% Stacked areas',
  percent_column: '100% Stacked columns',
  line: 'Comparison lines',
  column: 'Comparison columns',
  compare_line: 'Time Comparison lines',
  compare_column: 'Time Comparison columns',
  'h-bars': 'Horizontal column',
  doughnut: '100% Doughnut',
}

export const COLORS = [
  '#63b5f7',
  '#1a75d2',
  '#ffd54f',
  '#ef6c02',
  '#455a63',
  '#95a6a6',
  '#de2d01',
  '#00838f',
  '#01bfa5',
  '#ffa001',
  '#90caf9',
  '#7fcbc5',
  '#aed581',
  '#e6ee9c',
  '#ffcc7f',
  '#ffab91',
  '#d1c4e9',
  '#9e9e9e',
  '#c8b298',
]

export const CONSTANT = {
  type_compare: 'compare',
  type_bar: 'Bar',
  currency: 'currency',
  percentage: 'percentage',
  compare_color: '#abb4c0',
  weekends_color: '#eaebf0',
  dots_style: {
    activeDot: {
      strokeWidth: 0,
      r: 6,
    },
    dot: {
      strokeWidth: 0,
      r: 3,
    },
  },
}

export const getColor = (index: number, colors?: ReadonlyArray<string>) => {
  if (Array.isArray(colors)) {
    return colors[index % colors.length]
  }
  return COLORS[index % COLORS.length]
}

/**
 *  Dynamically compute the label text width in charts
 */
export const getTextWidthInCharts = (value: string) => {
  const getFont = () => {
    const div = document.querySelector('#root') as Element
    const style = window.getComputedStyle(div)

    return style.getPropertyValue('font')
  }
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d') as CanvasRenderingContext2D
  context.font = getFont()
  const metric = context.measureText(value)

  return Math.ceil(metric.width)
}
