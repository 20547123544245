import { FC } from 'react'

import withLoading from 'src/utils/hocs/withLoading'
import ShareDashboardModalBodyLoading from './ShareDashboardModalBodyLoading'
import ShareDashboardModalShareLink from './ShareDashboardModalShareLink'
import ShareDashboardModalUsers from './ShareDashboardModalUsers'

const ShareDashboardModalBody: FC = () => {
  return (
    <div>
      <ShareDashboardModalUsers />
      <ShareDashboardModalShareLink />
    </div>
  )
}

export default withLoading(ShareDashboardModalBody, ShareDashboardModalBodyLoading)
