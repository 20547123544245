import * as Sentry from '@sentry/react'
import { History } from 'history'
import { FC } from 'react'
import { Provider } from 'react-redux'

import AccountProvider from 'src/components/data/providers/AccountProvider'
import PrinterProvider from 'src/components/data/providers/PrinterProvider'
import SettingsProvider from 'src/components/data/providers/SettingsProvider'
import WizardProvider from 'src/components/data/providers/WizardProvider'
import Compose from 'src/components/ui/atoms/Compose'
import Toaster from 'src/components/ui/atoms/Toaster'
import { store as reduxStore } from 'src/redux/store'
import App from './App'
import Router from './components/ui/atoms/Router/Router'
import GlobalError from './components/ui/molecules/GlobalError'
import StorageLoading from './components/ui/molecules/StorageLoading/StorageLoading'

interface AppProviderProps {
  history: History
}

const ErrorBoundary = Sentry.ErrorBoundary as any

const AppProvider: FC<AppProviderProps> = ({ history }) => (
  <ErrorBoundary fallback={<GlobalError />}>
    <Router history={history}>
      <Compose
        components={[
          ({ children }) => <Provider store={reduxStore}>{children}</Provider>,
          AccountProvider,
          SettingsProvider,
          PrinterProvider,
          WizardProvider,
        ]}
      >
        <StorageLoading>
          <App />
          <Toaster />
        </StorageLoading>
      </Compose>
    </Router>
  </ErrorBoundary>
)

export default AppProvider
