export const EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const PWD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])\S{8,}$/

const SPECIAL_CHARACTERS = unescape(
  '%21%22%23%24%25%26%27%28%29*+%2C-./%5C%3A%3B%3C%3D%3E%3F@%5B%5D%5E_%60%7B%7C%7D%7E',
)

const WARNING_MESSAGE =
  'You password must include at least 8 alphanumeric characters, both upper and lower case letters, at least one number and at least one special character'

export const required = value => {
  if (!value) {
    return 'Required'
  }

  return undefined
}

export const email = email => {
  if (!EMAIL.test(email)) {
    return 'Invalid email address'
  }

  return undefined
}

export const emailsList = emails => {
  if (!emails?.length) return 'Required'
  const isInvalid = Array.isArray(emails) && emails.some(email => !EMAIL.test(email))
  return isInvalid ? 'Invalid email address' : null
}

export const similare = (value, { newPassword }) => {
  if (value !== newPassword) {
    return 'Should be the same'
  }

  return undefined
}

export const password = value => {
  if (!PWD.test(value)) {
    return `${WARNING_MESSAGE} ${SPECIAL_CHARACTERS}`
  }

  return undefined
}

export const dynamicRequired = (value, allValues) => {
  const once = allValues.frequency?.value === 'once'
  if (!once && !value) {
    return 'Required'
  }
}
