import { CSSProperties, RefObject } from 'react'

import PopupFixedPropsInterface from './popup/PopupFixedPropsInterface'

const MODAL_MARGIN = 28

export const getElLocationStyles = (
  fixedPositionProps: PopupFixedPropsInterface | undefined,
  buttonRef: RefObject<HTMLElement>,
  windowWidth: number,
): CSSProperties => {
  const anchorEl = buttonRef.current

  if (!fixedPositionProps) {
    return {}
  }
  const { anchorOrigin: anchorOriginProps, style, direction } = fixedPositionProps
  if (!anchorEl) {
    return {}
  }
  const baseStyle: CSSProperties = {
    position: 'fixed',
    zIndex: 14440,
  }

  const { x, y, height, width } = anchorEl.getBoundingClientRect()

  const anchorOrigin = anchorOriginProps || 'right'

  let left: CSSProperties['left'] = anchorOrigin === 'left' ? x : x + width

  let transformStyles: CSSProperties = {}

  let translateX = anchorOrigin === 'center' ? `calc(-50% - ${width / 2}px)` : '0px'

  if (x + width > windowWidth) {
    left = windowWidth
    translateX = '100%'
    // Force to left
    transformStyles = {
      transform: 'translateX(-100%)',
    }
  }

  if (direction === 'top') {
    transformStyles = {
      transform: `translate(${translateX}, calc(-100% - ${height + MODAL_MARGIN}px))`,
    }
  }

  if (left < 0) {
    left = 0
  }

  return {
    ...baseStyle,
    top: y + height,
    left,
    ...transformStyles,
    ...style,
  }
}
