import { forwardRef } from 'react'

import InputField, { InputFieldProps } from '../Forms/Input/InputField'

import * as styles from './TextInput.scss'

export interface TextInputProps extends InputFieldProps {
  label: string
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(({ label, ...inputProps }, ref) => {
  return (
    <div className={styles.TextInput}>
      <label className={styles.TextInputLabel}>{label}</label>
      <InputField {...inputProps} ref={ref} />
    </div>
  )
})

export default TextInput
