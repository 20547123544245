import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import { FC, MouseEvent, useState } from 'react'
import { MdOpenInNew } from 'react-icons/md'
import { NavLink, useHistory } from 'react-router-dom'
import { animated, Transition } from 'react-spring'

import Badge, { BADGE_TYPE } from 'src/components/ui/atoms/Badge'
import ChevronDown from 'src/components/ui/atoms/Icons/ChevronDown'
import ChevronUp from 'src/components/ui/atoms/Icons/ChevronUp'
import Tooltip from 'src/components/ui/atoms/Tooltip'
import useDispatcher from 'src/hooks/useDispatcher'
import useProductMatchParams from 'src/hooks/useProductMatchParams'
import useQueryParams from 'src/hooks/useQueryParams'
import useSettings from 'src/hooks/useSettings'
import { resetLoadingComponents } from 'src/redux/actions/rootActions'
import { MenuInterface } from 'src/types/MenuInterface'
import { PRODUCT } from 'src/utils/constant'
import { parseURL } from 'src/utils/urls'
import { isNeedToClearQueryFn } from 'src/utils/urlUtils'
import ProductIcon from '../../atoms/ProductIcon/ProductIcon'

import * as styles from './Menu.scss'

interface SubMenuProps {
  isOpen: boolean
  isMenuOpen: boolean
  parentElement: MenuInterface
  toggleDropdown: (parent: string, child?: string) => void
  currentProduct: string
  currentParent: string
  currentParentUrl: string
  currentProductUrl: string
  openSections: any
  isCurrentParentOpen: boolean
}

export const SubMenu: FC<SubMenuProps> = ({
  parentElement,
  toggleDropdown,
  isMenuOpen,
  currentProduct,
  currentParent,
  currentParentUrl,
  openSections,
  isCurrentParentOpen,
}) => {
  const { getDashboards, getFavoriteDashboardSettings, clearQueryParams, market } = useSettings()
  const { queryParams, setQueryParams } = useQueryParams()
  const history = useHistory()

  const params = useProductMatchParams()
  const programIdParam = params?.params?.program

  const [itemHovered, setItemHovered] = useState<MenuInterface | null>(null)

  const resetLoadingComponentsDispatch = useDispatcher(resetLoadingComponents)

  const getUrlPage = (parentElement: MenuInterface | undefined, item: MenuInterface) => {
    if (parentElement?.code && item?.code) {
      const { code, program } = item
      const name = parentElement.code.toLowerCase()
      const programParam = program ? `/${program.id}` : ''
      return `/${name}/${code}${programParam}`
    }
    return ''
  }

  const getChildrenClasses = () => {
    if (isEmpty(openSections)) {
      return cx({
        [styles.childElements]: isMenuOpen,
        [styles.childElementsActive]: parentElement.code === currentParent,
      })
    }
    return cx({
      [styles.childElements]: isMenuOpen,
      [styles.childElementsActive]: isCurrentParentOpen,
    })
  }

  const childElementName = child => {
    if (isMenuOpen) {
      return <div>{child.name}</div>
    }
    return null
  }

  const isChildActive = (menu: MenuInterface) => {
    if (!menu?.code) return false

    return (
      menu.code === currentProduct &&
      (menu.program && programIdParam ? menu.program.id.toString() === programIdParam : true)
    )
  }

  const isMenuHovered = (menu: MenuInterface) => {
    if (!menu?.code || !itemHovered) return false

    if (menu.program) {
      return (
        menu.code === itemHovered.code &&
        itemHovered.program &&
        menu.program.id.toString() === itemHovered.program.id.toString()
      )
    }

    return menu.code === itemHovered?.code
  }

  const handleParentClick = () => {
    toggleDropdown(parentElement.code)

    if (isEmpty(parentElement.children)) {
      history.push(`/${parentElement.code}`)
    }
  }

  const handleChildClick = (
    parentElement: MenuInterface,
    child: MenuInterface,
    evt: MouseEvent<HTMLAnchorElement>,
  ) => {
    const pathname = window.location.pathname
    const { page, program: programParam } = parseURL(pathname)
    const from = queryParams?.from
    const { code, program } = child
    if (
      currentProduct !== code ||
      code !== page ||
      (program && program.toString() !== programParam)
    ) {
      resetLoadingComponentsDispatch()

      // If we come from the "launch_in, ecosystem_download (Ecosystems) or shared_link", no need to clear the queryParams, we need It !
      if (isNeedToClearQueryFn(from)) {
        clearQueryParams()
        setQueryParams({
          from: '',
          download_pdf: false,
        })
      }
      toggleDropdown(parentElement.code, code)
      getDashboards({ subProduct: code, reset: false, programId: program?.id })
      getFavoriteDashboardSettings({
        subProduct: code,
        enableLoading: true,
        resetSettings: false,
        programId: program?.id,
      })
    } else {
      evt.preventDefault()
    }
  }

  const handleOpenToNewTab = (
    parentElement: MenuInterface | undefined,
    child: MenuInterface,
    evt: MouseEvent<HTMLDivElement>,
  ) => {
    evt.stopPropagation()
    evt.preventDefault()
    const link = getUrlPage(parentElement, child)
    const url = `${window.location.origin}${link}`
    window.open(url, '_blank')
  }

  const hasChildren = !isEmpty(parentElement.children)
  const childrenClasses = getChildrenClasses()

  // allow us to apply the color on the icon only when we click on the subproduct
  const activeParent = parentElement.code === currentParentUrl
  const isActiveProduct = activeParent ?? isChildActive(parentElement)
  const isSubMenuOpen = currentParent === parentElement.code
  const currentProgram = market?.program?.id

  const productAlertsCount = parentElement.children
    ?.map(children => {
      const program = currentProgram || children.active_program

      if (program && children?.alert_count?.[program]) {
        return children?.alert_count?.[program]
      }

      if (typeof children?.alert_count === 'number') {
        return children?.alert_count
      }

      return 0
    })
    .reduce((acc, val) => acc + val, 0)
  const productHasAlerts = productAlertsCount > 0

  const classes = {
    SellBadge: cx({
      [styles.MenuClosed]: true,
      [styles.IconAlertsContainer]: !isMenuOpen,
    }),
    parentClasses: cx({
      [styles.parentElement]: true,
      [styles.parentElementActive]: isActiveProduct,
      [styles.parentElementActiveBorder]: isActiveProduct && (!isSubMenuOpen || !isMenuOpen),
    }),
    productClasses: cx({
      [styles.Product]: true,
      [styles.MenuClosed]: !isMenuOpen,
      [styles.ProductActive]: activeParent && isActiveProduct,
    }),
    customClasses: cx({
      [styles.hiddenItem]: !isMenuOpen,
      [styles.SellMargin]: parentElement.code === PRODUCT.benchmark,
      [styles.parentElementActiveManage]: !hasChildren && activeParent,
    }),
    Icon: cx({
      [styles.Icon]: isMenuOpen,
    }),

    ChildClasses: cx({
      [styles.childElement]: isMenuOpen,
      [styles.MenuClosed]: !isMenuOpen,
    }),

    childElementActive: cx({
      [styles.childElementActive]: isMenuOpen && isActiveProduct,
    }),
  }

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        className={classes.parentClasses}
        onClick={handleParentClick}
        data-for={parentElement.code}
        data-tip=""
        aria-label={`${parentElement.name} link button`}
      >
        <div className={classes.productClasses}>
          <span className={classes.Icon}>
            <ProductIcon productCode={parentElement.code as any} isActive={isActiveProduct} />
          </span>
          {productHasAlerts && !isMenuOpen && (
            <span className={classes.SellBadge}>
              <Badge type={BADGE_TYPE.notificationMenu} label={productAlertsCount} />
            </span>
          )}
          <span className={classes.customClasses}>{parentElement.name}</span>
        </div>
        {hasChildren && (
          <div className={styles.Chevron}>
            {currentParent === parentElement.code && isCurrentParentOpen ? (
              <ChevronUp data-testid="submenu-chevron-up" />
            ) : (
              <ChevronDown data-testid="submenu-chevron-down" />
            )}
          </div>
        )}
      </div>
      {!isMenuOpen && (
        <Tooltip place="right" id={parentElement.code}>
          {parentElement.name}
        </Tooltip>
      )}
      {hasChildren && (
        <div className={childrenClasses}>
          <Transition
            items={(isCurrentParentOpen ? parentElement.children : []) as MenuInterface[]}
            from={{ opacity: 0, transform: 'translateY(-10%)' }}
            enter={{ opacity: 1, transform: 'translateY(0%)' }}
            leave={{ opacity: 0 }}
            trail={70}
            config={{ duration: 90 }}
          >
            {({ opacity }, item) => {
              const { code, name, alert_count: alert, active_program: activeProgram } = item

              const defaultProgram = currentProgram || activeProgram
              const defaultAlert =
                alert && (typeof alert === 'number' ? alert : alert[defaultProgram as any])

              return (
                <animated.div
                  style={{
                    opacity: opacity.to({ output: [0, 1], range: [0, 1] }),
                    transform: opacity
                      .to({ output: [-10, 0], range: [0, 1] })
                      .to(y => `translate3d(0,${y}px,0)`),
                  }}
                >
                  <NavLink
                    to={getUrlPage(parentElement, item)}
                    onClick={evt => handleChildClick(parentElement, item, evt)}
                    isActive={() => isChildActive(item)}
                    className={classes.ChildClasses}
                    activeClassName={classes.childElementActive}
                    data-tip=""
                    data-for={code}
                    aria-label={`${item.name} link button`}
                    role="link"
                    onMouseEnter={() => setItemHovered(item)}
                    onMouseLeave={() => setItemHovered(null)}
                  >
                    {childElementName(item)}
                    {Boolean(defaultAlert) && isMenuOpen && (
                      <div className={styles.AlertsContainer}>
                        <Badge
                          data-testid="submenu-notification-badge"
                          type="notificationMenu"
                          small
                          label={defaultAlert}
                        />
                      </div>
                    )}
                    {/* Open to new Tab button */}
                    {isMenuOpen && isMenuHovered(item) && (
                      <>
                        <div
                          className={styles.childElementOpenInNewLink}
                          data-for={`open-in-new-${code}`}
                          data-tip=""
                          aria-label={`link button`}
                          onClick={evt => {
                            handleOpenToNewTab(parentElement, item, evt)
                          }}
                          data-testid="open-in-new-tab-button"
                        >
                          <MdOpenInNew
                            className={cx(styles.childElementOpenInNewLinkIcon, {
                              [styles.childElementOpenInNewLinkIconActive]: isChildActive(item),
                            })}
                          />
                        </div>
                        <Tooltip external={true} id={`open-in-new-${code}`}>
                          Open in a new tab
                        </Tooltip>
                      </>
                    )}
                  </NavLink>
                  {!isMenuOpen && (
                    <Tooltip place="right" id={code}>
                      {name}
                    </Tooltip>
                  )}
                </animated.div>
              )
            }}
          </Transition>
        </div>
      )}
    </>
  )
}

export default SubMenu
