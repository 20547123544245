import cx from 'classnames'
import { forwardRef, HTMLAttributes, ReactNode } from 'react'

import ComponentColor from 'src/types/ItemColor'
import ButtonClose from '../ButtonClose'

import * as styles from './Input.scss'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: ReactNode
  leftIcon?: ReactNode
  classes?: {
    root?: string
    input?: string
    icon?: string
    leftIcon?: string
  }
  input?: any
  containerProps?: HTMLAttributes<HTMLDivElement> & { 'data-testid'?: string }
  iconProps?: HTMLAttributes<HTMLDivElement>
  leftIconProps?: HTMLAttributes<HTMLDivElement>
  onClear?: () => void
  componentColor?: ComponentColor
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      classes = {},
      type = 'text',
      icon,
      value,
      input,
      leftIcon,
      containerProps,
      iconProps,
      leftIconProps,
      onClear,
      componentColor,
      ...othersProps
    },
    ref,
  ) => {
    const isEmptyValue = !value?.toString().length

    return (
      <div
        data-testid="input-container"
        {...containerProps}
        className={cx(styles.InputWrapper, className, classes.root, containerProps?.className)}
      >
        {leftIcon && (
          <div
            data-testid="input-left-icon"
            {...leftIconProps}
            className={cx(styles.Icon, classes.leftIcon, leftIconProps?.className)}
          >
            {leftIcon}
          </div>
        )}
        <input
          ref={ref}
          type={type}
          className={cx(styles.Input, classes.input, {
            [styles.InputOrange]: componentColor === ComponentColor.orange,
            [styles.InputIcon]: icon,
          })}
          autoComplete="off"
          data-testid="input-field"
          {...input}
          {...(input ? {} : { value })}
          {...othersProps}
        />
        {icon && (
          <div
            data-testid="input-icon"
            {...iconProps}
            className={cx(styles.Icon, classes.icon, iconProps?.className)}
          >
            {icon}
          </div>
        )}
        {!isEmptyValue && onClear && (
          <ButtonClose
            data-testid="input-close-icon"
            aria-label="Clear input"
            className={cx(styles.Icon, styles.IconRight)}
            onClick={onClear}
          />
        )}
      </div>
    )
  },
)

export default Input
