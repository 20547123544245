import isEmpty from 'lodash/isEmpty'

import { ProductPage } from 'src/components/data/providers/ProductPageProvider'
import { OptionValue } from 'src/components/ui/organisms/Ecosystem/components/ContextBar'
import { EcosystemCellType, EcosystemColumn, EcosystemOption } from 'src/types/EcosystemInterface'
import { Filters } from 'src/types/Filters'
import { Product } from 'src/utils/product'
import { SHORTCUT_OPTION_REVENUE_DETAIL_KEY } from './constant'

export interface Event {
  category: string
  action: string
  label?: string
  value?: number
  [customProperty: string]: any
}

export type TrackEventCallback = (
  event: Partial<Omit<Event, 'category'>> & {
    action: string
    value?: boolean | string | ReadonlyArray<string> | number
    label?: boolean | string | ReadonlyArray<string> | number
    componentSize?: 'Large' | 'Small'
  },
) => void
export type TrackPageViewEventCallback = (pageTitle: string) => void

export const trackFiltersChangeFn = ({
  filters,
  trackEvent,
  page,
  hasTimePeriod = true,
}: {
  filters: Filters
  trackEvent: TrackEventCallback
  page: ProductPage
  hasTimePeriod?: boolean
}) => {
  const { timePeriod, dimensions, market, scopes } = filters

  const selectedScope = scopes && scopes.map((el: any) => el.dimension.label).join(', ')
  const frequency =
    timePeriod && timePeriod.view === 'monthly'
      ? 'months'
      : timePeriod?.view === 'hourly'
        ? 'hours'
        : 'days'

  const selectedPeriod = timePeriod && timePeriod.endDate.diff(timePeriod.startDate, frequency) + 1
  const marketValue = market && market.program.label
  const breakdown = dimensions && dimensions.label
  const isBenchmark = page.product === Product.BENCHMARK
  const dropdown = dimensions || market

  if (hasTimePeriod && timePeriod?.selectedRange) {
    trackEvent({
      action: 'run report: Time Period',
      label: timePeriod.selectedRange?.id || 'custom',
      value: selectedPeriod,
    })

    const selectedRangePeriod =
      timePeriod?.endDateCompare &&
      timePeriod?.startDateCompare &&
      timePeriod?.endDateCompare.diff(timePeriod?.startDateCompare, frequency) + 1

    trackEvent({
      action: 'Run Report: Comparison Period',
      label: timePeriod?.enableCompare ? timePeriod?.selectedRangeCompare?.name : 'Off',
      value: timePeriod?.enableCompare ? selectedRangePeriod : 0,
    })
  }
  dropdown &&
    trackEvent({
      action: `run report: ${isBenchmark ? 'Market' : 'Breakdown'}`,
      label: isBenchmark ? marketValue : breakdown,
    })
  scopes &&
    trackEvent({
      action: 'run report: Scope',
      label: selectedScope,
      value: scopes.length,
    })
  market?.program.seller_categories?.length &&
    trackEvent({
      action: 'run report: Seller Categories',
      label: `${marketValue} - ${
        market.program.seller_category === null
          ? 'All market segments'
          : market.program.seller_category
      }`,
    })
}

export const trackEcosystemOptionsFn = (
  option: EcosystemOption,
  trackEvent: TrackEventCallback,
  newValue: OptionValue,
  componentSize: 'Large' | 'Small',
) => {
  option.id === 'growth' &&
    trackEvent({
      action: 'Change ecosystem : Ranking option',
      label: newValue,
      componentSize,
    })
  option.id === 'chartmode' &&
    newValue &&
    trackEvent({
      action: 'Change ecosystem : Display mode',
      label: 'Chart mode activated',
    })
  option.id === 'comparisons' &&
    newValue &&
    trackEvent({
      action: 'change ecosystem : Comparison option',
      label: 'comparison activated',
      componentSize,
    })
  option.id === 'adk_mapping' &&
    !newValue &&
    trackEvent({
      action: 'Change ecosystem : Mapping option',
      label: 'Mapping desactivated',
      componentSize,
    })
  option.id === 'mapping' &&
    trackEvent({
      action: 'Change ecosystem : Mapping option',
      label: newValue,
      componentSize,
    })

  // Show full revenue event
  if (option.id === SHORTCUT_OPTION_REVENUE_DETAIL_KEY && newValue) {
    trackEvent({ action: 'Show: Full Revenue' })
  }
}

export const trackSchedulingFormValues = (formValues: any, trackEvent: TrackEventCallback) => {
  const values = Object.entries(formValues as any)
    .filter(([, value]: any) => !isEmpty(value))
    .map(([key, value]: any) => {
      const label = key === 'emails' ? value.join(', ') : value.label
      return {
        action: `Report: ${
          key === 'emails'
            ? `To ${value.length} recipient${value.length > 1 ? 's' : ''}`
            : key.replace(/_/g, ' ')
        }`,
        label,
      }
    })

  values.forEach(formValue => {
    trackEvent({ category: 'Share dashboard', action: formValue.action, label: formValue.label })
  })
}

export const trackColumnsOnToggleCategory = (
  columnsOfCurrentCategory: ReadonlyArray<EcosystemColumn>,
  activeColumnsOfCurrentCategory: ReadonlyArray<EcosystemColumn>,
  activeColumns: ReadonlyArray<EcosystemColumn>,
  trackEvent: TrackEventCallback,
  ecosystemId: number,
  currentTemplate: string,
) => {
  return columnsOfCurrentCategory.forEach((column, _index, array) => {
    if (column.type !== EcosystemCellType.position) {
      if (!activeColumnsOfCurrentCategory.includes(column)) {
        trackEvent({
          action: `Change Ecosystem N°${ecosystemId + 1}: Columns`,
          label: `${currentTemplate} ${column.label}: ${
            activeColumns.find(({ id }) => id === column.id) ? 'off' : 'on'
          }`,
          componentSize: 'Large',
        })
      }
      if (array.length === activeColumnsOfCurrentCategory.length) {
        trackEvent({
          action: `Change Ecosystem N°${ecosystemId + 1}: Columns`,
          label: `${currentTemplate} ${column.label}: off`,
          componentSize: 'Large',
        })
      }
    }
  })
}
