import { ComponentCode } from 'src/utils/settings'
import { RESET_COMPONENTS_LOADING, SET_COMPONENTS_LOADING } from '../constants/constants'

// root actions interface
export interface RootActionsProps {
  setLoadingComponents: (loading: boolean, componentCode: ComponentCode) => void
  resetLoadingComponents: () => void
}

/**
 * Set components loading
 * @param boolean loading
 * @param ComponentCode componentCode
 */
export const setLoadingComponents = (
  loading: boolean,
  componentCode: ComponentCode,
  error?: Error,
) => ({
  type: SET_COMPONENTS_LOADING,
  payload: { loading, componentCode, error },
})

/**
 * Reset components loading
 */
export const resetLoadingComponents = () => ({
  type: RESET_COMPONENTS_LOADING,
})
