import mapValues from 'lodash/mapValues'

import { moment } from 'src/utils'
import { ChartType } from 'src/utils/chart'
import { humanizeFullDateWithHour } from '.'

export const humanizeDateTime = date => date && moment(date).format('MMMM D, YYYY [at] h:mm a')
export const humanizeDate = date => date && moment(date).format('MMM D, YYYY')
export const humanizeMonth = date => date && moment(date).format('MMM YYYY')
export const humanizeDateShort = date => date && moment(date).format('MMM D')
export const humanizeFullDate = date => date && moment(date).format('YYYY-MM-DD')
export const formatHourDate = date => date && moment(date).format('YYYY-MM-DD-HH')

export const humanizeFullDates = (dates, isHourly = false) =>
  dates && mapValues(dates, el => el && (isHourly ? formatHourDate(el) : humanizeFullDate(el)))

export const months = {
  Jan: 1,
  Feb: 2,
  Mar: 3,
  Apr: 4,
  May: 5,
  Jun: 6,
  Jul: 7,
  Aug: 8,
  Sep: 9,
  Oct: 10,
  Nov: 11,
  Dec: 12,
}

export const getWeekends = (xAxisValues, { view, startDate, endDate }, config) => {
  if (view === 'hourly') {
    return []
  }
  const formatDate = view === 'monthly' ? humanizeMonth : humanizeDateShort

  const frequency = 'days'
  const dateValues = xAxisValues.map(formatDate)
  const dateRangeToMomentObject = moment.range(startDate, endDate)
  const dateRangeToArray = Array.from(dateRangeToMomentObject.by(frequency))
  let iDay = 0
  const weekends = []
  if (dateRangeToArray.length !== dateValues.length) return weekends

  while (iDay < dateValues.length) {
    if (dateRangeToArray[iDay].day() === 6) {
      const toDate = !dateValues[iDay + 1] ? dateValues[iDay] : dateValues[iDay + 1]
      // recharts doesn't handle well referenceArea behavior for bar chart
      // we need to add 1 day to endate when we have a bar chart to make sure
      // sunday is included in referenceArea chart
      const newEndDate = moment(toDate, 'MMM D').format('MMM D')
      weekends.push({
        start: dateValues[iDay],
        end: config.type === ChartType.Bar ? newEndDate : toDate,
      })
      iDay += 7
    } else {
      iDay += 1
    }
  }

  return weekends
}

// Get all 12pm dates in a date range
export const getHourlyStrokes = (xAxisValues, { startDate, endDate }) => {
  const dateRangeToMomentObject = moment.range(startDate, endDate)
  const dateRangeToArray = Array.from(dateRangeToMomentObject.by('hours'))
  const dateValues = xAxisValues.map(humanizeFullDateWithHour)

  if (dateRangeToArray.length !== dateValues.length) return []

  const hourStrokes = []
  dateRangeToArray.forEach((date, index) => {
    if (date.format('HH') === '00') {
      hourStrokes.push(dateValues[index])
    }
  })
  return hourStrokes
}
