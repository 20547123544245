import PropTypes from 'prop-types'

import { parseURL } from 'src/utils/urls'

import * as styles from './ProductPage.scss'

const ProductPage = ({ pathname, settings, title }) => {
  if (!pathname) return null

  const { product: productFormPathname, page, program } = parseURL(pathname)

  const elementCode = page || productFormPathname
  const programCode = program ? `-${program}` : ''

  const element = settings.elementsByCode[`${elementCode}${programCode}`]

  const content = element ? (
    <>
      <div className={styles.Product} data-testid="productpage-product">
        {element.parentName}
      </div>
      <div className={styles.SubProduct} data-testid="productpage-subproduct">
        {element.name}
      </div>
    </>
  ) : (
    <span className={styles.Product} data-testid="productpage-product">
      {title || productFormPathname}
    </span>
  )

  return (
    <div data-testid="productpage" className={styles.ProductPage}>
      {content}
    </div>
  )
}

ProductPage.propTypes = {
  pathname: PropTypes.string,
  title: PropTypes.string,
  settings: PropTypes.shape({
    elementsByCode: PropTypes.object.isRequired,
  }),
}

export default ProductPage
