import { useRouteMatch } from 'react-router-dom'

const useProductMatchParams = () => {
  const routeMatch = useRouteMatch<{ product?: string; subProduct?: string; program?: string }>(
    '/:product/:subProduct/:program?',
  )
  return routeMatch
}

export default useProductMatchParams
