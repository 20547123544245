import React, { FC, ReactNode, useEffect } from 'react'

import Page from 'src/components/ui/atoms/Page'
import AdomikLoader from 'src/components/ui/atoms/PlaceHolder/AdomikLoader'
import Header from 'src/components/ui/molecules/Header'
import useAccount from 'src/hooks/useAccount'
import { adomikLoadingMessageGenerateDispatcher } from 'src/redux/actions/adomikLoadingActions'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import { Product } from 'src/utils/product'
import { getDashboardKeyFn } from 'src/utils/saveDashboardUtils'

import * as styles from './ProductPageProvider.scss'

export interface ProductPageAPI {
  componentAvailability?: string
  componentData?: string
  entitiesSearch?: string
  translate?: string
}

export interface ProductPage {
  product: Product
  name: string
  code: string
  api: ProductPageAPI
  header?: any
  pageTitle?: string
  program?: number
  dashboardKey: string
}

export type ProductPageCtx = ProductPage

export const ProductPageContext = React.createContext<ProductPageCtx>({} as ProductPageCtx)

interface ProductPageProviderProps extends Omit<ProductPageCtx, 'api' | 'dashboardKey'> {
  children: ReactNode
  componentsSettingsLoading?: boolean // From redux store
  api?: ProductPageAPI
}

const ProductPageProvider: FC<ProductPageProviderProps> = ({
  children,
  product,
  name,
  code,
  api = {},
  header,
  program,
  pageTitle,
}) => {
  const { loadingSeller } = useAccount()
  const dispatch = useAppDispatch()
  const componentsSettingsLoading = useAppSelector(state => state.componentsSettings.loading)

  const contextValue = {
    product,
    name,
    code,
    api: {
      ...api,
      componentAvailability: api.componentAvailability || `/api/${product}/availability/component`, // DataDownload only
      componentData: api.componentData || `/api/${product}/component_v2`,
      entitiesSearch: api.entitiesSearch || `/api/${product}/entities_search`,
      translate: api.translate || `/api/${product}/entities/translate`,
    },
    dashboardKey: getDashboardKeyFn({ subProductCode: code, programId: program }),
    program,
  }

  const isLoading = loadingSeller || componentsSettingsLoading

  // Generate a random message for the AdomikLoader
  useEffect(() => {
    if (!isLoading) {
      dispatch(adomikLoadingMessageGenerateDispatcher())
    }
  }, [dispatch, isLoading])

  return (
    <Page title={pageTitle}>
      <ProductPageContext.Provider value={contextValue}>
        <Header
          componentsCodes={header?.componentsCodes}
          printModeAllowed={header?.print_mode_allowed}
        />
        {isLoading ? <AdomikLoader className={styles.AdomikLoader} /> : children}
      </ProductPageContext.Provider>
    </Page>
  )
}

export default ProductPageProvider
