import * as styles from './PaceManLoader.scss'

const PacManLoader = () => (
  <div className={styles.Container}>
    <div className={styles.LoaderParent}>
      <div className={styles.PacMan}>
        <div className={styles.PacManBody} />
        <div className={styles.PacManBody} />
        {[1, 2, 3].map(i => (
          <div className={styles.PacManBall} key={i} />
        ))}
      </div>
    </div>
  </div>
)

export default PacManLoader
