import cx from 'classnames'
import { FC } from 'react'
import { MdModeEdit } from 'react-icons/md'

import BadgeNew from 'src/components/ui/atoms/BadgeNew'
import Copy from 'src/components/ui/atoms/Icons/Copy'
import StarIcon from 'src/components/ui/atoms/Icons/StarIcon'
import { usePopupButtonContext } from 'src/components/ui/atoms/PopupButton/PopupButtonProvider'
import Tooltip from 'src/components/ui/atoms/Tooltip'
import Option from 'src/components/ui/molecules/Dropdown/components/Option'
import Options from 'src/components/ui/molecules/Dropdown/components/Options'
import usePageLoading from 'src/hooks/usePageLoading'
import useTracking from 'src/hooks/useTracking'
import ComponentColor from 'src/types/ItemColor'
import SaveDashboardItemInterface from 'src/types/SaveDashboardItemInterface'
import { truncate } from 'src/utils/text'
import DashboardDeleteIcon from './DashboardDeleteIcon'
import SavedDashboardsActionItem from './SavedDashboardItemAction'

import * as styles from './DashboardsList.scss'

export interface SaveDashboardsItemProps {
  isHovered: boolean
  onHover: (isHovered: boolean) => void
  item: SaveDashboardItemInterface
  deleteDashboard: (dashboard: SaveDashboardItemInterface) => void
  isFavorite: boolean
  isDefaultDashboard: boolean
  setToFavoriteDashboard: (dashboard: SaveDashboardItemInterface) => void
  setAsCurrentDashboard: (dashboard: SaveDashboardItemInterface) => void
  loadDashboard: (dashboard: SaveDashboardItemInterface) => void
  setDisplayModal: (type: 'edit' | 'copy') => void
  isSelected: boolean
  className?: string
  editDashboard: (dashboard: SaveDashboardItemInterface) => void
  componentColor?: ComponentColor
}

const TRUNC_LIMIT = 21

const SaveDashboardsItem: FC<SaveDashboardsItemProps> = ({
  isHovered,
  onHover,
  item,
  deleteDashboard,
  isFavorite,
  setToFavoriteDashboard,
  setAsCurrentDashboard,
  isDefaultDashboard,
  loadDashboard,
  setDisplayModal,
  isSelected,
  className,
  editDashboard,
  componentColor,
}) => {
  const { trackEvent } = useTracking()
  const { label, id, default_dashboard } = item

  const disabledEdit = isDefaultDashboard || Boolean(default_dashboard)

  const disableFavorite = Boolean(default_dashboard)

  const isOrange = componentColor === ComponentColor.orange

  const shouldBeTruncate = isHovered && label.length > TRUNC_LIMIT
  const truncLabel = shouldBeTruncate ? truncate(label, TRUNC_LIMIT) : label

  const { updatePageLoadingAdomikNavigationId } = usePageLoading()

  const { toggle } = usePopupButtonContext()

  const handleEditDashboard = () => {
    editDashboard(item)
  }

  return (
    <Options
      value={label}
      className={cx(
        styles.Options,
        {
          [styles.OptionsSelected]: isSelected,
          [styles.OptionsSelectedOrange]: isOrange && isSelected,
        },
        className,
      )}
      onMouseEnter={() => onHover(true)}
      onMouseLeave={() => onHover(false)}
      key={id}
      onClick={() => {
        // Regenerate page id
        updatePageLoadingAdomikNavigationId()
        loadDashboard(item)
        trackEvent({ action: 'Click: Load Saved Dashboard', label })
        toggle(false)
      }}
      componentColor={componentColor}
      data-pendo={default_dashboard ? 'dashboard-curation-monitoring' : undefined}
      data-testid="save-dashboard-item"
    >
      <div>
        {/* Set as default dashbaord */}
        {!disableFavorite && (
          <SavedDashboardsActionItem
            onClick={() => {
              if (!isFavorite) {
                setToFavoriteDashboard(item)
                trackEvent({ action: 'Click: Favorite Dashboard' })
              }
            }}
            tooltipLabel="Set as default dashboard"
            id={id}
            data-testid="save-dashboard-action-set-as-default-dashboard"
          >
            <StarIcon
              className={cx(styles.Star, {
                [styles.StarActive]: isFavorite,
              })}
            />
          </SavedDashboardsActionItem>
        )}

        {/* Load dashboard on clicking in label  */}
        <Option
          value={item.label}
          className={cx({
            [styles.Label]: true,
            [styles.LabelContainer]: true,
            [styles.LabelContainerWithoutStar]: disableFavorite,
          })}
        >
          <span className={styles.SaveDashboardButtonTitle} data-for={item.label} data-tip="">
            {truncLabel}
          </span>
          {item.viewed === false && <BadgeNew className={styles.LabelContainerBadgeNew} />}
          {shouldBeTruncate && (
            <Tooltip id={item.label} delayShow={100} place="bottom">
              {item.label}
            </Tooltip>
          )}
        </Option>
      </div>
      <div>
        {isHovered && !disabledEdit && (
          <>
            <SavedDashboardsActionItem
              data-testid="save-dashboard-action-rename"
              onClick={handleEditDashboard}
              tooltipLabel="Rename"
              id={id}
            >
              <MdModeEdit />
            </SavedDashboardsActionItem>

            <SavedDashboardsActionItem
              onClick={() => {
                setAsCurrentDashboard(item)
                setDisplayModal('copy')
              }}
              tooltipLabel="Copy"
              id={id}
              className={styles.ActionIconWithMarginLeft}
              data-testid="save-dashboard-action-copy"
            >
              <Copy />
            </SavedDashboardsActionItem>

            <SavedDashboardsActionItem
              onClick={() => {
                trackEvent({ action: 'Click: Delete Dashboard' })
              }}
              tooltipLabel="Delete"
              id={id}
              className={styles.ActionIconWithMarginLeft}
              data-testid="save-dashboard-action-delete"
            >
              <DashboardDeleteIcon
                data-for={`delete-${id}`}
                data-tip=""
                onClick={() => deleteDashboard(item)} // It call confirmation modal so we need to put this onClick here
              />
            </SavedDashboardsActionItem>
          </>
        )}
      </div>
    </Options>
  )
}

export default SaveDashboardsItem
