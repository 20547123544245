import cx from 'classnames'
import React, { ReactNode } from 'react'

import * as styles from '../Dropdown.scss'

const Option: React.FC<{
  children: ReactNode | ReactNode[]
  value: string
  className?: string
  onClick?: () => void
}> = ({ children, className, onClick }) => {
  return (
    <li
      className={cx(className, styles.Value)}
      onClick={() => {
        onClick && onClick()
      }}
      role="listitem"
    >
      {children}
    </li>
  )
}

export default Option
