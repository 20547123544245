import { FC, SVGProps } from 'react'

const ArrowIcon: FC<SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      {...props}
    >
      <path
        d="M4.25 10.651v-9m0 0L1.57 4.186m2.68-2.535 2.678 2.535"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default ArrowIcon
