import { Link } from 'react-router-dom'

import { moment } from 'src/utils'

import * as styles from './Footer.scss'

const URL = '/src/assets/Adomik-Platform-Privacy-policy.pdf'
const LINK_PRIVACY = ' Adomik Privacy Policy'
const copyright = `© ${moment().format('YYYY')} Adomik -`

const Footer = () => (
  <div data-testid="footer" className={styles.Footer}>
    {copyright}
    <Link to={URL} className={styles.Link} target="_blank" rel="noopener noreferrer">
      {LINK_PRIVACY}
    </Link>
  </div>
)

export default Footer
