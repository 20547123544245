import * as Sentry from '@sentry/react'
import { ComponentType, FC } from 'react'
import { Route as ReactRoute, RouteProps } from 'react-router-dom'

import { SENTRY_URL } from 'src/utils/config'

interface RouteInterface extends Omit<RouteProps, 'component'> {
  component?: ComponentType<any>
}

const SentryRoute = Sentry.withSentryRouting(ReactRoute as any)

const Route: FC<RouteInterface> = props => {
  const Component = SENTRY_URL ? ReactRoute : SentryRoute

  return <Component {...props} />
}

export default Route
