import React, { ReactNode, useState } from 'react'

import useFetch from 'src/hooks/useFetch'
import type AdomikOriginType from 'src/types/AdomikOriginType'

const getApiUrl = () => process.env.API_URL

export interface WizardCtx {
  dashboard: ReadonlyArray<any>
  detailsData: object
  getDashboard: (adomikOrigin?: AdomikOriginType) => Promise<any>
  getDetailsData: () => Promise<any>
}

export const WizardContext = React.createContext<WizardCtx>({} as WizardCtx)

const WizardProvider = ({ children }: { children: ReactNode }) => {
  const [dashboard, setDashboard] = useState<ReadonlyArray<any>>([])
  const [detailsData, setDetailsData] = useState<object>({ details_status: [] })

  const [, executeGetDashboard] = useFetch<any>(
    {
      url: `${getApiUrl()}/api/data_status_wizard`,
      method: 'GET',
    },
    { manual: true },
  )

  const [, executeGetDetailsData] = useFetch<any>(
    {
      url: `${getApiUrl()}/api/last_seven_data_status_wizard`,
      method: 'GET',
    },
    { manual: true },
  )

  const getDashboard = async (adomikOrigin?: AdomikOriginType) => {
    const { data: dashboard } = await executeGetDashboard(undefined, undefined, adomikOrigin)
    setDashboard(dashboard)
  }

  const getDetailsData = async () => {
    const { data: detailsData } = await executeGetDetailsData()
    setDetailsData(detailsData)
  }

  const contextValue = {
    dashboard,
    detailsData,
    getDashboard,
    getDetailsData,
  }

  return <WizardContext.Provider value={contextValue}>{children}</WizardContext.Provider>
}

export default WizardProvider
