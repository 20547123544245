import { ComponentType } from 'react'
import { Redirect, RouteProps, useLocation } from 'react-router-dom'

import useAccount from 'src/hooks/useAccount'
import { generateLoginURL } from 'src/utils/urls'
import Route from './Route'

// Show the component only when the user is logged in
// Otherwise, redirect the user to /signin page
const PrivateRoute = ({
  component: Component,
  ...rest
}: RouteProps & { component: ComponentType<any> }) => {
  const { isLogged } = useAccount()
  const location = useLocation()

  return (
    Component && (
      <Route
        {...rest}
        render={props =>
          isLogged ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: generateLoginURL(location) }} />
          )
        }
      />
    )
  )
}

export default PrivateRoute
