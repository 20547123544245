import React, { ReactNode, useCallback, useState } from 'react'

import useAccount from 'src/hooks/useAccount'
import { Filters } from 'src/types/Filters'
import { formattedDateWithHour, humanizeFullDate } from 'src/utils'
import { capitalizeFirst, cleanObject } from 'src/utils/helpers'
import { snakeToCamel } from 'src/utils/text'
import transformFormData from 'src/utils/transformFormData'

export interface FilterBarCtx {
  filters?: Filters
  rawFilters?: { [key: string]: any }
  requestFilters?: { [key: string]: any }
  dataWarnings: object
  updateFilters: (filters: object) => void
  addDataWarning: (code: string, messages: ReadonlyArray<string>) => void
  removeDataWarnings: (code: string) => void
  resetDataWarnings: () => void
  resetFiltersShapes: () => void
}

export const FilterBarContext = React.createContext<FilterBarCtx>({} as FilterBarCtx)

interface FilterBarProviderProps {
  children: ReactNode
  defaultContext?: Partial<FilterBarCtx>
}

// Here is id Forms that should not be saved in the component settings. The saved queries and the scopes are not the same.
export const savedQueriesForms = ['queries_editor']

/**
 * Transform filters bar values
 * @param values
 */
export const transformRawFilterBarValues = (values: any) => {
  const scopes =
    values?.scopes?.raw?.map((tag: any) => {
      return {
        dimension: { ...tag.dimension, label: capitalizeFirst(tag.dimension.label) },
        id: tag.id,
        exclude: tag.exclude || false,
      }
    }) || []
  const program = values?.market?.raw?.program
  const market = program?.id ? { program: { id: program?.id } } : ({} as object)
  if (market && program?.seller_category) {
    market['program']['seller_category'] = program.seller_category
  }
  const isHour = values?.time_period?.raw?.view === 'hourly'
  const humanize = isHour ? formattedDateWithHour : humanizeFullDate
  const obj = {
    breakdown: values?.dimensions?.raw?.id,
    timePeriod: {
      startDate: humanize(values?.time_period?.raw?.startDate),
      startDateCompare: humanize(values?.time_period?.raw?.startDateCompare),
      endDate: humanize(values?.time_period?.raw?.endDate),
      endDateCompare: humanize(values?.time_period?.raw?.endDateCompare),
      selectedRange: values?.time_period?.raw?.selectedRange,
      selectedRangeCompare: values?.time_period?.raw?.selectedRangeCompare,
      frequency: values?.time_period?.raw?.view,
      comparison: values?.time_period?.raw?.comparison,
      enableCompare: values?.time_period?.raw?.enableCompare,
    },
    scopes,
    market,
  }

  return cleanObject(obj)
}

const FilterBarProvider = ({ children, defaultContext }: FilterBarProviderProps) => {
  const [filtersShapes, setFiltersShapes] = useState<{
    filters: Filters
    rawFilters: { [key: string]: any }
    requestFilters: object
  }>()
  const [dataWarnings, setDataWarnings] = useState<object>({})

  const { informations } = useAccount()
  const currentProfilId = informations?.current_profile?.id

  const updateFilters = useCallback(
    (newFilters: any) => {
      const requestFilters = transformFormData(newFilters, { context: 'api' })
      const filters = Object.entries(newFilters).reduce(
        (acc, [key, value]) => ({ ...acc, [snakeToCamel(key)]: (value as any).raw }),
        {} as any,
      )
      filters.currentProfilId = currentProfilId
      const newFiltersShapes = {
        filters,
        rawFilters: newFilters,
        requestFilters,
      }
      setFiltersShapes(newFiltersShapes)
    },
    [dataWarnings, setFiltersShapes, currentProfilId],
  )

  // Add warnings to display on the filter bar submit button
  const addDataWarning = (code: string, messages: ReadonlyArray<string>) => {
    if (messages.length > 0) {
      setDataWarnings(dataWarnings => ({ ...dataWarnings, [code]: messages }))
    }
  }

  const removeDataWarnings = (code: string) =>
    setDataWarnings(dataWarnings => ({ ...dataWarnings, [code]: [] }))

  const resetDataWarnings = () => setDataWarnings(Object.assign({}))
  const resetFiltersShapes = () => setFiltersShapes(undefined)

  const contextValue = {
    ...(defaultContext || {}),
    ...filtersShapes,
    dataWarnings,
    updateFilters,
    addDataWarning,
    resetDataWarnings,
    resetFiltersShapes,
    removeDataWarnings,
  }

  return <FilterBarContext.Provider value={contextValue}>{children}</FilterBarContext.Provider>
}

export default FilterBarProvider
