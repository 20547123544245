import { ResponseValues } from 'axios-hooks'
import pick from 'lodash/pick'
import { useCallback } from 'react'
import toast from 'react-hot-toast'

import { useAppSelector } from 'src/redux/hooks'
import { API } from 'src/utils/config'
import { SHARE_DASHBOARD_MESSAGES } from 'src/utils/constants/shareDashboard'
import { SubProduct, subProductsWithoutTargetPeriodInformations } from 'src/utils/product'
import useFetch from './useFetch'
import useFilterBarSettings from './useFilterBarSettings'
import useProductPage from './useProductPage'

interface ShareDashboardPayload {
  label: string
  userIds: number[]
}

const useShareDashboard = (): [
  ResponseValues<any, any, any>,
  (args: ShareDashboardPayload) => Promise<any>,
] => {
  const settings = useAppSelector(state => state.componentsSettings.settings)
  const [datas, executeShareSaveDashboard] = useFetch(
    {
      url: API.SHARE_SAVE_DASHBOARD,
      method: 'POST',
    },
    {
      manual: true,
    },
  )

  const filterBarSettings = useFilterBarSettings()
  const { code, product, program, dashboardKey } = useProductPage()

  const getSaveDashboardPayloadData = useCallback(
    ({ label, userIds }: ShareDashboardPayload) => {
      const comparisonDisabled = filterBarSettings?.timePeriod?.comparisonDisabled
      let target_period_informations: object = {
        period: filterBarSettings?.timePeriod?.selectedRange,
        frequency: filterBarSettings?.timePeriod.frequency || filterBarSettings?.timePeriod.view,
        custom_period: {
          start_date: filterBarSettings?.timePeriod.startDate,
          end_date: filterBarSettings?.timePeriod.endDate,
        },
        comparisonDisabled,
        comparison: filterBarSettings?.timePeriod?.comparison,
        enableCompare: filterBarSettings?.timePeriod?.enableCompare,
      }

      const currentSettings = pick(settings, [dashboardKey])

      const components_settings = {
        [code]: currentSettings[dashboardKey],
      }

      const payload = {
        product,
        sub_product: code,
        components_settings,
        label,
        user_ids: userIds,
      }

      if (program) {
        payload['program_id'] = program
      }

      if (!subProductsWithoutTargetPeriodInformations.includes(code as SubProduct)) {
        if (!comparisonDisabled) {
          target_period_informations = {
            ...target_period_informations,
            compare_to: filterBarSettings?.timePeriod?.selectedRangeCompare,
            custom_period: {
              ...target_period_informations['custom_period'],
              previous_start_date: filterBarSettings?.timePeriod.startDateCompare,
              previous_end_date: filterBarSettings?.timePeriod.endDateCompare,
            },
          }
        }
        payload['target_period_informations'] = target_period_informations
      }

      return { saved_dashboard: payload }
    },
    [filterBarSettings, code, dashboardKey, product, settings, program],
  )

  const handleShareSaveDashboard = useCallback(
    async (args: ShareDashboardPayload) => {
      return toast.promise(executeShareSaveDashboard({ data: getSaveDashboardPayloadData(args) }), {
        error: SHARE_DASHBOARD_MESSAGES.error,
        success: SHARE_DASHBOARD_MESSAGES.success,
        loading: SHARE_DASHBOARD_MESSAGES.loading,
      })
    },
    [executeShareSaveDashboard, getSaveDashboardPayloadData],
  )

  return [datas, handleShareSaveDashboard]
}

export default useShareDashboard
