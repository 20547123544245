import { lazy } from 'react'

import AppRouteInterface from 'src/types/AppRouteInterface'

const commonRoutes: ReadonlyArray<AppRouteInterface> = [
  {
    path: '/account/',
    component: lazy(
      () =>
        /* webpackMode: "lazy", webpackChunkName: "account_page" */
        import('src/components/ui/pages/common/AccountPage') as any,
    ),
  },
  {
    path: '/administration',
    component: lazy(
      () =>
        /* webpackMode: "lazy", webpackChunkName: "administration_page" */
        import('src/components/ui/pages/common/AdministrationPage'),
    ),
  },
  {
    path: '/availability',
    component: lazy(
      () =>
        /* webpackMode: "lazy", webpackChunkName: "details_data_status_page" */
        import('src/components/ui/pages/common/DetailsDataStatusPage'),
    ),
  },
]

export default commonRoutes
