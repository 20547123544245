// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tooltip__Tooltip___DnGJr{line-height:1rem;word-wrap:break-word;max-width:700px;font-size:12px}.Tooltip__TooltipFixedWidth___FvHC9{width:480px}.Tooltip__TooltipWithArrowOffset___KkDAd::before,.Tooltip__TooltipWithArrowOffset___KkDAd::after{content:"";left:calc(50% + var(--arrow-offset, 0px)) !important}`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/Tooltip/Tooltip.scss"],"names":[],"mappings":"AAAA,0BACE,gBAAA,CACA,oBAAA,CACA,eAAA,CACA,cAAA,CAEA,oCACE,WAAA,CAIA,iGAEE,UAAA,CACA,oDAAA","sourcesContent":[".Tooltip {\n  line-height: 1rem;\n  word-wrap: break-word;\n  max-width: 700px;\n  font-size: 12px;\n\n  &FixedWidth {\n    width: 480px;\n  }\n\n  &WithArrowOffset {\n    &::before,\n    &::after {\n      content: '';\n      left: calc(50% + var(--arrow-offset, 0px)) !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var Tooltip = `Tooltip__Tooltip___DnGJr`;
export var TooltipFixedWidth = `Tooltip__TooltipFixedWidth___FvHC9`;
export var TooltipWithArrowOffset = `Tooltip__TooltipWithArrowOffset___KkDAd`;
export default ___CSS_LOADER_EXPORT___;
