import cx from 'classnames'
import { FC, HTMLAttributes } from 'react'
import { MdClose } from 'react-icons/md'

import * as styles from './ButtonClose.scss'

const DEFAULT_ICON_SIZE = 11.2
export interface ButtonCloseProps extends HTMLAttributes<HTMLDivElement> {
  size?: number
}

const ButtonClose: FC<ButtonCloseProps> = ({ size, className, ...divProps }) => {
  return (
    <div
      role="button"
      aria-label="Close button"
      data-testid="button-close"
      className={cx(styles.ButtonClose, className)}
      {...divProps}
    >
      <MdClose size={size} />
    </div>
  )
}

ButtonClose.defaultProps = {
  size: DEFAULT_ICON_SIZE,
}

export default ButtonClose
