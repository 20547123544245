import { AUTH_TOKEN_KEY } from './constants/localStorageKey'

const OLD_AUTH_TOKEN = 'old_auth_token'

const getAuthToken = () => localStorage.getItem(AUTH_TOKEN_KEY)

const setAuthToken = (token: string) => localStorage.setItem(AUTH_TOKEN_KEY, token)

const setOldAuthToken = (token: string) => localStorage.setItem(OLD_AUTH_TOKEN, token)

const getOldAuthToken = () => localStorage.getItem(OLD_AUTH_TOKEN)

const clearStorage = () => localStorage.clear()

const logout = () => {
  const authToken = getAuthToken()
  setOldAuthToken(authToken || '')
  clearStorage()
}

const storageUtils = {
  getAuthToken,
  setAuthToken,
  clearStorage,
  logout,
  getOldAuthToken,
}

export default storageUtils
