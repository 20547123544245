import cx from 'classnames'
import { FC, HTMLProps, MouseEvent, useRef } from 'react'
import toast from 'react-hot-toast'

import useGetCurrentUrl from 'src/hooks/useGetCurrentUrl'
import useTracking from 'src/hooks/useTracking'
import { copyTextToClipboardFn } from 'src/utils/clipboardUtils'
import Button from '../../atoms/Button'
import { TextInput } from '../../atoms/TextInput'
import Typography from '../../atoms/Typography'

import * as styles from './ShareDashboard.scss'

const ShareDashboardModalShareLink: FC = () => {
  const url = useGetCurrentUrl()

  const { trackEvent } = useTracking()

  const handleCopyLink: HTMLProps<HTMLDivElement>['onCopy'] = evt => {
    evt.preventDefault()
    copyTextToClipboardFn(url)
  }

  const handleClickCopyLink = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    copyTextToClipboardFn(url)
    trackEvent({
      action: 'Click: Copy Share Link',
    })
    toast.success('Link successfully copied')
  }

  const handleClickInput = () => {
    trackEvent({
      action: 'Click: Share a link',
    })
  }

  const inputContainerRef = useRef<HTMLDivElement>(null)

  return (
    <div className={styles.ShareDashboardModalBodyShareLinkContainer}>
      <div className={styles.ShareDashboardModalBodyTitleSection}>
        <Typography variant="title2" fontSize={16}>
          Share your filter
        </Typography>
        <Typography variant="body" fontSize={14}>
          Copy the link below to share your Time Period and Scope. This will not include the
          customisation of your Dashboard (Charts, Ecosystem Tables).
        </Typography>
      </div>
      <div
        ref={inputContainerRef}
        className={styles.ShareDashboardModalBodyShareLinkContainerInputContainer}
        onCopy={handleCopyLink}
      >
        <TextInput
          className={styles.ShareDashboardModalBodyShareLinkContainerInputContainerTextInput}
          label="LINK"
          value={url}
          onChange={e => e.preventDefault()}
          readOnly
          onClick={handleClickInput}
          containerClassName={
            styles.ShareDashboardModalBodyShareLinkContainerInputContainerContainerTextInput
          }
        />
      </div>
      <div className={styles.ShareDashboardModalBodyActionContainer}>
        <Button
          className={cx(
            styles.ShareDashboardModalBodyActionContainerButton,
            styles.ShareDashboardModalBodyActionContainerButtonShare,
          )}
          onClick={handleClickCopyLink}
          buttonType="primary"
        >
          Copy Link
        </Button>
      </div>
    </div>
  )
}

export default ShareDashboardModalShareLink
