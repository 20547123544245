import { Metric, MetricChartData } from 'src/utils/metric'
import { Dimension } from 'src/utils/search'
import { Scope, TimePeriod } from '../types/Filters'

export enum ComponentCode {
  FilterBar = 'filterBar',
  KeyMetrics = 'keyMetrics',
  MultiCharts = 'multiCharts',
  Ecosystems = 'ecosystems',
  CheckboxesGroup = 'checkboxesGroup', // Data Download
  EcosystemsPartner = 'EcosystemsPartner',
}

export type FilterBarFields = ReadonlyArray<{
  dimension: string
  id: string | number | null
  exclude: boolean
}>

export type EcosystemFilter = { column: string; value: any }

export type KeyMetricsType = {
  metric?: { code: string }
  chart?: {
    mode?: string
    label?: string
    breakdowns?: ReadonlyArray<string>
  } & MetricChartData
  toggledMetrics?: ReadonlyArray<Metric> // Metrics Grid Container
  chartsData?: ReadonlyArray<MetricChartData[]> // Metrics Grid Container
}

export interface ComponentsSettings {
  [subProduct: string]: {
    [ComponentCode.FilterBar]?: {
      breakdown?: string
      fields?: FilterBarFields
      scopes?: any
      timePeriod?: any
      market?: any
    }
    [ComponentCode.KeyMetrics]?: KeyMetricsType
    [ComponentCode.MultiCharts]?: {
      data: any[]
    }
    [ComponentCode.CheckboxesGroup]?: {
      data: any[]
    }
    [ComponentCode.Ecosystems]?: {
      tables: ReadonlyArray<{
        id: number
        primaryDimension: Dimension
        secondDimension?: Dimension
        chartmode: string | false
        chartmode_selected_items: string[]
        template?: { id: string; label: string }
        options?: ReadonlyArray<{ id: string; value: boolean | string }>
        columns?: ReadonlyArray<string>
        filters?: ReadonlyArray<EcosystemFilter>
        sort?: {
          column: string
          direction: string
        }
      }>
    }
  }
}

export interface SettingsLoaded {
  [subProduct: string]: {
    [ComponentCode.FilterBar]?: boolean
    [ComponentCode.KeyMetrics]?: boolean
    [ComponentCode.MultiCharts]?: boolean
    [ComponentCode.CheckboxesGroup]?: boolean
    [ComponentCode.Ecosystems]?: boolean
  }
}

export interface SettingsTimePeriod extends TimePeriod {
  comparisonDisabled: boolean
  selectedRange: { id: string; name: string }
  selectedRangeCompare: { id: string; name: string }
}

export interface FilterBarSettings {
  breakdown: string
  scopes: ReadonlyArray<Scope>
  timePeriod: SettingsTimePeriod
}
