const parseEnvVariable = variable => {
  switch (variable) {
    case 'true':
      return true

    case 'false':
      return false

    default:
      return variable
  }
}

export const GOOGLE_ANALYTICS_MEASUREMENT_ID = process.env.GOOGLE_ANALYTICS_MEASUREMENT_ID || ''

export const IS_DEV = process.env.NODE_ENV === 'development'
export const USE_REDUX_LOGGER = parseEnvVariable(process.env.USE_REDUX_LOGGER)
export const CHECK_PROPTYPES_IN_DEPTH = parseEnvVariable(process.env.CHECK_PROPTYPES_IN_DEPTH)
export const SENTRY_URL = parseEnvVariable(process.env.SENTRY_URL)
export const API_USER_URL = parseEnvVariable(process.env.API_URL)
export const API_DATA_URL = parseEnvVariable(process.env.REPORTING_URL)
export const SNOWFLAKE_REPORTING_URL = parseEnvVariable(process.env.SNOWFLAKE_REPORTING_URL)

export const API = {
  DATA: API_DATA_URL,
  SNOWFLAKE_REPORTING_URL,
  USER: API_USER_URL,
  USER_LOGIN: `${API_USER_URL}/auth/login.json`,
  USER_LOGOUT: `${API_USER_URL}/auth/logout.json`,
  USER_PASSWORD: `${API_USER_URL}/auth/password.json`,
  USER_AUTH: `${API_USER_URL}/auth`,
  USER_PROFILES_SWITCH: `${API_USER_URL}/api/admin/profiles/switch`,
  USER_INFORMATIONS: `${API_USER_URL}/api/admin/account`,
  USER_CONNECT_AS: `${API_USER_URL}/api/admin/account/connect_as`,
  USER_QUERIES: `${API_USER_URL}/api/queries`,
  USER_CONFIGURATIONS: `${API_USER_URL}/api/saved_configs`,
  SERVICE_MAPPING_DIMENSIONS: `${API_USER_URL}/api/mapping/dimensions`,
  SERVICE_MAPPING_PANEL_VIEW: `${API_USER_URL}/api/mapping/entities`,
  SAVE_DASHBOARD_USERS: `${API_USER_URL}/api/admin/saved_dashboards/profiles_users`,
  SHARE_SAVE_DASHBOARD: `${API_USER_URL}/api/admin/saved_dashboards/share`,
}

export const CROSS_STORAGE_PLATFORM_URL = process.env.CROSS_STORAGE_PLATFORM_URL || ''
export const REFINER_PROJECT_ID = process.env.REFINER_PROJECT_ID || ''
export const PENDO_API_KEY = process.env.PENDO_API_KEY || ''
