import { useCallback, useState } from 'react'

const useLocalStorage = (key: string) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key)
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : undefined
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  })

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (value: (v: any) => void | string) => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore = value instanceof Function ? value(storedValue) : value
        // Save state
        setStoredValue(valueToStore)
        // Save to local storage
        window.localStorage.setItem(key, JSON.stringify(valueToStore))
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
      }
    },
    [key, storedValue],
  )

  return [storedValue, setValue]
}

export default useLocalStorage
