import { v4 as uuidV4 } from 'uuid'

import { PageLoadingInterface } from 'src/types/PageLoadingInterface'
import { setExtraSentryFn, setSentryTagFn } from 'src/utils/sentryUtils'

export default class PageLoading {
  static instance: PageLoading | null = null

  value: PageLoadingInterface = {
    adomikNavigationId: null,
    adomikPlatformContext: null,
    adomikPlatformContextId: null,
    globalRequestId: '',
  }

  static getInstance(): PageLoading {
    if (PageLoading.instance === null) {
      PageLoading.instance = new PageLoading()
    }
    return PageLoading.instance
  }

  getData(): PageLoadingInterface {
    return this.value
  }

  setAdomikNavigationId(isGlobal = true): string {
    this.value.adomikNavigationId = uuidV4()
    if (isGlobal) {
      this.value.globalRequestId = this.value.adomikNavigationId
    }
    setSentryTagFn('navigation_id', this.value.adomikNavigationId)
    return this.value.adomikNavigationId as string
  }

  setAdomikPlatformContext(adomikPlatformContext: PageLoadingInterface['adomikPlatformContext']) {
    this.value.adomikPlatformContext = adomikPlatformContext
    setExtraSentryFn('adomikPlatformContext', adomikPlatformContext)
    return this.value.adomikPlatformContext
  }

  setAdomikPlatformContextId(
    adomikPlatformContextId: PageLoadingInterface['adomikPlatformContextId'],
  ) {
    this.value.adomikPlatformContextId = adomikPlatformContextId
    setExtraSentryFn('adomikPlatformContextId', adomikPlatformContextId)
    return this.value.adomikPlatformContextId
  }
}
