import { useCallback, useContext, useEffect } from 'react'
import toast from 'react-hot-toast'

import {
  AccountContext,
  AccountCtx,
  ConnectAsFn,
  UpdateProfileFn,
} from 'src/components/data/providers/AccountProvider'
import useProductPage from 'src/hooks/useProductPage'
import useSettings from 'src/hooks/useSettings'
import useWizard from 'src/hooks/useWizard'
import { setSentryUserFn } from 'src/utils/sentryUtils'
import { getAdomikOriginFn } from 'src/utils/snowflakeProductsUtils'

const useAccount = (): AccountCtx & {
  changeSeller: ConnectAsFn
  changeProfile: UpdateProfileFn
} => {
  const context = useContext(AccountContext)
  const {
    connectAs: connectAsContext,
    updateProfile: updateProfileContext,
    getCurrentUser,
    setLoadingSeller,
  } = context

  const { getDashboards, getComponents, getMenu, getCurrencies, getFavoriteDashboardSettings } =
    useSettings()
  const { getDashboard } = useWizard()
  const { code, program } = useProductPage()

  const refreshSettings = useCallback(async () => {
    await getCurrentUser()
    const adomikOrigin = getAdomikOriginFn(code)

    return Promise.all([
      getComponents(adomikOrigin),
      getMenu(adomikOrigin),
      getDashboard(adomikOrigin),
      getCurrencies(adomikOrigin),
      getDashboards({ subProduct: code, reset: true, adomikOrigin, programId: program }),
      getFavoriteDashboardSettings({
        subProduct: code,
        enableLoading: true,
        resetSettings: true,
        adomikOrigin,
        programId: program,
      }),
    ])
  }, [
    code,
    getComponents,
    getCurrencies,
    getCurrentUser,
    getDashboard,
    getDashboards,
    getFavoriteDashboardSettings,
    getMenu,
  ])

  const changeSeller: ConnectAsFn = async (sellerId: string, cb?: () => void) => {
    setLoadingSeller(true)
    cb?.()
    await connectAsContext(sellerId)
    await refreshSettings()
    setLoadingSeller(false)
    toast.success('Company updated')
  }

  const changeProfile: UpdateProfileFn = async (sellerId: string, cb?: () => void) => {
    setLoadingSeller(true)
    cb?.()
    await updateProfileContext(sellerId)
    await refreshSettings()
    setLoadingSeller(false)
    toast.success('Profile updated')
  }

  // Set sentry user
  const user = context?.informations
  useEffect(() => {
    setSentryUserFn(user)
  }, [user])

  return { ...context, changeSeller, changeProfile }
}

export default useAccount
