import omit from 'lodash/omit'
import upperFirst from 'lodash/upperFirst'

import { DimensionComponentInterface } from 'src/types/DimensionInterface'
import Fields from './fields'

const findComponentIdentifierInTree = (component: any, componentIdentifier: string) => {
  if (component.component_identifier === componentIdentifier) {
    return component
  }

  if (component.children) {
    for (let i = 0; i < component.children.length; i++) {
      const result = findComponentIdentifierInTree(component.children[i], componentIdentifier)
      if (result) {
        return result
      }
    }
  }
  return null
}

const getProductFromComponents = (
  components: Record<string, { children: any[] }>,
  productCode: string,
) => {
  const componentKeys = Object.keys(components)
  for (const componentKey of componentKeys) {
    const product = components[componentKey].children?.find(({ code }) => code === productCode)
    if (product) {
      return product
    }
  }
  return null
}

/**
 * Get all sub product codes from a list of products
 */
export const getProductDimensionsFn = ({
  components,
  currentProductCode,
}: {
  components: Record<string, { children: any[] }>
  currentProductCode: string
}): DimensionComponentInterface[] => {
  const currentProduct = getProductFromComponents(components, currentProductCode)

  if (!currentProduct) return []

  return findComponentIdentifierInTree(currentProduct, Fields.Search)?.config || []
}

/**
 * Get mapped product
 */
export const getMappedProductDimensionsFn = ({
  components,
  currentProductCode,
  componentCode,
}: {
  components: Record<string, { children: any[] }>
  currentProductCode: string
  componentCode: string
}): Record<string, string | null> => {
  const currentProduct = getProductFromComponents(components, currentProductCode)

  if (!currentProduct) return {}

  return omit(
    findComponentIdentifierInTree(currentProduct, Fields.Search)?.dimensions_mapping?.find(
      ({ code }) => code === componentCode,
    ) || {},
    ['code'],
  )
}

export const getProductSuffixNameFn = (productName: string) => {
  if (productName === 'Deal') {
    return ''
  }
  if (productName === 'New Deal') {
    return 'New'
  }
  return upperFirst(productName)
}
