const ChevronDown = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="6"
    className="chevron-down-icon"
    data-testid="chevron-down-icon"
    viewBox="0 0 9 6"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M7.72.97L4.5 4.19 1.28.97A.75.75 0 0 0 .22 2.03l3.75 3.75a.75.75 0 0 0 1.06 0l3.75-3.75A.75.75 0 1 0 7.72.97z"
    />
  </svg>
)

export default ChevronDown
