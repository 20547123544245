const WarningIcon = props => (
  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
)

export default WarningIcon
