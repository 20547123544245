import { AnyAction } from 'redux'

import { ADOMIK_LOADING_MESSAGE_GENERATE, PACMAN_LOADER_ENABLE } from '../constants/constants'

const QUOTES = [
  'One dataset to rule them all.',
  'Hasta la data, baby.',
  'To increase or to decrease prices, that is the question.',
  'Blue chart or red table?',
  'May the data be with you.',
  'Hey! This is a big deal. A private one.',
  'Call that buyer... Make him an offer he can’t refuse',
  'The first rule of monetization management is: You do not talk about monetization management.',
  'This is the beginning of a beautiful data friendship.',
  'Bid. James Bid.',
  'E.T. phone customer support.',
  'To infinite yield... and beyond!',
  'Carpe Diem. Seize the data.',
  'Show me the money... tization.',
  'I love the smell of data in the morning.',
  'Fasten your seatbelts. It’s going to be a bumpy data analysis.',
  'Spreadsheets? Where we’re going we don’t need spreadsheets.',
  'The greatest trick Adomik mapping ever pulled was convincing the world it didn’t exist.',
  'I’m the king of data unification!',
  'My Mama always said, "Analytics is like a box of chocolates; you never know what you’re gonna get."',
  'No. Try not. Do... Or do not. There is no try.',
  'Elementary, my dear dashboard!',
  '"Holistic yield... the final frontier" - Stack trek.',
  'Oh my God! They unified Kenny!',
  'I feel the need... The need for Yield!',
  'Holistic yield is coming...',
  'I’m tired, boss. Tired of being on spreadsheets every day.',
  'You can always map what you want.',
  'The yield will be with you, always - Adomik Kenobi.',
  'All you need is Adomik.',
  'Don’t stop till you get enough charts.',
  'Gimme baby one more chart.',
  'Stand back! ... Alohomora data!',
  'You’ll never analyze alone.',
  'Another spreadsheet bites the dust.',
  'One for the money. Two for the charts.',
  "You know I'm bid, I'm biiiid",
  'Winter is bidding',
  'Another bid in the wall (garden)',
  'Smells like team spirit',
  'I can’t bid... with or without you',
  'We don’t need no mediation - we do need floor control !',
  'You go back to Report... and others go back to black',
  'Dreams of data unification',
  'I was made for bidding on you baby!',
  'You only get one bid, do not miss your chance to blow - This opportunity cost comes once in a lifetime',
  "Manual data collection!.....we don't do that here.",
  'Bid iiit, bid iiiit',
  'I Map the Line',
]

export interface AdomikLoadingStoreProps {
  loadingMessage: string
  pacmanLoaderEnabled: boolean
}

const generateRandomMessage = () => QUOTES[Math.floor(Math.random() * QUOTES.length)]

const initialState: AdomikLoadingStoreProps = {
  loadingMessage: generateRandomMessage(),
  pacmanLoaderEnabled: false,
}

const adomikLoadingReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ADOMIK_LOADING_MESSAGE_GENERATE: {
      return { ...state, loadingMessage: generateRandomMessage() }
    }

    case PACMAN_LOADER_ENABLE: {
      const { pacmanLoaderEnabled } = action.payload

      return { ...state, pacmanLoaderEnabled }
    }

    default:
      return state
  }
}

export default adomikLoadingReducer
