import numeral from 'numeral'

import customLocales from 'src/utils/shapeNumber/locales'

export type ShapeNumberOptions = {
  decimals?: number
  zeroFormat?: boolean
  displayUnit?: boolean
  format?: string
  currencyCode?: string
  truncate?: boolean
  isApproximation?: boolean
}

const defaultShapeNumberOptions = {
  decimals: 2,
  zeroFormat: false,
  displayUnit: true,
  truncate: true,
}

export const shapeNumberFn = (
  number: number,
  shapeNumberOptions: ShapeNumberOptions,
  formatCurrency = false,
): string => {
  const options = { ...defaultShapeNumberOptions, ...shapeNumberOptions }

  const { format, currencyCode, displayUnit, decimals, truncate, zeroFormat } = options

  numeral.reset()
  if (currencyCode) {
    customLocales(currencyCode)
  }

  const numeralFormat = format
    ? format
    : `0${!truncate ? ',0' : ''}.${'0'.repeat(decimals)}${displayUnit && truncate ? 'a' : ''}`

  const numeralNumber = numeral(numeralFormat.includes('%') ? number / 100 : number)

  if (zeroFormat) {
    numeral.zeroFormat('--')
  }

  const formattedNumber = `${numeralNumber.format(numeralFormat)}`

  if (formatCurrency) {
    const clonedNumeral = numeral(1).format('$0,0.00')
    const currency = clonedNumeral.replace(/[0-9,.]/g, '')

    if (currency) {
      return formattedNumber.replace(currency, `<span class="currency">${currency}</span>`)
    }
  }

  return formattedNumber
}

/**
 * Get the currency symbol for a given currency code
 */
export const getCurrencySymbolFn = (currencyCode?: string): string => {
  numeral.reset()
  if (currencyCode) {
    customLocales(currencyCode)
  }
  const formattedPrice = numeral(1).format('$0,0.00')

  return formattedPrice.replace(/[0-9,.]/g, '')
}

export const toFixedNumberFn = (number: number, decimals: number): number => {
  const pow = Math.pow(10, decimals)
  return Math.round(number * pow) / pow
}

export const countDecimalsFn = (number: number): number => {
  if (Math.floor(number) === number) return 0
  return number.toString().split('.')[1]?.length || 0
}

// Numerical format
export const toNumericalFormatFn = (number: number, format: string): string => {
  numeral.reset()
  return numeral(number).format(format)
}
