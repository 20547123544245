import { lazy } from 'react'

import prebidRoutes from 'src/routes/prebidRoutes'
import AppRouteInterface from 'src/types/AppRouteInterface'
import { Product, PRODUCT_PATHS, SubProduct } from 'src/utils/product'

const productsRoutes: ReadonlyArray<AppRouteInterface> = [
  {
    path: PRODUCT_PATHS[SubProduct.Dashboard],
    component: lazy(
      () =>
        /* webpackMode: "lazy", webpackChunkName: "report__dashboard__page" */
        import('src/components/ui/pages/products/report/DashboardPage'),
    ),
  },
  {
    path: PRODUCT_PATHS[SubProduct.DataDownload],
    component: lazy(
      () =>
        /* webpackMode: "lazy", webpackChunkName: "report__datadownload__page" */
        import('src/components/ui/pages/products/report/DataDownloadPage'),
    ),
  },
  {
    path: PRODUCT_PATHS[SubProduct.Analytics],
    component: lazy(
      () =>
        /* webpackMode: "lazy", webpackChunkName: "benchmark__analytics__page" */
        import('src/components/ui/pages/products/benchmark/AnalyticsPage'),
    ),
  },
  {
    path: PRODUCT_PATHS[SubProduct.MarketOverview],
    component: lazy(
      () =>
        /* webpackMode: "lazy", webpackChunkName: "benchmark__market_overview__page" */
        import('src/components/ui/pages/products/benchmark/MarketOverviewPage'),
    ),
  },
  {
    path: PRODUCT_PATHS[SubProduct.MarketAlerts],
    component: lazy(
      () =>
        /* webpackMode: "lazy", webpackChunkName: "page_builder" */
        import('src/components/ui/pages/products/benchmark/MarketAlertsPage'),
    ),
  },
  {
    path: PRODUCT_PATHS[SubProduct.MarketAlertsV2],
    component: lazy(
      () =>
        /* webpackMode: "lazy", webpackChunkName: "benchmark_market_intelligence_page" */
        import('src/components/ui/pages/products/market_intel/MarketIntelAlerts'),
    ),
  },
  {
    path: PRODUCT_PATHS[Product.MARKET_INTEL],
    component: lazy(
      () =>
        /* webpackMode: "lazy", webpackChunkName: "benchmark_market_intelligence_page" */
        import('src/components/ui/pages/products/market_intel/MarketIntelligence'),
    ),
  },
  {
    path: PRODUCT_PATHS[SubProduct.BidAnalytics],
    component: lazy(
      () =>
        /* webpackMode: "lazy", webpackChunkName: "troubleshoot__bid_analytics__page" */
        import('src/components/ui/pages/products/troubleshoot/BidAnalyticsPage'),
    ),
  },
  {
    path: PRODUCT_PATHS[SubProduct.DealAlerts],
    component: lazy(
      () =>
        /* webpackMode: "lazy", webpackChunkName: "deal__bid_analytics__page" */
        import('src/components/ui/pages/products/deal/DealAlertsPage'),
    ),
  },
  {
    path: PRODUCT_PATHS[SubProduct.DealAlertsV2],
    component: lazy(
      () =>
        /* webpackMode: "lazy", webpackChunkName: "deal__deal_alert_v2__page" */
        import('src/components/ui/pages/products/deal_v2/DealPage'),
    ),
  },
  {
    path: PRODUCT_PATHS[SubProduct.DealCenterV2],
    component: lazy(
      () =>
        /* webpackMode: "lazy", webpackChunkName: "deal__deal_center_v2__page" */
        import('src/components/ui/pages/products/deal_v2/DealPage'),
    ),
  },
  {
    path: PRODUCT_PATHS[SubProduct.DealCenter],
    component: lazy(
      () =>
        /* webpackMode: "lazy", webpackChunkName: "deal__deal_center_ecosystem__page" */
        import('src/components/ui/pages/products/deal/DealCenterPage'),
    ),
  },
  {
    path: PRODUCT_PATHS[SubProduct.RevenueImpact],
    component: lazy(
      () =>
        /* webpackMode: "lazy", webpackChunkName: "price_revenue_impact_page" */
        import('src/components/ui/pages/products/price/RevenueImpactPage'),
    ),
  },
  ...prebidRoutes,
]

export default productsRoutes
