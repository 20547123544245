import { getProductApiUrlFn } from 'src/utils/snowflakeProductsUtils'
import useIsSnowflakeProduct from '../useIsSnowflakeProduct'

const useApiUrl = () => {
  const isSnowflakeProduct = useIsSnowflakeProduct()
  const api = getProductApiUrlFn(isSnowflakeProduct)
  return api
}

export default useApiUrl
