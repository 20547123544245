import cx from 'classnames'
import { FC, useCallback, useState } from 'react'
import { FaLink } from 'react-icons/fa'

import Button from 'src/components/ui/atoms/Button'
import useTracking from 'src/hooks/useTracking'
import ShareDashboardModal from './ShareDashboardModal'

import * as styles from './ShareDashboard.scss'

export interface ShareDashboardProps {
  isLoading: boolean
  className?: string
}

const ShareDashboard: FC<ShareDashboardProps> = ({ className }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [setIsModalOpen])

  const { trackEvent } = useTracking()

  const handleOpenShareDashboardModal = () => {
    setIsModalOpen(true)
    trackEvent({
      action: 'Share Dash/url button',
    })
  }

  return (
    <div className={styles.ShareDashboard}>
      <Button
        buttonType="silence"
        className={cx(styles.ShareDashboardButton, className)}
        onClick={handleOpenShareDashboardModal}
        data-testid="share-button"
      >
        <FaLink /> &nbsp; Share
      </Button>
      <ShareDashboardModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </div>
  )
}

export default ShareDashboard
