import find from 'lodash/fp/find'
import flow from 'lodash/fp/flow'
import get from 'lodash/fp/get'
import uniq from 'lodash/uniq'
import xorBy from 'lodash/xorBy'

import { EcosystemSelectedScopesTag } from 'src/redux/reducers/filterBarReducer'
import { TimePeriod } from 'src/types/Filters'
import { formattedDateWithHour } from 'src/utils'
import { humanizeFullDate } from 'src/utils/time'
import { FILTER_BAR_REMOVE_TAG_ID } from './constants/filterbar'

export const getGeoCountryFromProgram = program =>
  flow(find('geo_country'), get('geo_country'))(program?.scope)

// an id uniq for each tag
const UNIQ_ID = '_#&!@#'

/**
 * Remove tags with same dimension as the tag selected in launching and not from launching
 */
export const removeNotFromLaunchingTagsDimension = (args: {
  oldScopesTags: EcosystemSelectedScopesTag[]
  currentScopesTags: EcosystemSelectedScopesTag[]
  tags: EcosystemSelectedScopesTag[]
}): EcosystemSelectedScopesTag[] => {
  const { currentScopesTags, oldScopesTags, tags } = args

  //   Get all added tags updated from launching
  const tagsUpdated = xorBy(
    oldScopesTags,
    currentScopesTags,
    ({ id, isLaunching }) => `${id}-${isLaunching ? UNIQ_ID : ''}`,
  )

  //   Get all dimensions ids to update
  const dimensionIdsToUpdate = uniq(tagsUpdated.map(({ dimension }) => dimension.id))

  if (dimensionIdsToUpdate.length) {
    // Remove all tags not from launching
    return tags.filter(
      ({ dimension, from }) =>
        !dimensionIdsToUpdate.includes(dimension.id || (dimension as any).dimension) ||
        from === 'launch_in',
    )
  }

  const hasRemove = currentScopesTags.some(({ id }) => id === FILTER_BAR_REMOVE_TAG_ID)

  if (hasRemove) {
    return tags.filter(({ from }) => from === 'launch_in')
  }

  return tags
}

// Parse timePeriod
export const parseTimePeriodFn = (timePeriod: TimePeriod) => {
  const { endDate, endDateCompare, startDate, startDateCompare, view } = timePeriod
  const isHour = view === 'hourly'
  const humanize = isHour ? formattedDateWithHour : humanizeFullDate
  return {
    startDate: humanize(startDate),
    startDateCompare: humanize(startDateCompare),
    endDate: humanize(endDate),
    endDateCompare: humanize(endDateCompare),
    view,
  }
}
