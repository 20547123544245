import { UserInterface } from 'src/types/UserInterface'
import { AccountInformations } from './account'

/**
 * Get user full name
 */
export const getUserFullNameFn = (user: UserInterface) => {
  if (!user) {
    return '-'
  }
  const { first_name: firstName, last_name: lastName, email } = user
  if (!firstName && !lastName) {
    return email ?? '-'
  }
  return `${firstName || ''}${firstName && lastName ? ' ' : ''}${lastName || ''}`
}

/**
 * Get full user account name
 */
export const getUserAccountNameFn = (
  userAccount: Pick<AccountInformations, 'first_name' | 'last_name'>,
): string => getUserFullNameFn(userAccount as UserInterface)
