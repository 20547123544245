import cx from 'classnames'
import { cloneElement, FC, MouseEvent, ReactElement } from 'react'

import Tooltip from 'src/components/ui/atoms/Tooltip'
import OptionItem, {
  OptionItemProps,
} from 'src/components/ui/molecules/Dropdown/components/OptionItem'

import * as styles from './DashboardsList.scss'

interface SavedDashboardsActionItemProps extends OptionItemProps {
  tooltipLabel: string
  onClick: () => void
  children: ReactElement
  id: string
  className?: string
}

const SavedDashboardsActionItem: FC<SavedDashboardsActionItemProps> = ({
  children,
  onClick,
  id,
  tooltipLabel,
  className,
  ...optionItemProps
}) => {
  const tooltipId = `${tooltipLabel.toLocaleLowerCase()}-${id}`
  const ClonedReactElement = cloneElement(children, {
    'data-for': tooltipId,
    'data-tip': '',
  })

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.preventDefault()
    onClick()
  }

  return (
    <OptionItem {...optionItemProps} className={cx(styles.Icon, className)} onClick={handleClick}>
      {ClonedReactElement}
      <Tooltip id={tooltipId} delayShow={100} place="bottom">
        {tooltipLabel}
      </Tooltip>
    </OptionItem>
  )
}

export default SavedDashboardsActionItem
