import { FC } from 'react'
import { Toaster as DefaultToaster } from 'react-hot-toast'

const Toaster: FC = () => {
  return (
    <DefaultToaster
      position="top-center"
      toastOptions={{
        duration: 4000,
        style: {
          background: '#000',
          padding: '10px',
          color: '#FFF',
          fontSize: '0.8375rem',
        },
        loading: {
          icon: '⏳',
        },
        error: {
          style: {
            background: '#e74c3c',
          },
        },
      }}
    />
  )
}

export default Toaster
