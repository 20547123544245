import { useEffect, useMemo, useRef } from 'react'
import isEqual from 'react-fast-compare'

import { shareDashboardUsersSetUsersDispatcher } from 'src/redux/actions/shareDashboardUsersActions'
import { useAppSelector } from 'src/redux/hooks'
import { UserInterface } from 'src/types/UserInterface'
import { API } from 'src/utils/config'
import { getUserFullNameFn } from 'src/utils/userUtils'
import useAccount from './useAccount'
import useDispatcher from './useDispatcher'
import useFetch from './useFetch'

const URL = API.SAVE_DASHBOARD_USERS

const useGetShareDashboardUsers = (needToFetch?: boolean) => {
  const { informations } = useAccount()
  const users = useAppSelector(state => state.shareDashboardUsers.users)
  const handleSetUsers = useDispatcher(shareDashboardUsersSetUsersDispatcher)
  const [{ loading, error }, executeFetchDashboards] = useFetch<UserInterface[]>(
    {
      url: URL,
      method: 'GET',
    },
    { manual: true },
  )

  const lastInformationRef = useRef<(typeof informations)['current_profile'] | null>(null)

  const hasData = Boolean(users?.length)

  useEffect(() => {
    if (
      needToFetch &&
      informations &&
      (!isEqual(informations.current_profile, lastInformationRef.current) || !hasData)
    ) {
      lastInformationRef.current = informations.current_profile
      executeFetchDashboards().then(({ data }) => {
        handleSetUsers(
          data.sort((a, b) =>
            new Intl.Collator('en', { sensitivity: 'base' }).compare(
              getUserFullNameFn(a),
              getUserFullNameFn(b),
            ),
          ),
        )
      })
    }
  }, [informations?.current_profile?.id, needToFetch, hasData])

  const datas = useMemo(
    () => ({
      data: users,
      loading,
      error,
    }),
    [users, loading, error],
  )

  return datas
}

export default useGetShareDashboardUsers
