import cx from 'classnames'
import PropTypes from 'prop-types'

import BallPulseLoader from '../../PlaceHolder/BallPulseLoader/BallPulseLoader'

import * as styles from './Button.scss'

const Button = props => {
  const {
    children,
    className,
    onClick,
    primary = false,
    initial = false,
    secondary = false,
    tertiary = false,
    quaternary = false,
    silence = false,
    primarySilent = false,
    small = false,
    disabled = false,
    Daily,
    Monthly,
    isCalendarGrey,
    loading,
    ...othersProps
  } = props

  const buttonClass = cx({
    [className]: !!className,
    [styles.Default]: initial,
    [styles.Silence]: silence,
    [styles.Primary]: primary,
    [styles.PrimarySilent]: primarySilent,
    [styles.Secondary]: secondary,
    [styles.Tertiary]: tertiary,
    [styles.Quaternary]: quaternary,
    [styles.Small]: small,
    [styles.Disable]: disabled,
    [styles.Daily]: Daily,
    [styles.Monthly]: Monthly,
    [styles.GreyCalendar]: isCalendarGrey,
    [styles.Loading]: loading,
  })

  return loading ? (
    <BallPulseLoader variant="primary" />
  ) : (
    <button
      type="button"
      className={buttonClass}
      onClick={e => (onClick && !loading ? onClick(e) : null)}
      {...othersProps}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  primary: PropTypes.bool,
  primarySilent: PropTypes.bool,
  initial: PropTypes.bool,
  silence: PropTypes.bool,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  quaternary: PropTypes.bool,
  small: PropTypes.bool,
  disable: PropTypes.bool,
  onClick: PropTypes.func,
  Daily: PropTypes.bool,
  isCalendarGrey: PropTypes.bool,
  Monthly: PropTypes.bool,
}

export default Button
