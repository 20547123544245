import cx from 'classnames'
import { FC } from 'react'

import WarningIcon from 'src/components/ui/atoms/Icons/WarningIcon'

import * as styles from './Alert.scss'

export interface AlertProps {
  message: {
    msg: string
    warning?: boolean
  }
}

const Alert: FC<AlertProps> = ({ message }) => (
  <p
    role="alert"
    className={cx(styles.Alert, {
      [styles.AlertWarning]: message.warning,
    })}
  >
    <span className={styles.IconWrapper}>
      <WarningIcon />
    </span>

    <span>{message.msg}</span>
  </p>
)

export default Alert
