import { GetMenuInterface, MenuInterface } from 'src/types/MenuInterface'

/**
 * Transform program list to menu list
 */
export const transformProgramMenusFn = (menus: GetMenuInterface[]): MenuInterface[] => {
  return menus.reduce<MenuInterface[]>((acc, menu) => {
    if (menu.program_list?.length) {
      const programMenus: MenuInterface[] = menu.program_list.map(program => {
        return {
          ...menu,
          id: `${menu.id}-${program.id}`,
          programCode: `${menu.code}-${program.id}`,
          name: program.label,
          program,
          program_list: undefined,
          children: menu.children ? transformProgramMenusFn(menu.children) : undefined,
        }
      })
      return [...acc, ...programMenus]
    }
    return [
      ...acc,
      {
        ...menu,
        children: menu.children ? transformProgramMenusFn(menu.children) : undefined,
      },
    ]
  }, [])
}
