import PropTypes from 'prop-types'

const StarIcon = props => {
  const { color, className, onClick, ...othersProps } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      className={className}
      onClick={onClick}
      {...othersProps}
    >
      <path
        fill={color || 'currentColor'}
        fillRule="evenodd"
        d="M8.032 3.756L6.448.548a.521.521 0 0 0-.9 0l-1.58 3.208-3.54.514a.5.5 0 0 0-.277.853l2.562 2.5-.606 3.526a.5.5 0 0 0 .726.527L6 10.008l3.167 1.665a.5.5 0 0 0 .726-.527L9.287 7.62l2.562-2.5a.5.5 0 0 0-.277-.853l-3.54-.511z"
      />
    </svg>
  )
}

StarIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default StarIcon
