import isFunction from 'lodash/isFunction'
import PropTypes from 'prop-types'
import { PureComponent } from 'react'

import Button from 'src/components/ui/atoms/Button'
import Modal from 'src/components/ui/atoms/Modal'

import * as styles from './styles.scss'

export default options => Component =>
  class extends PureComponent {
    static displayName = `withConfirmModal(${Component.displayName || Component.name})`

    static propTypes = {
      onClick: PropTypes.func,
      context: PropTypes.object,
    }

    constructor(props) {
      super(props)

      this.onElementClick = this.onElementClick.bind(this)
      this.onConfirmClick = this.onConfirmClick.bind(this)
      this.onCancelClick = this.onCancelClick.bind(this)
    }

    state = {
      open: false,
      clickArgs: [],
    }

    onElementClick(e) {
      e.stopPropagation()
      if (options.onModalOpen) {
        options.onModalOpen()
      }
      e.nativeEvent.stopImmediatePropagation()
      this.setState(() => ({ open: true, clickArgs: e }))
    }

    onConfirmClick(e) {
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()
      const { onClick } = this.props
      const { clickArgs } = this.state
      this.onCancelClick()
      onClick(clickArgs)
    }

    onCancelClick() {
      this.setState(() => ({ open: false }))
      if (options.onModalClose) {
        options.onModalClose()
      }
    }

    render() {
      const {
        title,
        message,
        cancelButton = true,
        confirmButton = true,
        cancelButtonText = 'Cancel',
        confirmButtonText = 'Confirm',
        modalDataTestid,
      } = options
      const { open } = this.state
      const { context, ...props } = this.props

      return (
        <>
          <Component {...props} onClick={this.onElementClick} />
          <Modal
            data-testid={modalDataTestid}
            open={open}
            onRequestClose={this.onCancelClick}
            className={styles.ConfirmModal}
          >
            {title && <Modal.Header>{isFunction(title) ? title(context) : title}</Modal.Header>}
            <Modal.Body className={styles.Body}>
              {isFunction(message) ? message(context) : message}
            </Modal.Body>
            <Modal.Footer>
              {cancelButton && (
                <Button buttonType="silence" className={styles.Button} onClick={this.onCancelClick}>
                  <div>{cancelButtonText}</div>
                </Button>
              )}
              {confirmButton && (
                <Button
                  buttonType="primary"
                  className={styles.Button}
                  onClick={this.onConfirmClick}
                >
                  <div>{confirmButtonText}</div>
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        </>
      )
    }
  }
