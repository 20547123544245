import cx from 'classnames'
import { HtmlHTMLAttributes, ReactNode } from 'react'

import * as styles from './Title.scss'

interface Props {
  children: ReactNode
}

const Title = ({ className, ...otherProps }: Props & HtmlHTMLAttributes<HTMLSpanElement>) => (
  <span className={cx(styles.Title, className)} {...otherProps} />
)

export default Title
