import cx from 'classnames'
import { ChangeEvent, ClipboardEvent, KeyboardEvent, useEffect, useState } from 'react'

import Input from 'src/components/ui/atoms/Input'
import { EMAIL } from 'src/utils/formValidation'

import * as styles from './EmailsList.scss'

const EmailsList = ({ input, meta: { error, touched }, className, ...others }: any) => {
  const [inputValue, setInputValue] = useState<string>('')
  const { value: emailsList, onChange: updateEmailsList, onBlur } = input
  let inputRef: any = null

  const handleClickOutside = (event: any) => {
    if (inputValue.length && inputRef && !inputRef.contains(event.target)) {
      updateEmailsList([...emailsList, inputValue.trim()])
      setInputValue('')
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)
  })

  const deleteEmail = (item: string) => {
    updateEmailsList(emailsList.filter((email: string) => email !== item))
  }

  const handleKeyDown = (event: any) => {
    const email = inputValue.trim()
    if (['Enter', 'Tab', ' ', ','].includes(event.key) && email.length) {
      event.preventDefault()
      inputRef.focus()
      updateEmailsList([...emailsList, email])
      setInputValue('')
    }

    if (['Backspace'].includes(event.key) && !inputValue.length && emailsList.length) {
      event.preventDefault()
      updateEmailsList((emailsList: ReadonlyArray<string>) =>
        emailsList.filter(
          (_el: string, index: number, array: ReadonlyArray<string>) => index !== array.length - 1,
        ),
      )
    }
  }

  const handlePaste = (event: any) => {
    event.preventDefault()
    const paste = event.clipboardData.getData('text')
    const emails = paste.match(/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/g)

    if (emails) {
      updateEmailsList([...emailsList, ...emails])
    }
  }

  return (
    <div className={styles.EmailsListWrapper}>
      <div
        className={cx({
          [styles.EmailsList]: true,
          [styles.EmailsListError]: touched && error,
        })}
      >
        {Array.isArray(emailsList) &&
          emailsList?.map((email: string, index: number) => (
            <span
              key={index}
              className={cx({
                [styles.EmailChip]: true,
                [styles.EmailChipError]: !EMAIL.test(email),
              })}
            >
              {email}
              <button
                className={cx({
                  [styles.CloseButton]: true,
                  [styles.CloseButtonError]: !EMAIL.test(email),
                })}
                type="button"
                onClick={() => deleteEmail(email)}
              >
                X
              </button>
            </span>
          ))}
        <Input
          ref={el => (inputRef = el)}
          {...input}
          value={inputValue}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => handleKeyDown(e)}
          onPaste={(e: ClipboardEvent<HTMLInputElement>) => handlePaste(e)}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value)}
          onBlur={() => onBlur(undefined)}
          classes={{ root: styles.InputWrapper, input: styles.Input }}
          {...others}
        />
      </div>
      {touched && error && <div className={cx(className, styles.Error)}>{error}</div>}
    </div>
  )
}

export default EmailsList
