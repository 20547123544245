import { ComponentCode } from 'src/utils/settings'
import { useComponentCodeStateSettings } from './useComponentCodeStateSettings'

const useFilterBarSettings = () => {
  const settings = useComponentCodeStateSettings(ComponentCode.FilterBar)

  return settings
}

export default useFilterBarSettings
