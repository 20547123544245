import { History } from 'history'
import { FC } from 'react'
import { BrowserRouter, Router as ReactRouter } from 'react-router-dom'

import { SENTRY_URL } from 'src/utils/config'

interface RouterInterface {
  history: History
  children: any
}

const Router: FC<RouterInterface> = ({ history, children }) => {
  if (SENTRY_URL) return <ReactRouter history={history}>{children}</ReactRouter>

  return <BrowserRouter>{children}</BrowserRouter>
}

export default Router
