import { ADOMIK_LOADING_MESSAGE_GENERATE, PACMAN_LOADER_ENABLE } from '../constants/constants'

/**
 * Generate new adomik loading message
 */
export const adomikLoadingMessageGenerateDispatcher = () => ({
  type: ADOMIK_LOADING_MESSAGE_GENERATE,
})

/**
 * Enable or disable pacman loader on page
 */
export const adomikPacmanLoaderDispatcher = (pacmanLoaderEnabled: boolean) => ({
  type: PACMAN_LOADER_ENABLE,
  payload: { pacmanLoaderEnabled },
})
