import cx from 'classnames'
import { PureComponent } from 'react'

import Image from 'src/assets/images/404.jpg'

import * as styles from './NotFound.scss'

class NotFound extends PureComponent {
  constructor(props) {
    super(props)

    this.onLoad = this.onLoad.bind(this)
  }

  state = {
    loaded: false,
  }

  onLoad() {
    this.setState(() => ({ loaded: true }))
  }

  render() {
    const { loaded } = this.state
    const classes = cx({
      [styles.Error]: true,
      [styles.ErrorLoaded]: loaded,
    })
    return (
      <div data-testid="not-found" className={classes}>
        <div className={styles.ErrorMessage}>
          <div className={styles.ErrorHeader}>
            <span className={styles.ErrorCode}>404</span>
            <span className={styles.ErrorTitle}>- Oooops, it seems like you got lost !</span>
          </div>
          <div className={styles.ErrorDetails}>
            <div>Let us take this opportunity to tell you that we are glad to work with you</div>
            <div>
              … and we recommend that you restart from the menu on your left … unless you want to
              join the party!
            </div>
          </div>
        </div>
        <img
          data-testid="not-found-image"
          className={styles.ErrorImage}
          src={Image}
          alt=""
          onLoad={this.onLoad}
        />
      </div>
    )
  }
}

export default NotFound
