import { FC } from 'react'

import useGetShareDashboardUsers from 'src/hooks/useGetShareDashboardUsers'
import Modal from '../../atoms/Modal'
import Typography from '../../atoms/Typography'
import ShareDashboardModalBody from './ShareDashboardModalBody'
import ShareDashboardProvider from './ShareDashboardProvider'

import * as styles from './ShareDashboard.scss'

export interface ShareDashboardModalProps {
  onClose: () => void
  isOpen: boolean
}

const ShareDashboardModal: FC<ShareDashboardModalProps> = ({ onClose, isOpen }) => {
  const { loading, data: users } = useGetShareDashboardUsers(isOpen)

  return (
    <ShareDashboardProvider users={users}>
      <Modal
        className={styles.ShareDashboardModal}
        isManuallyShaped
        data-testid="modal-share-data"
        open={isOpen}
        onRequestClose={onClose}
        clickOutsideToClose
      >
        <Modal.Header className={styles.ShareDashboardModalHeader} isColoredHeader>
          <Typography variant="modalTitle">Share</Typography>
        </Modal.Header>
        <Modal.Body className={styles.ShareDashboardModalBody}>
          <ShareDashboardModalBody loading={loading} />
        </Modal.Body>
        <Modal.Footer isColored>
          <div className={styles.ShareDashboardModalFooter} />
        </Modal.Footer>
      </Modal>
    </ShareDashboardProvider>
  )
}

export default ShareDashboardModal
