export enum Product {
  REPORT = 'report',
  BENCHMARK = 'benchmark',
  MARKET_INTEL = 'market_intel',
  TROUBLESHOOT = 'troubleshoot',
  PRICE = 'price',
  DEAL = 'deal',
  DEAL_V2 = 'new_deal',
  PbsYield = 'yield',
}

export enum CommonPage {
  SelfServiceMapping = 'self_service_mapping',
}

export enum SubProduct {
  Dashboard = 'dashboard',
  DataDownload = 'datadownload',
  Analytics = 'analytics',
  MarketAlerts = 'market_alert',
  MarketOverview = 'market_overview',
  BidAnalytics = 'analyzer',
  DealAlerts = 'deal_alert',
  DealCenter = 'deal_center',
  DealAlertsV2 = 'new_deal_alert',
  DealCenterV2 = 'new_deal_center',
  RevenueImpact = 'revenue_impact',
  AudienceAnalytics = 'audience_analytics',
  PrebidAnalytics = 'prebid_analytics',
  AnalyticsV2 = 'analytics_v2',
  MarketAlertsV2 = 'market_alert_v2',
}

export const PRODUCT_PATHS = {
  [SubProduct.Dashboard]: '/report/dashboard',
  [SubProduct.DataDownload]: '/report/datadownload',
  [SubProduct.Analytics]: '/benchmark/analytics',
  [SubProduct.MarketOverview]: '/benchmark/market_overview',
  [SubProduct.MarketAlerts]: '/benchmark/market_alert',
  [SubProduct.MarketAlertsV2]: `/${Product.MARKET_INTEL}/${SubProduct.MarketAlertsV2}`,
  [Product.MARKET_INTEL]: `/${Product.MARKET_INTEL}/:subProduct/:program?`,
  [SubProduct.DealAlertsV2]: `/${Product.DEAL_V2}/${SubProduct.DealAlertsV2}`,
  [SubProduct.DealCenterV2]: `/${Product.DEAL_V2}/${SubProduct.DealCenterV2}`,
  [SubProduct.BidAnalytics]: '/troubleshoot/analyzer',
  [SubProduct.DealAlerts]: '/deal/deal_alert',
  [SubProduct.DealCenter]: '/deal/deal_center',
  [SubProduct.RevenueImpact]: '/price/revenue_impact',
  [SubProduct.AudienceAnalytics]: `/${Product.PbsYield}/${SubProduct.AudienceAnalytics}`,
  [SubProduct.PrebidAnalytics]: `/${Product.PbsYield}/${SubProduct.PrebidAnalytics}`,
  [SubProduct.AnalyticsV2]: `/${Product.MARKET_INTEL}/${SubProduct.AnalyticsV2}`,
}

/** Here is sub-products without "target_period_informations" (TimePeriod in Calendar) */
export const subProductsWithoutTargetPeriodInformations = [
  SubProduct.DealAlerts,
  SubProduct.MarketAlerts,
]
/** Here is sub-products without comparison (TimePeriod in Calendar) */
export const subProductsWithoutComparison = [SubProduct.DataDownload]
/** Here is Benchmark sub-products */
export const benchmarkSubProducts = [
  SubProduct.Analytics,
  SubProduct.MarketAlerts,
  SubProduct.MarketOverview,
]

export const REPORT_SUB_PRODUCTS = [SubProduct.Dashboard, SubProduct.DataDownload]

export const DEAL_SUB_PRODUCTS = [SubProduct.DealAlerts, SubProduct.DealCenter]

/** Here are the products that support the "Share Links" button */
export const shareLinksProducts = [
  Product.REPORT,
  Product.BENCHMARK,
  Product.DEAL,
  Product.DEAL_V2,
  Product.MARKET_INTEL,
]
/** Here is sub-products without "ecosystems" */
export const subProductsWithoutEcosystems = [SubProduct.MarketAlerts, SubProduct.DataDownload]
/** Here is sub-products without "key metrics" */
export const subProductsWithoutMetrics = [
  SubProduct.DealAlerts,
  SubProduct.DealCenter,
  SubProduct.DealAlertsV2,
  SubProduct.DealCenterV2,
]
/** Here is sub-products with "checkboxes" */
export const subProductsCheckboxes = [SubProduct.DataDownload]

// page with indicators
export const PAGES_WITH_INDICATORS = [Product.REPORT]

export const PRODUCTS = {
  [Product.REPORT]: {
    label: 'Report',
    code: Product.REPORT,
  },
  [Product.BENCHMARK]: {
    label: 'Benchmark',
    code: Product.BENCHMARK,
  },
  [Product.TROUBLESHOOT]: {
    label: 'Troubleshoot',
    code: Product.TROUBLESHOOT,
  },
  [Product.PRICE]: {
    label: 'Price',
    code: Product.PRICE,
  },
  [Product.DEAL]: {
    label: 'Deal',
    code: Product.DEAL,
  },
}

export const SUB_PRODUCTS = {
  [SubProduct.Dashboard]: {
    label: 'Dashboard',
    code: SubProduct.Dashboard,
    parent: Product.REPORT,
  },
  [SubProduct.DataDownload]: {
    label: 'Data download',
    code: SubProduct.DataDownload,
    parent: Product.REPORT,
  },
  [SubProduct.Analytics]: {
    label: 'Analytics',
    code: SubProduct.Analytics,
    parent: Product.BENCHMARK,
  },
  [SubProduct.MarketAlerts]: {
    label: 'Market alerts',
    code: SubProduct.MarketAlerts,
    parent: Product.BENCHMARK,
  },
  [SubProduct.MarketOverview]: {
    label: 'Market overview',
    code: SubProduct.MarketOverview,
    parent: Product.BENCHMARK,
  },
  [SubProduct.BidAnalytics]: {
    label: 'Bid analytics',
    code: SubProduct.BidAnalytics,
    parent: Product.TROUBLESHOOT,
  },
  [SubProduct.DealAlerts]: {
    label: 'Deal alerts',
    code: SubProduct.DealAlerts,
    parent: Product.DEAL,
  },
  [SubProduct.DealCenter]: {
    label: 'Deal center',
    code: SubProduct.DealCenter,
    parent: Product.DEAL,
  },
  [SubProduct.RevenueImpact]: {
    label: 'Revenue impact',
    code: SubProduct.RevenueImpact,
    parent: Product.PRICE,
  },
  [SubProduct.AudienceAnalytics]: {
    label: 'Audience analytics',
    code: SubProduct.AudienceAnalytics,
    parent: Product.PbsYield,
  },
  [SubProduct.PrebidAnalytics]: {
    label: 'Demande analytics',
    code: SubProduct.PrebidAnalytics,
    parent: Product.PbsYield,
  },
}
