import CompareIcon from 'src/components/ui/atoms/Icons/Compare'
import PercentIcon from 'src/components/ui/atoms/Icons/Percent'
import StackIcon from 'src/components/ui/atoms/Icons/Stack'
import UnstackIcon from 'src/components/ui/atoms/Icons/Unstack'
import { Product, SubProduct } from './product'

export const TABS = {
  data_status: 'dataStatus',
  help: 'help',
  label: 'market',
  comparison: {
    disabled: 'comparison_disabled',
    label: 'Comparison disabled',
  },
  type: {
    revenue_growth: 'revenue_growth',
    gap: 'gap',
    metric: 'metric',
  },
  comparison_type: 'days',
}

export const DATE_MESSAGES = {
  warning: {
    days: {
      comparison_disabled: {
        msg: 'Lookback period is limited to 60 days. Comparison will not be available for the selected dates.',
        msgWithPeriod: days =>
          `Lookback period is limited to ${days} days. Comparison will not be available for the selected dates.`,
        msgWithMinDate: minDate =>
          `Lookback period is limited to ${minDate.format(
            'MMMM Do',
          )}. Comparison will not be available for the selected dates.`,
      },
      largeFirstDateSelection: {
        msg: 'Select up to 100 days before or after your current date selection',
        msgWithMaxSelection: maxSelection =>
          `Select up to ${maxSelection} days before or after your current date selection`,
        warning: true,
      },
      largeFirstOpenCalendar: {
        msg: 'NEW: daily data is now available from November 1st, 2023.',
        msgWithMinDate: minDate =>
          `NEW: daily data is now available from ${minDate.format('MMMM Do, YYYY')}.`,
        warning: true,
      },
      comparison_turned_off: {
        msg: 'Comparison will not be available for the selected dates.',
      },
      format: {
        msg: 'Invalid date. Please write date as: MM/DD/YYYY.',
      },
      wrong_date: {
        msg: 'Invalid date.',
      },
      sell_last_month_available: {
        msg: '"Comparison period" must be before the reference "time period".',
      },
      end_date_compare_before_startDate: {
        msg: '"Comparison period" must be before the reference "time period".',
      },
      end_date: {
        msg: 'Start date must be prior to end date.',
      },
      end_date_compare: {
        msg: '"Comparison period" must be before the reference "time period".',
      },
    },
    months: {
      comparison_month_disabled: {
        msg: 'Lookback period is limited to 24 months. Comparison will not be available for the selected dates.',
      },
      format: {
        msg: 'Invalid date. Please write date as: MM/YYYY.',
      },
      availability: {
        msg: 'Please select a month within the last 24 months.',
      },
      date_not_available_last_month: {
        msg: 'Start date must be prior to end date.',
      },
      end_date_compare: {
        msg: '"Comparison period" must be before the reference "time period". Start date must be prior to end date.',
      },
      end_date_compare_face_startDate: {
        msg: '"Comparison period" must be before the reference "time period".',
      },
      end_date_compare_before_time_period: {
        msg: 'In comparison period, start date must be prior to end date.',
      },
      end_date_compare_after_time_period: {
        msg: '“Comparison period” must be before the reference “time period”.',
      },
      current_month_not_same_duration: {
        msg: 'Selected time periods do not match (whole month vs. month-to-date)',
        warning: true,
      },
    },
    hours: {
      comparison_disabled: {
        msg: 'Lookback period is limited to 48 hours. Comparison will not be available for the selected dates.',
        msgWithPeriod: hour =>
          `Lookback period is limited to ${hour} hours. Comparison will not be available for the selected dates.`,
      },
      format: {
        msg: 'Invalid date. Please write date as: DD/MM/YYYY HH.',
      },
      wrong_date: {
        msg: 'Invalid date.',
      },
      availability: {
        msg: 'Please select a hour within the last 24 hours.',
      },
      end_date_compare: {
        msg: '"Comparison period" must be before the reference "time period". Start date must be prior to end date.',
      },
      end_date_compare_face_startDate: {
        msg: '"Comparison period" must be before the reference "time period".',
      },
      end_date_compare_before_time_period: {
        msg: 'In comparison period, start date must be prior to end date.',
      },
      end_date_compare_after_time_period: {
        msg: '“Comparison period” must be before the reference “time period”.',
      },
      current_month_not_same_duration: {
        msg: 'Selected time periods do not match (whole hour vs. hour-to-date)',
        warning: true,
      },
    },
  },
}

export const DATA_DOWNLOAD_MESSAGES = {
  warning: {
    title: 'Maximum reached',
    optional: 'Optional',
    message: 'Maximum number of dimensions reached. ',
    add_top_dimension:
      'You can add a fourth dimension to your file: Time breakdown, Partners, or Seats. ',
    add_dimension: 'You can add a fourth dimension to your file. ',
  },
  alert:
    'To add other dimensions, please reach out to your Customer Success Manager to activate Data Export. ',
  download_report: 'Download report in CSV',
  dimensions: 'dimensions',
  metrics: 'metrics',
  currencies_warning: 'Revenue data is delivered in the currency you selected in: ',
  link_to_settings: 'My settings',
  url_to_settings: '/account/platform',
  performanceMetrics: {
    title: 'Performance metrics',
    message: `You have selected a performance metric.<br /> The downloaded file will include a 'Coverage' column for each selected metric. The 'Coverage' is the % of impressions that are taken into account for calculating the metric.`,
  },
  adomikIds: {
    title: 'Adomik IDs',
    message: `You have opted to display Adomik IDs. <br /><ul><li>IDs for mapped items will start with 'adk'.</li><li>IDs for unmapped items will start with 'ndk'.</li>`,
  },
  dimensionLimitReached: {
    title: 'Dimensions limit reached',
    message:
      'The maximum number of selected dimensions has been reached. If you need to include additional dimensions, kindly get in touch with your CSM to enable Data Export.',
  },
  fileFormat: {
    title: 'File format',
    message:
      'You have selected the .xlsx format. <br /> This will increase the generation time compared to the .csv format.',
  },
}

export const DATA_DOWNLOAD_MODES = {
  idMode: {
    code: 'idMode',
    label: 'Display Adomik IDs',
    default: false,
  },
  fileExtension: {
    code: 'fileExtension',
    label: 'File extension',
    default: '.csv',
  },
}

export const MENU_THEMES = {
  dark: 'dark',
  light: 'light',
}

export const MENU = {
  footer: {
    account: 'account',
    administration: 'administration',
    self_mapping: 'self_service_mapping',
  },
}

export const COLORS = {
  success: '#63b97b',
  info: '#f1c40f',
  warning: '#edae0d',
  error: '#cb5040',
  in_maintenance: '#e3721f',
}

export const WIZARD = {
  status_link: 'Full data availability status',
  full_data_url: '/availability',
  title_availability: 'Data Availability',
  title_service: 'Service update',
}

export const WIZARD_STATUS = {
  success: 'ok',
  in_progress: 'being_processed',
  pending: 'coming_next',
  error: 'late',
  key_values_notification: 'kv_notification',
  in_maintenance: 'in_maintenance',
}

export const STATUS = {
  news: 'new',
  stop: 'stop',
  neutral: 'neutral',
  up: 'up',
  down: 'down',
  na: 'NA',
  off: 'off',
  newDeal: 'new_deal',
}

export const DATA_TABLE_MESSAGES = {
  no_data_from_filters_slicers: 'Click the Clear button to display the hidden data.',
  no_data_from_filters_ecosystems:
    'No results matching the active filters. Clear the active filters to show more data.',
  no_data_from_search: 'No matching results.',
  data_availability_message: 'Data is not available for the scope you have filtered on.',
  null_value: '--',
  data_availability_messages: {
    deal_alert: 'No alert',
    dashboard:
      'The scope you have filtered on is not compatible with the chosen dimension in this ecosystem.',
    analyzer:
      'The scope you have filtered on is not compatible with the chosen dimension in this ecosystem.',
  },
}

export const DISPLAY_MODE = {
  comparison: 'comparison',
  variation: 'variation',
  raw: 'raw',
}

export const PRODUCT = {
  page: {
    analytics: 'analytics',
    dashboard: 'dashboard',
    bidAnalytics: 'analyzer',
    dataDownload: 'datadownload',
    marketAlert: 'market_alert',
    marketOverview: 'market_overview',
    dealAlert: 'deal_alert',
    dealCenter: 'deal_center',
    prebidAudienceAnalytics: SubProduct.AudienceAnalytics,
    prebidAnalytics: SubProduct.PrebidAnalytics,
  },
  report: 'report',
  benchmark: 'benchmark',
  market: 'market',
  troubleshoot: 'troubleshoot',
  price: 'price',
  deal: 'deal',
  dealV2: Product.DEAL_V2,
  pbsYield: Product.PbsYield,
  market_intel: 'market_intel',
}

export const INSIGHT_FINDER = {
  header: 'Insight Finder',
  title: 'Revenue gap analysis',
}

export const OPPOTURNITY = {
  rev_gap: 'REVENUE GAP: ',
  ahead: 'ahead of the market',
  behind: 'behind the market',
}

export const REPORT = {
  modes: {
    revenue_mode: 'revenue_mode',
    ctr_viewability_mode: 'ctr_viewability_mode',
  },
}

export const BENCHMARK = {
  gap: 'Gap',
  revenue_currency_gap: 'revenue_currency_gap',
  type: {
    label: 'label',
    popup: 'popup',
  },
}

export const MARKET_ALERT = {
  empty: "You don't have any recent market alerts. Come back again soon to see the latest updates.",
  empty_day: 'no alerts',
  heading: {
    title: 'Market Alerts',
    description: 'An alert is generated when a new campaign is launched in the market',
  },
  keys: {
    predicted_spend: 'predicted_spend',
    seller_spend: 'seller_spend',
    gap: 'spend_gap',
    insight_finder: 'insight_finder',
    chart: 'chart',
  },
  insight_finder: 'Insight Finder',
  status: {
    behind: 'behind',
    on_par: 'on_par',
    ahead: 'ahead',
  },
  inventory: 'Targeted Inventory',
  color: {
    me: '#ffd54f',
    market: '#28aaea',
  },
  variation: 'Market Spend Variation',
}

export const TOP_MESSAGES = {
  benchmark: {
    analytics: {
      info: 'Top ranking based on my revenue 2 days ago for the selected market',
    },
    market_overview: {
      info: 'Top ranking based on market revenue 2 days ago for the selected market',
    },
  },
  market_intel: {
    analytics_v2: {
      info: 'Top ranking based on revenue for yesterday',
      appInfo: 'Ranking based on revenue for the last 7 days',
    },
  },
  report: {
    datadownload: {
      info: 'Top ranking based on revenue for yesterday',
      appInfo: 'Ranking based on revenue for the last 7 days',
    },
    dashboard: {
      info: 'Top ranking based on revenue for yesterday',
      appInfo: 'Ranking based on revenue for the last 7 days',
    },
  },
  price: {
    revenue_impact: {
      info: 'Top ranking based on the most recent revenue impact data',
    },
  },
  deal: {
    deal_center: {
      info: 'Top ranking based on revenue for yesterday',
    },
    deal_alert: {
      info: 'Top ranking based on revenue for yesterday',
    },
  },
  [Product.DEAL_V2]: {
    [SubProduct.DealCenterV2]: {
      info: 'Ranking based on revenue for the last 7 days',
    },
    [SubProduct.DealAlertsV2]: {
      info: 'Ranking based on revenue for the last 7 days',
    },
  },
  defaultMessage: { info: 'Top ranking based on revenue for yesterday' },
  query_messages:
    'Click on the "Save query" button and save your recurring custom queries to check them regularly.',
}

export const CHART = {
  tooltip: {
    gap: 'Gap',
    total: 'Total',
  },
  gap: {
    revenue: 'REVENUE GAP:',
    ahead: 'ahead of the market',
    behind: 'behind the market',
  },
}

export const GOTO = {
  benchmark: 'Benchmark',
  report: 'Report Dashboard',
  market_overview: 'Market Overview',
  troubleshoot: 'Troubleshoot',
  deal: 'Deal',
  deal_center: 'Deal Center',
  [SubProduct.AudienceAnalytics]: 'Audience analytics',
  [SubProduct.PrebidAnalytics]: 'Prebid analytics',
}

export const BOX = {
  medium: 'medium',
  large: 'large',
  small: 'small',
  label: {
    hide: 'hide details',
    show: 'show details',
  },
}

export const CHART_BAR_LABEL = {
  me: {
    revenue_resold: 'My Revenue SoV',
    impressions_resold: 'My Impressions SoV',
    exchange_cpm: 'My CPM',
    revenue_growth: 'My Revenue Growth',
  },
  market_intel: {
    revenue_resold: 'Market Revenue SoV',
    impressions_resold: 'Market Impressions SoV',
    exchange_cpm: 'Market CPM',
    revenue_growth: 'Market Revenue Growth',
  },
}

export const DECIMALS_CORRESPONDANCE = {
  default: 0,
  exchange_cpm: 2,
  exchange_rpm: 2,
}

export const ZERO_FORMAT_METRICS_WHITELIST = [
  'market_cpm',
  'seller_cpm',
  'revenue_gap',
  'market_revenue_resold',
  'seller_revenue_resold',
  'market_breakdown_imp',
  'market_breakdown',
  'market_cpm',
  'market_impressions_resold',
  'seller_impressions_resold',
]

export const VARIATION_METRICS = ['market_growth', 'seller_growth']

export const SELECT_CATEGORIES = {
  rank: {
    top: 'top',
    best: 'best',
    worst: 'worst',
  },
  rank_label: {
    top: 'Top revenue',
    best: 'Highest growth',
    worst: 'Greatest loss',
  },
  rank_benchmark: {
    top: 'Market Top',
    best: 'Highest',
    worst: 'Greatest',
  },
  metrics: 'metrics',
  benchmark: 'benchmark',
  market_overview: 'market_overview',
  dimensions: 'dimensions',
}

export const GOTO_URL = {
  benchmark: `/${PRODUCT.benchmark}/${PRODUCT.page.analytics}/`,
  marketIntel: `/${PRODUCT.market_intel}/${SubProduct.AnalyticsV2}/`,
  report: `/${PRODUCT.report}/${PRODUCT.page.dashboard}/`,
  deal_center: `/${PRODUCT.deal}/${PRODUCT.page.dealCenter}/`,
  prebidAudienceAnalytics: `/${PRODUCT.pbsYield}/${PRODUCT.page.prebidAudienceAnalytics}/`,
  prebidDemandAnalytics: `/${PRODUCT.pbsYield}/${PRODUCT.page.prebidAnalytics}/`,
}

export const CALENDAR = {
  labels: {
    success: 'success',
    disable: 'disable',
    period: 'Period',
  },
  days: {
    number: 61,
    label: 'days',
  },
  months: {
    number: 25,
    max: 25,
    label: 'months',
  },
  hours: {
    number: 2, // 2 days
    label: 'hours',
  },
  comparison: {
    number: {
      monthly: 25,
      daily: 61,
      hourly: 2,
    },
    disabled: {
      daily: 'comparison_disabled',
      hourly: 'comparison_disabled',
      monthly: 'comparison_month_disabled',
    },
  },
  escape: 27,
  custom: 'Custom',
}

export const ADOMIK_ID = 'adk'

export const FILTER_KEYS = {
  country: 'geo_country',
}

export const RESET_PASSWORD = {
  link_to_login: 'Go back to login',
  reset_button: 'Confirm password',
}

export const NUMBER_TYPES = {
  currency: 'currency',
  percentage: 'percentage',
  raw_percentage: 'rawPercentage',
  number: 'number',
}

export const DIMENSION_TYPES = {
  custom: 'Custom',
  tags: 'Tags',
  mostUsed: 'Most used',
  none: 'None',
}

export const SEARCH = {
  place_holder:
    'Add a filter to your scope on any supply and/or demand dimension to narrow your analysis',
  items: 'items',
  item: 'item',
  box_title: 'in your query',
  queried: 'queried',
  top: 'top',
  color: {
    yellow: '#FEBF4C',
    grey: '#C6CDD0',
  },
  saved_query: {
    header: 'Name your query',
  },
  tooltip: {
    message: 'For further information, please go to my account page',
    position: {
      top: 'top',
      bottom: 'bottom',
    },
  },
  no_result_text: 'No matching results',
}

export const COVERAGE_TYPES = {
  success: 'success',
  danger: 'danger',
  neutral: 'neutral',
  default: 'default',
}

export const COVERAGE_THICKNESSES = {
  thin: 'thin',
}

export const COVERAGE_CELLS = [
  'click_coverage',
  'viewability_coverage',
  'vtr_coverage',
  'market_estimated_viewability_coverage',
  'market_estimated_click_coverage',
  'market_estimated_vtr_coverage',
]

export const PATHNAMES = [
  'market_overview',
  'report',
  'benchmark',
  'troubleshoot',
  'deal',
  SubProduct.PrebidAnalytics,
  SubProduct.AudienceAnalytics,
]

export const VAR_CELL = ['rev_var', 'rev_var_percent']

export const IconsOptions = {
  stack: StackIcon,
  unstack: UnstackIcon,
  percent: PercentIcon,
  compare: CompareIcon,
}

// Ecosystem
export const ECOSYSTEM_CHART_MODE_KEY = 'chartmode'
export const ECOSYSTEM_BIG_WIDTH_TRENDS_ITEMS_KEY = 'big_sparkline_trends_items_ids'
export const ECOSYSTEM_CHART_SELECTED_ITEMS_IDS_KEY = 'chartmode_selected_items'

// Shortcut Options keys
export const SHORTCUT_OPTION_REVENUE_DETAIL_KEY = 'revenue_detail'
export const SHORTCUT_OPTION_COMPARISONS_KEY = 'comparisons'
// Exclude from shortcut options in chart mode
export const SHORTCUT_OPTIONS_KEYS_EXCLUDE_FROM_CHART_MODE = [
  SHORTCUT_OPTION_REVENUE_DETAIL_KEY,
  SHORTCUT_OPTION_COMPARISONS_KEY,
]

// Calendar
export const HOURLY_CALENDAR_MARGIN_END_DAY = 2
