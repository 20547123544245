import { MdClose } from 'react-icons/md'
import { animated, Transition } from 'react-spring'

import Alert from '../Alert'

import * as styles from './AlertsList.scss'

const AlertsList = ({
  alerts,
  onClose,
}: {
  alerts: ReadonlyArray<string>
  onClose: (alert: string) => void
}) => (
  <Transition
    items={[...alerts]}
    keys={(item: string) => item}
    from={{ opacity: 0, transform: 'translateY(5rem)' }}
    enter={{ opacity: 1, transform: 'translateY(0)' }}
    leave={{ opacity: 0, transform: 'translateY(5rem)' }}
    trail={70}
    config={{ duration: 300 }}
  >
    {({ opacity }, item) => (
      <animated.div
        className={styles.Alert}
        style={{
          opacity: opacity.to({ output: [0, 1], range: [0, 1] }),
          transform: opacity
            .to({ output: [100, 0], range: [0, 1] })
            .to((y: any) => `translate3d(0,${y}px,0)`),
        }}
      >
        <Alert message={{ msg: item, warning: true }} />
        <span className={styles.Button}>
          <MdClose className={styles.ButtonIcon} onClick={() => onClose(item)} />
        </span>
      </animated.div>
    )}
  </Transition>
)

export default AlertsList
