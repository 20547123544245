import cx from 'classnames'
import { FC, SVGProps } from 'react'

import * as styles from './Icons.scss'

interface DropdownArrowIconProps extends SVGProps<SVGSVGElement> {
  direction: 'up' | 'down'
}

const DropdownArrowIcon: FC<DropdownArrowIconProps> = ({ direction, className, ...svgProps }) => {
  const classes = cx(
    {
      [styles.DropdownArrowIcon]: true,
      [styles.DropdownArrowIconUp]: direction === 'up',
    },
    className,
  )

  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
      {...svgProps}
    >
      <path
        d="m.979.99 3.667 3.667a.5.5 0 0 0 .707 0L9.021.989"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default DropdownArrowIcon
