import { Icon } from './Icons.scss'

const UnstackIcon = props => (
  <svg className={Icon} height="2em" width="2em" viewBox="-7 -7 50 50" {...props}>
    <g data-name="Layer 2">
      <g data-name="Layer 4">
        <path d="M10.84 26.75a1 1 0 0 1-.71-.29 1 1 0 0 1 0-1.41l9.4-9.4a1 1 0 0 1 1.41 0l5.54 5.53 8.85-8.86a1 1 0 0 1 1.41 0 1 1 0 0 1 0 1.42l-9.56 9.56a1 1 0 0 1-1.41 0l-5.53-5.53-8.7 8.69a1 1 0 0 1-.7.29zM34.77 22a1 1 0 0 1-.71-.3l-1.59-1.59a1 1 0 1 1 1.41-1.41l.89.88.75-.75a1 1 0 0 1 1.41 0 1 1 0 0 1 0 1.42l-1.45 1.45a1 1 0 0 1-.71.3z" />
        <path d="M10.84 18.42a1 1 0 0 1-.71-.29 1 1 0 0 1 0-1.41L20.29 6.56a1 1 0 0 1 1.41 0l8.75 8.74A1 1 0 0 1 29 16.72l-8-8-9.45 9.45a1 1 0 0 1-.71.25z" />
        <path d="M40.93 33H6.13a1 1 0 0 1-1-1V1a1 1 0 0 1 2 0v30h33.8a1 1 0 0 1 0 2z" />
        <path d="M6.13 33H1a1 1 0 0 1 0-2h5.13a1 1 0 0 1 0 2z" />
        <path d="M6.13 37.6a1 1 0 0 1-1-1V32a1 1 0 1 1 2 0v4.6a1 1 0 0 1-1 1z" />
      </g>
    </g>
  </svg>
)

export default UnstackIcon
