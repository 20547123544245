import { AxiosPromise, AxiosRequestConfig } from 'axios'
import useAxios, { Options, RefetchOptions, ResponseValues } from 'axios-hooks'
import { useCallback, useMemo, useRef } from 'react'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router-dom'

import useIsSnowflakeProduct from 'src/hooks/useIsSnowflakeProduct'
import { AdomikOrigin } from 'src/types/AdomikOrigin'
import { LOGOUT_STATUS_ERRORS } from 'src/utils/constants/LogoutStatusErrors'
import storageUtils from 'src/utils/storageUtils'
import { LOGIN_ROOT, parse } from 'src/utils/urls'
import { waitForDelayFn } from 'src/utils/waitForDelayUtils'
import useAccount from '../useAccount'
import useDeepCompareMemo from '../useDeepCompareMemo'
import usePageLoading from '../usePageLoading'
import { getConfigWithPageHeaderIds, isErrorNeedRetryFn } from './useFetchLogic'

const DEFAULT_CONFIG = {
  dataType: 'json',
  withCredentials: true,
  timeout: 120000,
  validateStatus: (status: number) => status >= 200 && status < 300,
  headers: {
    ['Content-Type']: 'application/json; charset=utf-8',
  },
}

const MAX_RETRY = 3
const RETRY_DELAYS = [1000, 2000, 4000]

// This hook allow overriding useAxios with default settings and handling logout when necessary

const useFetch = <T,>(
  config: AxiosRequestConfig | string,
  options?: Options,
  needAuth = true,
): [
  ResponseValues<T, any, any>,
  (
    config?: AxiosRequestConfig,
    options?: RefetchOptions,
    adomikOrigin?: AdomikOrigin,
    retryTime?: number,
  ) => AxiosPromise<T>,
  () => void, // Cancel request
] => {
  const { logout } = useAccount()
  const history = useHistory()
  const { updatePageLoadingAdomikNavigationId } = usePageLoading()
  const globalRequestIdRef = useRef<string | null>(null)

  const isSnowflakeProduct = useIsSnowflakeProduct()

  const currentRequestUrl = typeof config === 'string' ? config : config.url

  // Add special header in export mode
  const exportId = parse(window.location.search?.replace('?', ''))?.export_id

  const defaultConfig = useMemo(
    () => ({
      ...DEFAULT_CONFIG,
      headers: {
        ...DEFAULT_CONFIG.headers,
        ...(exportId ? { ['Adomik-Export-Id']: exportId } : {}),
      },
    }),
    [exportId],
  )

  const axiosDefaultConfigs = useDeepCompareMemo(
    () =>
      typeof config === 'string'
        ? {
            ...defaultConfig,
            url: config,
          }
        : {
            ...defaultConfig,
            ...config,
            ['headers']: { ...defaultConfig.headers, ...config.headers },
          },
    [config, defaultConfig],
  )

  // Original query
  const [responseValues, execute, cancelRequest] = useAxios(axiosDefaultConfigs, {
    ...(options ?? {}),
    autoCancel: false,
  })

  const pathname = history?.location?.pathname

  // react router context allow us to redirect the user to the login page
  const executeQuery = useCallback(
    async (
      config?: AxiosRequestConfig,
      options?: RefetchOptions,
      adomikOrigin?: AdomikOrigin,
      retryTime = 0,
    ) => {
      if (needAuth && !storageUtils.getAuthToken()) {
        logout && logout(false)
        return Promise.resolve({
          data: null,
          status: 401,
        }) as AxiosPromise<any>
      }

      const isSnowProduct = adomikOrigin ? adomikOrigin === 'snowflake' : isSnowflakeProduct

      const axiosConfig = getConfigWithPageHeaderIds({
        currentConfig: config,
        requestUrl: currentRequestUrl,
        generateNewId: updatePageLoadingAdomikNavigationId,
        globalRequestIdRef,
        axiosDefaultConfigs,
        isSnowProduct,
      })

      try {
        return await execute(axiosConfig, options || {})
      } catch (error) {
        const err = error as any

        const isLogout =
          err.response &&
          LOGOUT_STATUS_ERRORS.includes(err.response.status) &&
          !pathname?.startsWith(LOGIN_ROOT)

        // if the request response includes LOGOUT_ERRORS status, logout user and redirect him to login page
        if (isLogout) {
          logout?.(false)
        }

        if (!isLogout && isErrorNeedRetryFn(err) && retryTime < MAX_RETRY) {
          await waitForDelayFn(RETRY_DELAYS[retryTime] || RETRY_DELAYS[0])
          return executeQuery(config, options, adomikOrigin, retryTime + 1)
        }

        if (err.message?.includes('timeout')) {
          toast.error('Request timed out')
          const url = config?.url || currentRequestUrl
          err.message = `${err.message} for ${url}`
        }

        throw error
      }
    },
    [
      axiosDefaultConfigs,
      currentRequestUrl,
      execute,
      pathname,
      logout,
      needAuth,
      updatePageLoadingAdomikNavigationId,
      isSnowflakeProduct,
    ],
  )

  const memoizedResponse = useMemo(
    () => [{ ...responseValues, data: responseValues.data }, executeQuery, cancelRequest],
    [cancelRequest, executeQuery, responseValues],
  )

  return memoizedResponse as [
    ResponseValues<T, any, any>,
    (
      config?: AxiosRequestConfig,
      options?: RefetchOptions,
      adomikOrigin?: AdomikOrigin,
      retryTime?: number,
    ) => AxiosPromise<T>,
    () => void,
  ]
}

export default useFetch
