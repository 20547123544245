import { AnyAction } from 'redux'

import {
  ECOSYSTEM_REMOVE_LAUNCHING_SELECTED_ITEMS,
  ECOSYSTEM_SET_LAUNCHING_SELECTED_CLEAR,
  ECOSYSTEM_SET_LAUNCHING_SELECTED_ITEMS,
  ECOSYSTEM_SET_LAUNCHING_SELECTED_MULTIPLE_ITEMS,
  ECOSYSTEM_SET_LAUNCHING_SELECTED_ONE_ITEM,
  ECOSYSTEM_SYNCHRONISE_LAUNCHING_SELECTED_ITEMS,
} from '../constants/constants'

interface EcosystemReducerProps {
  selectedLaunchingItems: string[]
}

const initState: EcosystemReducerProps = {
  selectedLaunchingItems: [],
}

export default function ecosystemReducer(state = initState, action: AnyAction) {
  switch (action.type) {
    case ECOSYSTEM_SET_LAUNCHING_SELECTED_ITEMS: {
      const { selectedLaunchingItems } = action.payload as { selectedLaunchingItems: string[] }
      return { ...state, selectedLaunchingItems }
    }
    case ECOSYSTEM_SET_LAUNCHING_SELECTED_ONE_ITEM: {
      const { selectedLaunchingItem, isSelected } = action.payload as {
        selectedLaunchingItem: string
        isSelected: boolean
      }
      return {
        ...state,
        selectedLaunchingItems: isSelected
          ? [...state.selectedLaunchingItems, selectedLaunchingItem]
          : state.selectedLaunchingItems.filter(item => item !== selectedLaunchingItem),
      }
    }
    case ECOSYSTEM_SET_LAUNCHING_SELECTED_MULTIPLE_ITEMS: {
      const { selectedLaunchingItems, isSelected } = action.payload as {
        selectedLaunchingItems: string
        isSelected: boolean
      }
      return {
        ...state,
        selectedLaunchingItems: isSelected
          ? [...state.selectedLaunchingItems, ...selectedLaunchingItems]
          : state.selectedLaunchingItems.filter(item => !selectedLaunchingItems.includes(item)),
      }
    }
    case ECOSYSTEM_SYNCHRONISE_LAUNCHING_SELECTED_ITEMS: {
      const { newSelectedLaunchingItems } = action.payload as {
        newSelectedLaunchingItems: string[]
      }
      return {
        ...state,
        selectedLaunchingItems: state.selectedLaunchingItems.filter(item =>
          newSelectedLaunchingItems.includes(item),
        ),
      }
    }
    case ECOSYSTEM_REMOVE_LAUNCHING_SELECTED_ITEMS: {
      const { removedItemsIds } = action.payload as { removedItemsIds: string[] }
      return {
        ...state,
        selectedLaunchingItems: state.selectedLaunchingItems.filter(
          item => !removedItemsIds.includes(item),
        ),
      }
    }
    case ECOSYSTEM_SET_LAUNCHING_SELECTED_CLEAR: {
      return {
        ...state,
        selectedLaunchingItems: [],
      }
    }
  }
  return state
}
