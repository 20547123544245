import cx from 'classnames'
import { CSSProperties, FC, HTMLProps } from 'react'

import * as styles from './Separator.scss'

interface SeparatorProps extends HTMLProps<HTMLDivElement> {
  withoutMargin?: boolean
  offsetHorizontal?: number
  marginTop?: number
  marginBottom?: number
}

const Separator: FC<SeparatorProps> = ({
  className,
  withoutMargin,
  style,
  offsetHorizontal,
  marginBottom,
  marginTop,
  ...divProps
}) => {
  const offset: CSSProperties = offsetHorizontal
    ? {
        marginLeft: offsetHorizontal,
        marginRight: offsetHorizontal,
        width: `calc(100% - ${offsetHorizontal * 2}px)`,
        marginTop,
        marginBottom,
      }
    : {}

  return (
    <div
      className={cx(
        styles.Separator,
        { [styles.SeparatorWithoutMargin]: withoutMargin },
        className,
      )}
      data-testid="separator"
      style={{
        ...style,
        ...offset,
      }}
      {...divProps}
    />
  )
}

Separator.defaultProps = {
  withoutMargin: false,
}

export default Separator
