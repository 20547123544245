import { useCallback } from 'react'
import { useDispatch as useAppDispatch } from 'react-redux'

const useDispatcher = <T extends any[]>(dispatchFunction: (...args: T) => void) => {
  const dispatch = useAppDispatch()

  const handleDispatch = useCallback((...args: T) => {
    dispatch(dispatchFunction(...args))
  }, [])

  return handleDispatch
}

export default useDispatcher
