import cx from 'classnames'
import { FC, ReactNode } from 'react'

import * as styles from './Modal.scss'

interface ModalBodyProps {
  children: ReactNode
  className?: string
  noPadding?: boolean
}

const Body: FC<ModalBodyProps> = props => {
  const { children, className, noPadding } = props
  const bodyClasses = cx(
    {
      [styles.Body]: true,
      [styles.BodyNoPadding]: noPadding,
    },
    className,
  )
  return <div className={bodyClasses}>{children}</div>
}

Body.defaultProps = {
  noPadding: false,
}

export default Body
