import { useCallback } from 'react'

import PageLoading from 'src/hooks/usePageLoading/PageLoading'
import { PageLoadingInterface } from 'src/types/PageLoadingInterface'

const usePageLoading = () => {
  const setPageLoadingAdomikPlatformContext = useCallback(
    (adomikPlatformContextArg: PageLoadingInterface['adomikPlatformContext']) => {
      PageLoading.getInstance().setAdomikPlatformContext(adomikPlatformContextArg)
    },
    [],
  )

  const setPageLoadingAdomikPlatformContextId = useCallback(
    (adomikPlatformContextIdArg: PageLoadingInterface['adomikPlatformContextId']) => {
      PageLoading.getInstance().setAdomikPlatformContextId(adomikPlatformContextIdArg)
    },
    [],
  )

  const updatePageLoadingAdomikNavigationId = useCallback((isGlobal = true) => {
    const newPageId = PageLoading.getInstance().setAdomikNavigationId(isGlobal)
    return newPageId
  }, [])

  return {
    updatePageLoadingAdomikNavigationId,
    setPageLoadingAdomikPlatformContext,
    setPageLoadingAdomikPlatformContextId,
  }
}

export default usePageLoading
