import find from 'lodash/find'
import merge from 'lodash/merge'
import union from 'lodash/union'
import qs from 'qs'

let history = null

const DEFAULT_SCOPE = {
  filters: {},
  exclude: {},
}

export const getSearch = () => {
  const location = history?.location
  if (!location) {
    return ''
  }
  const { search, pathname } = location
  if (search) {
    return search.substr(1)
  }
  if (pathname && pathname.includes('?')) {
    return pathname.substr(pathname.indexOf('?') + 1)
  }
  return ''
}

export const LOGIN_ROOT = '/login'

export const config = _history => {
  history = _history
}

export const generate = (params, baseUrl) => {
  const urlParams = qs.stringify(params)
  return `${baseUrl}${baseUrl.endsWith('/') ? '' : '/'}?${urlParams}`
}

export const parse = search => {
  const query = search || getSearch()
  if (!query) {
    return {}
  }
  return qs.parse(query)
}

export const getKey = key => parse()[key]

export const addTagFromObject = (scope, entities) => {
  const { filters, exclude } = merge(scope, DEFAULT_SCOPE)

  // Remove entities already in exclude
  const entitiesFilters = entities.filter(
    ({ id, dimension }) =>
      !(Object.keys(exclude).includes(dimension) && exclude[dimension].includes(id)),
  )

  return {
    filters: entitiesFilters.reduce(
      (accumulator, { dimension, id }) => ({
        ...accumulator,
        [dimension]: union(accumulator[dimension], [id]),
      }),
      filters,
    ),
    exclude,
  }
}

export const addTag = (search, entities, entitiesOnly = false) => {
  const { scopes, ...params } = parse(search.substr(1))
  const newScopes = entitiesOnly ? DEFAULT_SCOPE : scopes

  return { ...params, scopes: addTagFromObject(newScopes, entities) }
}

export const generateLoginURL = location => {
  const { pathname, search } = location

  if (pathname) {
    const param = pathname + search
    return `${LOGIN_ROOT}/?redirect=${encodeURIComponent(param)}`
  }
  return LOGIN_ROOT
}

export const isOnLogin = () => history.location.pathname.startsWith(LOGIN_ROOT)

export const getPostLoginURL = params => {
  if (params.redirect) {
    return params.redirect
  }
  return '/'
}

export const parseURL = (url, components = {}) => {
  const [product, page, program] = url.slice(1).split('/')
  const productElement = components[product]
  const pageElement = find(productElement?.children || [], { code: page })
  return {
    product,
    page,
    productElement,
    pageElement,
    program,
  }
}

const partnerTranslateToReport = filters => {
  if (typeof filters.partner === 'undefined') {
    return filters
  } else {
    return { ...filters, partner: filters.partner.map(id => `${id}-${id}`) }
  }
}

const partnerTranslateToBenchmark = filters => {
  if (typeof filters.partner === 'undefined') {
    return filters
  } else {
    return { ...filters, partner: filters.partner.map(id => id.substr(0, id.lastIndexOf('-'))) }
  }
}

export const translateBenchmarkToReportParams = params => {
  return {
    ...params,
    scopes: {
      ...params.scopes,
      filters: partnerTranslateToReport(params.scopes.filters),
      exclude: partnerTranslateToReport(params.scopes.exclude),
    },
  }
}

export const translateReportToBenchmarkParams = params => {
  return {
    ...params,
    scopes: {
      ...params.scopes,
      filters: partnerTranslateToBenchmark(params.scopes.filters),
      exclude: partnerTranslateToBenchmark(params.scopes.exclude),
    },
  }
}
