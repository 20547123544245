import { FC, memo } from 'react'
import { MdDelete } from 'react-icons/md'

import withConfirmModal from 'src/utils/hocs/withConfirmModal'

type DashboardDeleteIconProps = React.HTMLAttributes<HTMLDivElement>

const DashboardDeleteIcon: FC<DashboardDeleteIconProps> = props => {
  const DeleteIcon = withConfirmModal({
    title: 'Confirm',
    message: `Are you sure you want to delete this dashboard ?`,
    modalDataTestid: 'delete-dashboard-modal',
  })(MdDelete)

  return <DeleteIcon {...props} />
}

export default memo(DashboardDeleteIcon, () => true)
