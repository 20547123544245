import cx from 'classnames'
import computedStyleToInlineStyle from 'computed-style-to-inline-style'
import moment from 'moment'
import React, { ReactNode, useEffect, useState } from 'react'

import PDFHeader from 'src/assets/images/pdf_export_header.png'
import Button from 'src/components/ui/atoms/Forms/Button'
import ArrowLitleLeftIcon from 'src/components/ui/atoms/Icons/ArrowLitleLeftIcon'
import ArrowLittleRightIcon from 'src/components/ui/atoms/Icons/ArrowLittleRightIcon'
import PacManLoader from 'src/components/ui/atoms/PlaceHolder/PacManLoader'
import Title from 'src/components/ui/atoms/Title'
import Tooltip from 'src/components/ui/atoms/Tooltip'
import useQueryParams from 'src/hooks/useQueryParams'
import useSettings from 'src/hooks/useSettings'
import useTracking from 'src/hooks/useTracking'
import { useAppSelector } from 'src/redux/hooks'

import * as styles from './PrinterProvider.scss'

const CANVAS_SCALE = 1.2
const DOM_ID_TO_PRINT = 'printWrapper'

export interface PrinterCtx {
  printMode: boolean
  print: (pdfTitle: string) => void
  setPrintMode: (active: boolean) => void
}

export const PrinterContext = React.createContext<PrinterCtx>({} as PrinterCtx)

export interface Props {
  children: ReactNode
}

const print = async (pdfTitle: string) => {
  const element = document.getElementById(DOM_ID_TO_PRINT)
  if (!element) {
    return
  }

  // Chargement paresseux des librairies
  const [html2canvas, jsPDF] = await Promise.all([
    import('html2canvas').then(module => module.default),
    import('jspdf').then(module => module.default),
  ])

  const canvas = await html2canvas(element, {
    scale: CANVAS_SCALE,
    onclone: (clonedDoc: any) => {
      const container = clonedDoc.getElementById(DOM_ID_TO_PRINT)
      computedStyleToInlineStyle(container, {
        recursive: true,
        properties: ['fill', 'font-family', 'height', 'width'],
      })
    },
  })
  const doc = new jsPDF('p', 'px', [canvas.width, canvas.height], true)

  doc.addImage(
    canvas.toDataURL('image/jpeg', 0.75),
    'JPEG',
    0,
    0,
    doc.internal.pageSize.getWidth(),
    doc.internal.pageSize.getHeight(),
    'SLOW',
  )

  doc.setTextColor(25, 169, 237)
  doc.setFontSize(40)
  doc.setDrawColor(25, 169, 237)
  doc.setLineWidth(2)
  doc.save(pdfTitle)
}

const PrinterProvider = ({ children }: Props) => {
  const { trackEvent } = useTracking()
  const { exportConfig } = useSettings()
  const loadingComponents = useAppSelector(state => state.root.loadingComponents)
  const {
    setQueryParams,
    queryParams: { export_id: exportId },
  } = useQueryParams()
  const [printMode, setPrintMode] = useState<boolean>(exportId !== undefined)
  const [pdfTitle, setPdfTitle] = useState<string>(
    `Adomik_Dashboard_${moment().format('MM_DD_YYYY')}.pdf`,
  )
  const [downloadingPDF, setDownloadingPDF] = useState(false)

  useEffect(() => {
    if (exportId && Object.keys(exportConfig).length) {
      const title = exportConfig['title']
      const fileName = `${title.replace(/\s/g, '_')}.pdf`
      setPdfTitle(fileName)
    }
  }, [exportConfig])

  useEffect(() => {
    if (printMode) {
      import('jspdf').then(() => {
        import('html2canvas')
      })
    }
  }, [printMode])

  const contextValue = { printMode, print, setPrintMode }
  const isLoadingDone = loadingComponents.every(component => !component.loading)
  const hasErrorOnFinish =
    isLoadingDone && loadingComponents.some(component => Boolean(component.error))

  const handleDownloadPDF = async () => {
    trackEvent({
      action: `Generate: PDF${exportId ? ' BOT' : ''}`,
      category: 'Dashboard',
    })
    setDownloadingPDF(true)
    await print(pdfTitle)
    setDownloadingPDF(false)
  }
  const backToPlatform = () => {
    setQueryParams({
      download_pdf: false,
      from: 'download_pdf',
    })
    setPrintMode(false)
  }

  if (printMode) {
    window.onpopstate = () => {
      setPrintMode(false)
    }
  }

  return (
    <PrinterContext.Provider value={contextValue as PrinterCtx}>
      {printMode ? (
        <div>
          <div className={styles.Header}>
            <Title className={styles.PreviewTitle}>PDF preview</Title>
            <div className={styles.Buttons}>
              {!exportId && (
                <Button className={styles.ButtonsGoBack} quaternary onClick={backToPlatform}>
                  <div className={styles.Button}>
                    <ArrowLitleLeftIcon />
                    <ArrowLitleLeftIcon />
                    <span>Back to platform</span>
                  </div>
                </Button>
              )}
              <Button
                className={cx(styles.ButtonsDownload, {
                  [styles.ButtonsDownloadLoading]: !isLoadingDone,
                })}
                primary={isLoadingDone && !hasErrorOnFinish}
                primarySilent={!isLoadingDone}
                onClick={() => isLoadingDone && !hasErrorOnFinish && handleDownloadPDF()}
              >
                <div className={styles.Button} data-tip="" data-for={'download-pdf'}>
                  <span>Download PDF</span>
                  <ArrowLittleRightIcon />
                  <ArrowLittleRightIcon />
                  {!isLoadingDone && (
                    <Tooltip id={'download-pdf'} external className={styles.Tooltip}>
                      "Download PDF" will be clickable once the preview has finished loading.
                    </Tooltip>
                  )}
                </div>
              </Button>
            </div>
          </div>

          <div id={DOM_ID_TO_PRINT} className={cx({ [styles.Wrapper]: printMode })}>
            {printMode && <img className={styles.PDFHeader} src={PDFHeader} />}
            {children}
          </div>
          {downloadingPDF && (
            <div className={styles.Downloading}>
              <PacManLoader />
            </div>
          )}
        </div>
      ) : (
        children
      )}
    </PrinterContext.Provider>
  )
}

export default PrinterProvider
