import isEqual from 'lodash/isEqual'
import { DependencyList, useMemo, useRef } from 'react'

const useDeepCompareMemoize = <T extends DependencyList = any>(value: T): T => {
  const ref = useRef<DependencyList>()
  if (!isEqual(value, ref.current)) {
    ref.current = value
  }
  return ref.current as T
}

const useDeepCompareMemo = <T>(callback: (...args: any) => T, dependencies: DependencyList) => {
  return useMemo(callback, useDeepCompareMemoize(dependencies))
}

export default useDeepCompareMemo
