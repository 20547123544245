import cx from 'classnames'
import { FC, HTMLProps } from 'react'

import * as styles from './SeparatorVertical.scss'

interface SeparatorVerticalProps extends HTMLProps<HTMLDivElement> {
  withoutMargin?: boolean
  lineClassName?: string
}

const SeparatorVertical: FC<SeparatorVerticalProps> = ({
  withoutMargin,
  lineClassName,
  className,
  ...divProps
}) => {
  const classes = cx(
    {
      [styles.SeparatorVertical]: true,
      [styles.SeparatorVerticalWithoutMargin]: withoutMargin,
    },
    className,
  )

  return (
    <div className={classes} {...divProps}>
      <div className={cx(styles.SeparatorVerticalLine, lineClassName)} />
    </div>
  )
}

export default SeparatorVertical
