import cx from 'classnames'
import { FC, HTMLProps, ReactNode } from 'react'

import SpaceBetween from '../SpaceBetween'

import * as styles from './Modal.scss'

interface FooterProps extends HTMLProps<HTMLDivElement> {
  children: ReactNode
  hasSpaceBetween?: boolean
  isColored?: boolean
}

const Footer: FC<FooterProps> = ({
  children,
  hasSpaceBetween,
  className,
  isColored,
  ...divProps
}) => (
  <div
    className={cx(styles.Footer, { [styles.FooterColored]: isColored }, className)}
    {...divProps}
  >
    {hasSpaceBetween ? <SpaceBetween>{children}</SpaceBetween> : <div>{children}</div>}
  </div>
)

export default Footer
