import { FC, ReactNode } from 'react'

import HtmlProps from 'src/types/HtmlProps'

export interface OptionItemProps extends HtmlProps<HTMLButtonElement> {
  children: ReactNode
}

const OptionItem: FC<OptionItemProps> = ({ children, ...otherProps }) => (
  <button {...otherProps} type="button">
    {children}
  </button>
)

export default OptionItem
