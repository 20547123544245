import { FC } from 'react'

import Text from '../../atoms/PlaceHolder/Text'

import * as styles from './ShareDashboard.scss'

const ShareDashboardModalBodyLoading: FC = () => {
  return (
    <div className={styles.ShareDashboardModalBody}>
      {Array.from({ length: 6 }).map((_, index) => (
        <Text key={index} />
      ))}
    </div>
  )
}

export default ShareDashboardModalBodyLoading
