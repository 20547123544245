import cx from 'classnames'
import { ReactNode, useState } from 'react'

import Footer from 'src/components/ui/atoms/Footer'
import Menu from 'src/components/ui/organisms/Menu'
import usePrinter from 'src/hooks/usePrinter'

import * as styles from './Layout.scss'

interface Props {
  children: ReactNode
}

const Layout = ({ children }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { printMode } = usePrinter()

  return (
    <div
      className={cx(styles.App, {
        [styles.AppPrintMode]: printMode,
      })}
    >
      <main className={styles.Content}>
        {!printMode && <Menu onToggle={setIsMenuOpen} />}
        <div
          className={cx(styles.RightPanel, {
            [styles.RightPanelMenuOpen]: isMenuOpen,
            [styles.RightPanelPrintMode]: printMode,
          })}
        >
          <div className={styles.RouteContent} id="content">
            <div
              className={cx(styles.ContentWithoutFooter, {
                [styles.ContentWithoutFooterPrintMode]: printMode,
              })}
            >
              {children}
            </div>
            <Footer />
          </div>
        </div>
      </main>
    </div>
  )
}

export default Layout
