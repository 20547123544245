import cx from 'classnames'

import ComponentColor from 'src/types/ItemColor'

import * as styles from 'src/styles/ComponentColor.scss'

type FieldType =
  | 'label'
  | 'labelLight'
  | 'labelActive'
  | 'labelDropdownActive'
  | 'background'
  | 'backgroundWithHover'
  | 'backgroundActive2'
  | 'backgroundActive'
  | 'arrow'
  | 'dropdownBorder'
  | 'borderColor'
  | 'backgroundHover'
  | 'tableRowBackground'
  | 'labelHover'
  | 'listHover'
  | 'icon'
  | 'iconActive'
  | 'rowHover'
  | 'rowHoverLight'
  | 'color'

interface ComponentColorArg {
  componentColor: ComponentColor | undefined
  fields: FieldType[]
  className?: string
  skipOnUndefined?: boolean
}

export const getComponentColorClassNameFn = ({
  componentColor: componentColorArg,
  fields,
  className,
  skipOnUndefined = false,
}: ComponentColorArg): string | undefined => {
  if (!fields.length) return className
  if (skipOnUndefined && !componentColorArg) return className

  const classesMap: Record<
    FieldType,
    {
      [ComponentColor.blue]: string
      [ComponentColor.orange]: string
    }
  > = {
    label: {
      [ComponentColor.blue]: styles.ComponentColorLabel,
      [ComponentColor.orange]: styles.ComponentColorLabelOrange,
    },
    labelActive: {
      [ComponentColor.blue]: styles.ComponentColorLabelActive,
      [ComponentColor.orange]: styles.ComponentColorLabelOrangeActive,
    },
    labelLight: {
      [ComponentColor.blue]: styles.ComponentColorLabelLight,
      [ComponentColor.orange]: styles.ComponentColorLabelLightOrange,
    },
    labelDropdownActive: {
      [ComponentColor.blue]: styles.ComponentColorLabelDropdownActive,
      [ComponentColor.orange]: styles.ComponentColorLabelOrangeDropdownActive,
    },
    background: {
      [ComponentColor.blue]: styles.ComponentColorBackground,
      [ComponentColor.orange]: styles.ComponentColorBackgroundOrange,
    },
    backgroundWithHover: {
      [ComponentColor.blue]: cx(
        styles.ComponentColorBackground,
        styles.ComponentColorBackgroundWithHover,
      ),
      [ComponentColor.orange]: cx(
        styles.ComponentColorBackgroundOrange,
        styles.ComponentColorBackgroundOrangeWithHover,
      ),
    },
    backgroundActive: {
      [ComponentColor.blue]: styles.ComponentColorBackgroundActive,
      [ComponentColor.orange]: styles.ComponentColorBackgroundOrangeActive,
    },
    backgroundActive2: {
      [ComponentColor.blue]: styles.ComponentColorBackgroundActiveGray,
      [ComponentColor.orange]: styles.ComponentColorBackgroundOrangeActive,
    },
    arrow: {
      [ComponentColor.blue]: styles.ComponentColorArrow,
      [ComponentColor.orange]: styles.ComponentColorArrowOrange,
    },
    dropdownBorder: {
      [ComponentColor.blue]: styles.ComponentColorDropdownBorder,
      [ComponentColor.orange]: styles.ComponentColorDropdownBorderOrange,
    },
    borderColor: {
      [ComponentColor.blue]: styles.ComponentColorBorderColor,
      [ComponentColor.orange]: styles.ComponentColorBorderColorOrange,
    },
    backgroundHover: {
      [ComponentColor.blue]: styles.ComponentColorBackgroundHover,
      [ComponentColor.orange]: styles.ComponentColorBackgroundHoverOrange,
    },
    tableRowBackground: {
      [ComponentColor.blue]: styles.ComponentColorTableRowBackground,
      [ComponentColor.orange]: styles.ComponentColorTableRowBackgroundOrange,
    },
    labelHover: {
      [ComponentColor.blue]: styles.ComponentColorLabelHover,
      [ComponentColor.orange]: styles.ComponentColorLabelOrangeHover,
    },
    listHover: {
      [ComponentColor.blue]: styles.ComponentColorListHover,
      [ComponentColor.orange]: styles.ComponentColorListHoverOrange,
    },
    icon: {
      [ComponentColor.blue]: styles.ComponentColorIcon,
      [ComponentColor.orange]: styles.ComponentColorIconOrange,
    },
    iconActive: {
      [ComponentColor.blue]: styles.ComponentColorIconActive,
      [ComponentColor.orange]: styles.ComponentColorIconOrangeActive,
    },
    rowHover: {
      [ComponentColor.blue]: styles.ComponentColorRowHover,
      [ComponentColor.orange]: styles.ComponentColorRowHoverOrange,
    },
    rowHoverLight: {
      [ComponentColor.blue]: styles.ComponentColorRowHoverLight,
      [ComponentColor.orange]: styles.ComponentColorRowHoverLightOrange,
    },
    color: {
      [ComponentColor.blue]: styles.ComponentColorColor,
      [ComponentColor.orange]: styles.ComponentColorColorOrange,
    },
  }

  const componentColor = componentColorArg || ComponentColor.blue

  const fieldsClassNames = Object.keys(classesMap)
    .filter(key => fields.includes(key as FieldType))
    .reduce<string | undefined>(
      (acc, key) => cx(acc, classesMap[key as FieldType][componentColor]),
      undefined,
    )

  return cx(fieldsClassNames, className)
}

export const getMainColorFn = (componentColor: ComponentColor | undefined): string => {
  const color = componentColor || ComponentColor.blue

  switch (color) {
    case ComponentColor.orange:
      return '#ef942a'
    default:
      return '#19a9ed'
  }
}

export const getLightHoverColorFn = (componentColor: ComponentColor | undefined): string => {
  const color = componentColor || ComponentColor.blue

  switch (color) {
    case ComponentColor.orange:
      return '#FFF5EA'
    default:
      return '#EDF1F8'
  }
}
