import * as qs from 'qs'
import { useMemo } from 'react'

import { getGeoCountryFromProgram } from 'src/utils/filters'
import { parse } from 'src/utils/urls'
import useFilterBar from './useFilterBar'
import { useIsBenchmark } from './useIsBenchmark'

const useGetCurrentUrl = () => {
  const { rawFilters, requestFilters } = useFilterBar()
  const isBenchmarkProduct = useIsBenchmark()

  const url = useMemo(() => {
    const geoCountry = getGeoCountryFromProgram(rawFilters?.market?.raw?.program)
    const { origin, search, pathname } = window.location
    const currentUrlSearch = search.slice(1) // Remove first char (?) in search
    const newUrlSearch = parse(currentUrlSearch)
    const geoCountryField = !isBenchmarkProduct && geoCountry ? { geo_country: geoCountry } : {}
    const newParams = {
      ...newUrlSearch,
      scopes: {
        filters: { ...newUrlSearch?.scopes?.filters, ...geoCountryField },
        exclude: newUrlSearch?.scopes?.exclude,
      },
      from: 'shared_link',
    }
    const newSearch = `?${qs.stringify(newParams)}`
    const newUrl = `${origin}${pathname}${newSearch}`

    return newUrl
  }, [rawFilters?.market?.raw?.program, isBenchmarkProduct, requestFilters])

  return url
}

export default useGetCurrentUrl
