import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import useShareDashboard from 'src/hooks/useShareDashboard'
import useTracking from 'src/hooks/useTracking'
import SelectOptionInterface from 'src/types/SelectOptionInterface'
import { ShareDashboardInput } from 'src/types/ShareDashboard'
import { UserInterface } from 'src/types/UserInterface'
import { getUserFullNameFn } from 'src/utils/userUtils'
import Button from '../../atoms/Button'
import Select from '../../atoms/Select'
import { TextInput } from '../../atoms/TextInput'
import Typography from '../../atoms/Typography'
import { useShareDashboardContext } from './ShareDashboardProvider'

import * as styles from './ShareDashboard.scss'

const ShareDashboardModalUsers: FC = () => {
  const { users } = useShareDashboardContext()

  const [{ loading: isSharingDashboard }, handleShareDashboard] = useShareDashboard()

  const { trackEvent } = useTracking()

  const schema = yup
    .object({
      name: yup.string().required('Required'),
      selectedUsers: yup.array().min(1, 'Required'),
    })
    .required()

  const {
    handleSubmit,
    reset,
    formState: { errors, touchedFields, isSubmitted, isValid },
    register,
    control,
    setValue,
  } = useForm<ShareDashboardInput>({
    defaultValues: {
      name: '',
      selectedUsers: [],
    },
    resolver: yupResolver(schema) as any,
  })

  const onSubmit = (data: ShareDashboardInput) => {
    const dashboardName = data.name
    trackEvent({
      action: 'Share a Dashboard',
      label: dashboardName,
    })
    return handleShareDashboard({
      label: dashboardName,
      userIds: data.selectedUsers.map(user => user.id),
    }).then(() => reset())
  }

  useEffect(() => {
    return () => reset()
  }, [])

  const userOptions: SelectOptionInterface[] = useMemo(
    () =>
      users.map(user => ({
        label: getUserFullNameFn(user), // Todo change to fullName
        value: user.id.toString(),
      })),
    [users],
  )

  return (
    <div>
      <div className={styles.ShareDashboardModalBodyTitleSection}>
        <Typography fontSize={16} variant="title2">
          Share your full Dashboard
        </Typography>
        <Typography fontSize={14} variant="body">
          Share your full Dashboard filter and customisation (Charts, Ecosystem tables). Your shared
          Dashboard will be available in your colleague's saved dashboard list.
        </Typography>
      </div>
      <div>
        <TextInput
          label="DASHBOARD NAME"
          {...register('name')}
          meta={{
            error: errors?.name?.message,
            touched: touchedFields.name || isSubmitted,
          }}
          fullWidth
        />
        <div className={styles.ShareDashboardModalBodyUserInput}>
          <Typography variant="label" className={styles.ShareDashboardModalBodyUserInputLabel}>
            CHOOSE A USER
          </Typography>
          <Controller
            name="selectedUsers"
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <Select
                {...field}
                value={field.value.map((user: UserInterface) => ({
                  label: getUserFullNameFn(user),
                  value: user.id.toString(),
                }))}
                className={styles.ShareDashboardModalBodyUserInputSelect}
                options={userOptions}
                onChange={(options: any) => {
                  const selectedUsers = options
                    .map(option => users.find(user => user.id.toString() === option.value))
                    .filter(Boolean) as UserInterface[]

                  setValue('selectedUsers', selectedUsers)
                  field.onChange(selectedUsers)
                }}
                multi
                error={
                  (touchedFields.selectedUsers || isSubmitted) && errors?.selectedUsers?.message
                }
              />
            )}
          />
        </div>
      </div>
      <div className={styles.ShareDashboardModalBodyActionContainer}>
        <Button
          className={styles.ShareDashboardModalBodyActionContainerButton}
          disabled={isSharingDashboard || !isValid}
          onClick={handleSubmit(onSubmit)}
          buttonType="primary"
        >
          Share
        </Button>
      </div>
    </div>
  )
}

export default ShareDashboardModalUsers
