import groupBy from 'lodash/groupBy'
import range from 'lodash/range'

import { Dimension } from 'src/utils/search'

/**
 * @param value
 * @param srcValue
 */
export const mergeUnique = (value: any, srcValue: any) => {
  if (value !== undefined && srcValue !== undefined && Array.isArray(value)) {
    return srcValue
  }
}

/**
 * Remove undefined, null, and empty string from an object with nested objects
 * @param obj
 */
export const cleanObject = (obj: object): any => {
  const t = obj

  for (const v in t) {
    if (typeof t[v] == 'object') {
      cleanObject(t[v])
      if (t[v] && !Object.keys(t[v]).length && !Array.isArray(t[v])) {
        delete t[v]
      }
    } else if (t[v] == undefined && t[v] == null && t[v] == '') {
      delete t[v]
    }
  }
  return t
}

/**
 * Make the first letter of a string uppercase
 * @param string value
 */
export const capitalizeFirst = (value: string) => {
  if (!value) {
    return ''
  }
  return `${value.charAt(0).toUpperCase()}${value.slice(1)}`
}

/**
 * Convert Hex to RGB(A)
 * @param string hex
 * @param number alpha
 */
export const hexToRGBA = (hex: string, alpha = 0.15) => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')'
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')'
  }
}

/**
 * Convert RGB to RGB(A)
 * @param string rgb
 * @param number alpha
 */
export const rgbToRGBA = (rgb: string, alpha = 0.15) => {
  let colour = rgb.replace(/rgb/i, 'rgba')
  colour = colour.replace(/\)/i, `,${alpha})`)

  return colour
}

/**
 * Categorize items by column
 *
 * @param Dimension[] items
 * @param number maxItemsPerColumns
 */
export const groupedItemsPerColumn = (items: readonly Dimension[], maxItemsPerColumns?: number) => {
  const groupMaxItemsPerColumn = (acc: [string, Dimension[]][], current: [string, Dimension[]]) => {
    const [dimension, values] = current
    const hasMaxItemPerColumns = maxItemsPerColumns !== undefined

    if (hasMaxItemPerColumns && values.length > maxItemsPerColumns) {
      const requiredColumns = Math.ceil(values.length / maxItemsPerColumns)

      range(0, requiredColumns).forEach(index => {
        const endSliceIndex = (index + 1) * maxItemsPerColumns
        const startSliceIndex = endSliceIndex - maxItemsPerColumns
        const slice = values.slice(startSliceIndex, endSliceIndex)

        acc.push([dimension, slice])
      })
    } else {
      acc.push(current)
    }
    return acc
  }

  const groupedItems = groupBy(items, t => t.category)

  return Object.entries(groupedItems).reduce(groupMaxItemsPerColumn, [])
}
