import cx from 'classnames'
import { Component, MouseEventHandler, ReactNode } from 'react'
import { MdClose } from 'react-icons/md'

import * as styles from './Modal.scss'

const ESCAPE = 27

export interface ModalCoreProps {
  children: ReactNode
  onRequestClose: MouseEventHandler<HTMLDivElement>
  open: boolean
  isManuallyShaped?: boolean
  isManuallyColored?: boolean
  hideCloseIcon?: boolean
  className?: string
  clickOutsideToClose?: boolean
  disableCloseIcon?: boolean
  'data-testid'?: string
  containerClassName?: string
}

class ModalCore extends Component<ModalCoreProps> {
  nodes: {
    background: HTMLDivElement | null
    content: HTMLDivElement | null
  } = {
    background: null,
    content: null,
  }

  constructor(props: ModalCoreProps) {
    super(props)

    this.onKeyDown = this.onKeyDown.bind(this)
    this.setBackgroundRef = this.setBackgroundRef.bind(this)
    this.setContentRef = this.setContentRef.bind(this)
    this.clickOutside = this.clickOutside.bind(this)
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown)
    this.props.clickOutsideToClose && document.addEventListener('click', this.clickOutside, false)
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.clickOutsideToClose !== this.props.clickOutsideToClose &&
      !this.props.clickOutsideToClose
    ) {
      document.removeEventListener('click', this.clickOutside, false)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown)
    this.props.clickOutsideToClose &&
      document.removeEventListener('click', this.clickOutside, false)
  }

  onKeyDown(e) {
    const { onRequestClose } = this.props
    if (e.keyCode === ESCAPE) {
      onRequestClose(e)
    }
  }

  setBackgroundRef(node: HTMLDivElement) {
    this.nodes.background = node
  }

  setContentRef(node) {
    this.nodes.content = node
  }

  clickOutside(e) {
    const { onRequestClose } = this.props

    if (this.nodes.background?.contains(e.target) && !this.nodes.content?.contains(e.target)) {
      onRequestClose(e)
    }
  }

  render() {
    const {
      open,
      onRequestClose,
      children,
      isManuallyShaped,
      isManuallyColored,
      hideCloseIcon,
      className,
      disableCloseIcon,
      'data-testid': dataTestId,
      containerClassName,
    } = this.props

    const contentClasses = cx(
      {
        [styles.Content]: true,
        [styles.ContentManuallyShaped]: isManuallyShaped,
        [styles.ContentManuallyColored]: isManuallyColored,
      },
      className,
    )

    return (
      <div
        className={cx(styles.Modal, { [styles.DisplayNone]: !open }, containerClassName)}
        ref={this.setBackgroundRef}
        data-testid={dataTestId}
      >
        <div className={contentClasses} ref={this.setContentRef}>
          {!hideCloseIcon && (
            <div
              role="button"
              onClick={onRequestClose}
              className={cx(styles.CloseButton, {
                [styles.CloseButtonDisable]: disableCloseIcon,
              })}
              tabIndex={0}
              aria-label="Modal close button"
            >
              <MdClose />
            </div>
          )}
          {children}
        </div>
      </div>
    )
  }
}

export default ModalCore
