import { AxiosError, AxiosRequestConfig } from 'axios'
import { MutableRefObject } from 'react'

import PageLoading from 'src/hooks/usePageLoading/PageLoading'
import { API_DATA_URL, API_USER_URL, SNOWFLAKE_REPORTING_URL } from 'src/utils/config'
import { LOGOUT_STATUS_ERRORS } from 'src/utils/constants/LogoutStatusErrors'
import storageUtils from 'src/utils/storageUtils'

interface getHeadersArg {
  currentConfig?: AxiosRequestConfig
  requestUrl?: string
  globalRequestIdRef: MutableRefObject<string | null>
  generateNewId: (isGlobal?: boolean) => string
  axiosDefaultConfigs: AxiosRequestConfig
  isSnowProduct: boolean
}

const EXPORT_CONFIG_URL = '/api/admin/export_configs'

// get config with page id header
export const getConfigWithPageHeaderIds = ({
  currentConfig,
  requestUrl,
  globalRequestIdRef,
  generateNewId,
  axiosDefaultConfigs,
  isSnowProduct,
}: getHeadersArg) => {
  const config = (currentConfig ?? {}) as AxiosRequestConfig
  config.headers = config.headers || {}
  const { adomikNavigationId, adomikPlatformContext, adomikPlatformContextId, globalRequestId } =
    PageLoading.getInstance().getData()

  const currentRequestUrl = requestUrl ?? config.url

  //   Add header ids only on report url
  if (
    currentRequestUrl?.startsWith(API_DATA_URL) ||
    currentRequestUrl?.startsWith(SNOWFLAKE_REPORTING_URL)
  ) {
    if (!adomikNavigationId) {
      return config
    }
    let navigationId = adomikNavigationId
    if (
      globalRequestIdRef.current === globalRequestId &&
      !currentRequestUrl.endsWith('indicators')
    ) {
      // generate new id
      navigationId = generateNewId(false)
    } else {
      globalRequestIdRef.current = globalRequestId
    }

    config.headers['Adomik-Navigation-Id'] = navigationId
    if (adomikPlatformContext) {
      config.headers['Adomik-Platform-Context'] = adomikPlatformContext
      if (adomikPlatformContextId) {
        config.headers['Adomik-Platform-Context-Id'] = adomikPlatformContextId
      }
    }
  } else if (currentRequestUrl?.startsWith(API_USER_URL)) {
    if (currentRequestUrl?.includes(EXPORT_CONFIG_URL)) {
      config.headers['Adomik-Origin'] = 'snowflake'
    } else {
      config.headers['Adomik-Origin'] = isSnowProduct ? 'snowflake' : 'pg'
    }
  }

  const token = storageUtils.getAuthToken()

  if (token) {
    config.headers['authorization'] = token
  }

  config.headers = {
    ...axiosDefaultConfigs.headers,
    ...config.headers,
  }

  return config
}

/**
 * Retry when status is network error
 */
export const isErrorNeedRetryFn = (error: AxiosError) => {
  const status = error?.response?.status

  if (status && LOGOUT_STATUS_ERRORS.includes(status)) {
    return false
  }

  return error?.message === 'Network Error'
}
