import { FC, useEffect } from 'react'

import PostMessagePayloadInterface from 'src/types/PostMessagePayloadInterface'
import { CROSS_STORAGE_PLATFORM_URL } from 'src/utils/config'
import { safeParseJsonFn } from 'src/utils/jsonUtils'

const StorageLoadingFrame: FC = () => {
  useEffect(() => {
    if (!CROSS_STORAGE_PLATFORM_URL) return

    window.addEventListener('message', function (event) {
      if (event.origin === CROSS_STORAGE_PLATFORM_URL) {
        const dataToRetrieve = event.data

        const parsedData = safeParseJsonFn<PostMessagePayloadInterface>(dataToRetrieve)

        if (!parsedData) return

        if (parsedData.type === 'getStorage' && typeof parsedData.payload === 'string') {
          const localStorageData = localStorage.getItem(parsedData.payload)

          const setStoragePayload: PostMessagePayloadInterface = {
            type: 'setStorage',
            key: parsedData.payload,
            payload: localStorageData || '',
          }

          window.parent.postMessage(JSON.stringify(setStoragePayload), event.origin)
          return
        }

        if (parsedData.type === 'clearStorage') {
          localStorage.clear()
        }
      }
    })
  }, [])

  //   if is not inside iframe, redirect to home
  if (window.self === window.top) {
    window.location.href = '/'
  }

  return null
}

export default StorageLoadingFrame
