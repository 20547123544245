import cx from 'classnames'
import PropTypes from 'prop-types'
import { FC } from 'react'

import * as styles from './BallPulseLoader.scss'

const VARIANT = {
  primary: 'primary',
  secondary: 'secondary',
}

interface BallPulseLoaderProps {
  className?: string
  variant?: 'primary' | 'secondary'
}

const BallPulseLoader: FC<BallPulseLoaderProps> = ({ className, variant = VARIANT.secondary }) => {
  const ballsClasses = cx(styles.Ball, {
    [styles.BallPrimary]: variant === VARIANT.primary,
    [styles.BallSecondary]: variant === VARIANT.secondary,
  })
  return (
    <div data-testid="ball-pulse-loader" className={cx(styles.Container, className)}>
      <div>
        <div className={styles.BallPulse}>
          {[1, 2, 3].map(i => (
            <div className={ballsClasses} key={i} />
          ))}
        </div>
      </div>
    </div>
  )
}
BallPulseLoader.propTypes = {
  className: PropTypes.string,
}
export default BallPulseLoader
