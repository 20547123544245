import PropTypes from 'prop-types'

import * as styles from './SpaceBetween.scss'

const SpaceBetween = ({ children }) => <div className={styles.SpaceBetween}>{children}</div>

SpaceBetween.propTypes = {
  children: PropTypes.any.isRequired,
}

export default SpaceBetween
