import { FC, ReactNode, useCallback, useEffect, useState } from 'react'

import useAccount from 'src/hooks/useAccount'
import PostMessagePayloadInterface from 'src/types/PostMessagePayloadInterface'
import { AUTH_TOKEN_KEY, CROSS_STORAGE_FRAME_ID } from 'src/utils/constants/localStorageKey'
import { safeParseJsonFn } from 'src/utils/jsonUtils'
import { CROSS_STORAGE_PLATFORM_URL } from '../../../../utils/config'
import storageUtils from '../../../../utils/storageUtils'
import AdomikLoader from '../../atoms/PlaceHolder/AdomikLoader/AdomikLoader'
import StorageLoadingFrame from './StorageLoadingFrame'

const TIME_OUT = 1000

interface StorageLoadingProps {
  children: ReactNode
}

const StorageLoading: FC<StorageLoadingProps> = ({ children }) => {
  const token = storageUtils.getAuthToken()
  const [isIframeLoaded, setIsIframeLoaded] = useState(false)
  const { logoutLoading } = useAccount()

  // Check if cross-storage is present in the url
  const url = window.location.href
  const { pathname, search } = new URL(url)
  const params = new URLSearchParams(search)

  const isInCrossStoragePage = params.get('cross-storage') === 'true' && pathname === '/'

  const [isCrossLoading, setIsCrossLoading] = useState(
    !token && Boolean(CROSS_STORAGE_PLATFORM_URL) && !isInCrossStoragePage,
  )

  const initFrame = useCallback(() => {
    if (!isIframeLoaded) return

    const targetWindowFrame = document.getElementById(CROSS_STORAGE_FRAME_ID) as HTMLIFrameElement

    if (!targetWindowFrame) return

    const targetWindow = targetWindowFrame?.contentWindow

    if (logoutLoading && !isCrossLoading) {
      const payload: PostMessagePayloadInterface = {
        type: 'clearStorage',
      }
      targetWindow?.postMessage(JSON.stringify(payload), CROSS_STORAGE_PLATFORM_URL)
      return
    }

    if (!isCrossLoading) {
      return
    }

    const closeFrame = () => {
      setIsCrossLoading(false)
      setIsIframeLoaded(false)
    }

    // Abort the request after a timeout
    setTimeout(closeFrame, TIME_OUT)

    const payload: PostMessagePayloadInterface = {
      type: 'getStorage',
      payload: AUTH_TOKEN_KEY,
    }

    targetWindow?.postMessage(JSON.stringify(payload), CROSS_STORAGE_PLATFORM_URL)

    window.addEventListener('message', event => {
      // Verify the origin of the message
      if (event.origin === CROSS_STORAGE_PLATFORM_URL) {
        // Access the localStorage data received from the target domain
        const dataToRetrieve = event.data
        const parsedData = safeParseJsonFn<PostMessagePayloadInterface>(dataToRetrieve)

        if (!parsedData) return

        if (parsedData.type === 'setStorage' && isCrossLoading) {
          if (parsedData.payload) {
            if (
              parsedData.key === AUTH_TOKEN_KEY &&
              parsedData.payload === storageUtils.getOldAuthToken()
            ) {
              setIsCrossLoading(false)
              return
            }
            localStorage.setItem(parsedData.key, parsedData.payload)
          }
          setIsCrossLoading(false)
          return
        }

        if (parsedData.type === 'clearStorage') {
          localStorage.clear()
          setIsCrossLoading(false)
          return
        }
      }
    })
  }, [isInCrossStoragePage, isIframeLoaded, logoutLoading])

  useEffect(() => {
    if (!CROSS_STORAGE_PLATFORM_URL || isInCrossStoragePage) return

    initFrame()
  }, [initFrame])

  if (isInCrossStoragePage) {
    return <StorageLoadingFrame />
  }

  const crossStorageUrl = CROSS_STORAGE_PLATFORM_URL
    ? `${CROSS_STORAGE_PLATFORM_URL}/?cross-storage=true`
    : null

  if ((isCrossLoading || logoutLoading) && crossStorageUrl) {
    return (
      <>
        <AdomikLoader />
        <iframe
          src={crossStorageUrl}
          id={CROSS_STORAGE_FRAME_ID}
          onLoad={() => setIsIframeLoaded(true)}
          style={{ display: 'none' }}
        />
      </>
    )
  }

  return <>{children}</>
}

export default StorageLoading
