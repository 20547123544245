import { FC } from 'react'

import Number, { Props as NumberProps } from 'src/components/ui/atoms/Number'
import { Currency as ICurrency } from 'src/utils/currency'
import currencyLocales from 'src/utils/shapeNumber/currencyLocales'
import { ShapeNumberOptions } from 'src/utils/shapeNumber/number'

export interface CurrencyProps extends NumberProps {
  currency: Pick<ICurrency, 'position' | 'code'>
}

const defaultShapeNumberOptions: ShapeNumberOptions = {
  decimals: 2,
  truncate: true,
}

const Currency: FC<CurrencyProps> = ({
  number,
  variationSymbol = 'plus_minus',
  shapeNumberOptions,
  currency,
  ...othersProps
}) => {
  const options = { ...defaultShapeNumberOptions, ...shapeNumberOptions }
  const { truncate } = options
  const isCurrencySymbolBefore = currency.position === 'before'
  const defaultFormat = `${isCurrencySymbolBefore ? '$' : ''}0${!truncate ? ',0' : ''}.${'0'.repeat(
    options.decimals as number,
  )}${!isCurrencySymbolBefore ? '$' : ''}${!truncate ? '' : 'a'}`

  return (
    <Number
      number={number}
      variationSymbol={variationSymbol}
      shapeNumberOptions={{
        ...options,
        format: defaultFormat,
        currencyCode: currencyLocales[currency.code],
      }}
      {...othersProps}
    />
  )
}

export default Currency
