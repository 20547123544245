import { useMemo } from 'react'

import { matchProduct } from 'src/utils'
import { PRODUCT } from 'src/utils/constant'
import useProductPage from './useProductPage'

export const useIsBenchmark = () => {
  const { product } = useProductPage()
  const isBenchmark = useMemo(() => matchProduct(product, PRODUCT.benchmark), [product])
  return isBenchmark
}
