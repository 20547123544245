import { useEffect, useMemo } from 'react'
import { FiHelpCircle } from 'react-icons/fi'

import Select, { Option } from 'src/components/ui/atoms/Select'
import Tooltip from 'src/components/ui/atoms/Tooltip'
import { ExportConfig } from 'src/utils/export'
import { SettingsTimePeriod } from 'src/utils/settings'
import { KB_LINK } from '../../ExportSchedulingForm'

import * as styles from './Frequencies.scss'

enum Frequency {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
}
interface FrequenciesProps extends HTMLFormElement {
  timePeriod: SettingsTimePeriod
  config: ExportConfig
  setDefaultFrequency: (defaultFrequency: any) => void
  frequencyDataTestId?: string
  subFrequencyDataTestId?: string
}

const CUSTOM_FREQUENCY = [{ id: 'once', label: 'Once' }]

const Frequencies = ({
  frequencyDataTestId,
  subFrequencyDataTestId,
  ...fields
}: FrequenciesProps) => {
  const { date_ranges_frequencies, frequencies } = fields.config
  const frequencyValue = fields.frequency.input.value
  const currentRange = useMemo(
    () =>
      date_ranges_frequencies.find(
        dateRange => dateRange.id === fields?.timePeriod?.selectedRange.id,
      ),
    [fields.timePeriod],
  )

  const subFrequencies = useMemo(
    () => frequencies?.find(frequency => frequencyValue.value === frequency.id)?.sub_frequencies,
    [frequencyValue.value],
  )

  const defaultFrequency = useMemo(() => {
    return fields?.timePeriod?.selectedRangeCompare.id === 'custom' &&
      fields?.timePeriod?.selectedRange.id !== 'quarter_to_date' &&
      fields?.timePeriod?.selectedRange.id !== 'year_to_date'
      ? CUSTOM_FREQUENCY[0]
      : frequencies?.find(frequency => frequency.id === currentRange?.default_frequency_id)
  }, [currentRange])

  useEffect(() => {
    fields.setDefaultFrequency({
      frequency: {
        label: defaultFrequency?.label,
        value: defaultFrequency?.id,
      },
      subFrequency: {
        label: (defaultFrequency as any)?.sub_frequencies?.[0].label,
        value: (defaultFrequency as any)?.sub_frequencies?.[0].id,
      },
    })

    if (frequencyValue.value === Frequency.Monthly || frequencyValue.value === Frequency.Weekly) {
      fields.change('sub_frequency', {
        label: subFrequencies?.[0].label,
        value: subFrequencies?.[0].id,
      })
    }
  }, [defaultFrequency, frequencyValue])

  return (
    <div className={styles.DropdownContainer}>
      <Select
        options={frequencies.map(frequency => ({
          label: frequency.label,
          value: frequency.id,
        }))}
        defaultValue={
          {
            label: defaultFrequency?.label,
            value: defaultFrequency?.id,
          } as Option
        }
        onChange={fields.frequency.input.onChange}
        className={styles.Dropdown}
        isOptionDisabled={(option: Option) => !currentRange?.frequencies_ids.includes(option.value)}
        data-testid={frequencyDataTestId}
      />

      <FiHelpCircle className={styles.Icon} data-for="frequency" data-tip="" />
      <Tooltip id="frequency" place="right" className={styles.Tooltip} delayHide={100} clickable>
        For other frequency options, select a default Time period in your Dashboard (Last 7 days,
        Month to date, Last month etc).
        <br />
        <br />
        <a
          href={KB_LINK.urls.timePeriod}
          target="_blank"
          className={styles.TooltipLink}
          onClick={() => fields.trackTooltipLink('Frequency')}
          rel="noreferrer"
        >
          {KB_LINK.message}
        </a>
      </Tooltip>
      {subFrequencies && fields.timePeriod.view !== 'monthly' && (
        <>
          <label className={styles.Label}>
            {frequencyValue.value === Frequency.Weekly ? 'day of week' : 'day of month'}
          </label>
          <Select
            options={(subFrequencies as any).map((frequency: any) => ({
              label: frequency.label,
              value: frequency.id,
            }))}
            defaultValue={
              {
                label: subFrequencies[0].label,
                value: subFrequencies[0].id,
              } as any
            }
            onChange={fields.sub_frequency.input.onChange}
            value={fields.sub_frequency.input.value}
            className={styles.Dropdown}
            data-testid={subFrequencyDataTestId}
          />
        </>
      )}
    </div>
  )
}

export default Frequencies
