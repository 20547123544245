import has from 'lodash/has'
import isFunction from 'lodash/isFunction'

/**
 * @function
 * @description shouldRecurse limit to a maximum 4 recursions
 * @param {any} data - variable we want to recurse on
 * @param {number} depth - number current recursion depth
 * @returns {bool} true if data is an object and depth =< 4
 */
const shouldRecurse = (data, depth) => {
  if (depth > 4) {
    return false
  }
  return typeof data === 'object'
}

const getOptions = (element, { context }) => {
  const options = element.meta?.options || {}
  return options[context] || {}
}

const chooseKey = (defaultKey, key, raw, { context }) => {
  if (!key) {
    return defaultKey
  }
  if (isFunction(key)) {
    return key(defaultKey, raw, context)
  }
  return key
}

/**
 * @function
 * @description transform object with the format requested by execution context
 * @param {object} accumulator - previous state of the transformed data
 * @param {object} element - element to be transformed, it must have a key named raw
 * @param {string} defaultKey
 * @param {object} params - parameters of the current transformation
 * @param {string} [params.method=parse] - is equal to parse
 * @param {object} params.context - exapmle { Context: 'api' }
 * @returns {object} object current state of the transformed data
 */
const transformElement = (accumulator, element, defaultKey, params) => {
  const { method = 'parse', context } = params
  const { key, flatten, shouldBeKept } = getOptions(element, params)
  const transformed = element[method] ? element[method](element.raw, context) : element.raw
  const actualKey = chooseKey(defaultKey, key, element.raw, params)

  if (shouldBeKept && !shouldBeKept(element.raw, context)) {
    return accumulator
  }

  if (flatten) {
    return {
      ...accumulator,
      ...transformed,
    }
  }

  return {
    ...accumulator,
    [actualKey]: transformed,
  }
}

/**
 * @function
 * @description Centralize data transformation of forms (ex: Filter, DataDownload) in function
 * @param {object} data - returned by reduxForm at the moment of validation of the form
 * @param {object} params - parameters of the current transformation
 * @param {string} params.context - current context of execution (possibles values: api, query, url)
 * @param {string} [params.method=parse] - name of the method used
    to transform the data (default: parse)
 * @returns {object} usable object with the format requested by API
 */
const transformFormData = (data, params, depth = 1) => {
  if (!shouldRecurse(data, depth)) {
    return data
  }

  return Object.entries(data).reduce((accumulator, [key, element]) => {
    if (typeof data !== 'object') {
      return { ...accumulator, [key]: element }
    }

    /** if @param {object} element has a key named raw key, call transformElement */
    if (has(element, 'raw')) {
      return transformElement(accumulator, element, key, params)
    }

    const result = transformFormData(element, params, depth + 1)
    return {
      ...accumulator,
      [key]: Array.isArray(element) ? Object.values(result) : result,
    }
  }, {})
}

export default transformFormData
