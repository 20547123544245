import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'

import ChevronDown from 'src/components/ui/atoms/Icons/ChevronDown'
import ChevronUp from 'src/components/ui/atoms/Icons/ChevronUp'

import * as styles from './SearchDropdown.scss'

const SearchDropdown = props => {
  const {
    items,
    defaultValue,
    selectItem,
    renderItem,
    renderOption,
    isSearchable,
    className,
    name,
  } = props
  const [itemsList, setItemsList] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [placeHolderValue, setPlaceHolderValue] = useState('')
  const [query, setQuery] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const select = useRef(null)
  const input = useRef(null)
  const container = useRef(null)

  useEffect(() => {
    items && setItemsList(items)
    defaultValue && setPlaceHolderValue(defaultValue)
  }, [items])

  useEffect(() => {
    if (!isOpen) {
      input?.current && input.current.blur()
    }
  }, [isOpen])

  const onKeyDown = e => {
    if (e.keyCode === 27 && container.current.contains(document.activeElement)) {
      input?.current && input.current.blur()
      setIsOpen(false)
    }
  }

  const handleClickOutside = event => {
    if (container && !container.current.contains(event.target)) {
      input?.current && input.current.blur()
      setIsOpen(false)
    }
  }

  const handleClickOnContainer = e => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()

    if (container.current.contains(e.target)) {
      setIsOpen(!isOpen)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const onInputChange = e => {
    const inputValue = e.target.value
    setInputValue(inputValue)
    setQuery(inputValue.toLowerCase())
  }

  const handleSelectItem = givenItem => {
    const item = givenItem || itemsList[select.current.value]
    setInputValue('')
    setQuery('')
    setPlaceHolderValue(item.name)
    selectItem(item)
  }

  const filterWithQuery = el => {
    if (el.name && el.name.toLowerCase().includes(query)) {
      return true
    }
    if (el.code && el.code.toLowerCase().includes(query)) {
      return true
    }
    return false
  }

  const containerClasses = cx({
    [styles.SearchDropdown]: true,
  })

  let content

  if (isSearchable) {
    const inputClasses = cx([styles.formControl, styles.SearchInput])
    const options = itemsList.filter(filterWithQuery)
    content = (
      <>
        <input
          className={inputClasses}
          value={inputValue}
          placeholder={placeHolderValue}
          ref={input}
          onChange={onInputChange}
          data-testid={`input-${name}`}
        />
        {isOpen && (
          <ul className={styles.SearchResults} data-testid={`options-${name}`}>
            {!isEmpty(options) &&
              options.map(item => (
                <div
                  role="button"
                  onClick={() => handleSelectItem(item)}
                  tabIndex={0}
                  key={item.id}
                  className={styles.SearchOption}
                  aria-label={`Search result ${item.name}`}
                >
                  {renderOption ? renderOption(item) : <div>{item.name}</div>}
                </div>
              ))}
          </ul>
        )}
        {isOpen ? <ChevronUp className={styles.Icon} /> : <ChevronDown className={styles.Icon} />}
      </>
    )
  } else {
    const selectClasses = cx({
      [styles.formControl]: true,
      [styles.SearchSelect]: true,
      [className]: !!className,
    })
    const selected = itemsList.findIndex(el => el.name === defaultValue)
    content = (
      <select
        className={selectClasses}
        onChange={handleSelectItem}
        ref={select}
        defaultValue={selected}
      >
        {!isEmpty(itemsList) &&
          itemsList.map((item, index) => (
            <option key={item.id} value={index}>
              {renderItem ? renderItem(item) : item.name}
            </option>
          ))}
      </select>
    )
  }

  return (
    <div className={containerClasses} ref={container} onClick={handleClickOnContainer}>
      {content}
    </div>
  )
}

export default SearchDropdown

export const Option = ({ item }) => (
  <div className={styles.Option}>
    <div className={styles.name}>{item.name}</div>
  </div>
)

Option.propTypes = {
  item: PropTypes.object.isRequired,
  preventHandleChange: PropTypes.bool,
  handlePreventChange: PropTypes.func,
}
