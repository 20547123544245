const SaveAs = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="87" height="30" viewBox="0 0 87 30" {...props}>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-542.000000, -190.000000) translate(0.000000, 175.000000) translate(340.000000, 0.000000) translate(18.000000, 15.000000) translate(184.000000, 0.000000)">
        <text
          fill="currentColor"
          fontFamily="AvenirNext-Medium, Avenir Next"
          fontSize="14"
          fontWeight="400"
        >
          <tspan x="32.625" y="20">
            Save as
          </tspan>
        </text>
        <g>
          <path d="M0 0L17.4 0 17.4 16 0 16z" transform="translate(7.612500, 7.000000)" />
          <path
            fill="currentColor"
            fillRule="nonzero"
            d="M9.888 1.25C9.15 1.966 8.7 2.934 8.7 4c0 2.213 1.943 4 4.35 4 1.16 0 2.213-.415 2.992-1.093l-.001 6.343c0 .825-.734 1.5-1.632 1.5H2.991c-.898 0-1.632-.675-1.632-1.5V2.75c0-.825.726-1.5 1.632-1.5zM8.7 8.75c-1.354 0-2.447 1.005-2.447 2.25s1.093 2.25 2.447 2.25 2.447-1.005 2.447-2.25S10.054 8.75 8.7 8.75zM13.05 1c.45 0 .816.336.816.75v1.5h1.63c.451 0 .816.336.816.75s-.365.75-.815.75h-1.631v1.5c0 .414-.366.75-.816.75-.45 0-.816-.336-.816-.75v-1.5h-1.63c-.451 0-.816-.336-.816-.75s.365-.75.815-.75h1.631v-1.5c0-.414.366-.75.816-.75zM7.749 3H4.894c-.901 0-1.631.672-1.631 1.5 0 .83.73 1.5 1.63 1.5l3.3.001c-.37-.588-.58-1.271-.58-2.001 0-.346.047-.68.136-1z"
            transform="translate(7.612500, 7.000000)"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default SaveAs
