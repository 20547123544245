import isNil from 'lodash/isNil'
import { arrayOf, bool, func, number, object, shape, string } from 'prop-types'

import { CHECK_PROPTYPES_IN_DEPTH } from './config'
import { moment } from './index'

const customPropTypeFactory = customPropType => {
  const propType = (props, propName, ...args) => {
    if (isNil(props[propName])) {
      return null
    }
    return customPropType(props, propName, ...args)
  }
  propType.isRequired = (props, propName, componentName, ...args) => {
    if (isNil(props[propName])) {
      return new Error(`${componentName} requires the ${propName} property.`)
    }
    return customPropType(props, propName, componentName, ...args)
  }
  return propType
}

const optional = (mainValue, inactiveValue = object) =>
  CHECK_PROPTYPES_IN_DEPTH ? mainValue : inactiveValue

export const emptyOrShape = propShape =>
  customPropTypeFactory((props, propName, ...args) => {
    const value = props[propName]
    if (value && typeof value === 'object' && Object.keys(value).length === 0) {
      return null
    }
    return propShape(props, propName, ...args)
  })

export const momentType = customPropTypeFactory((props, propName, componentName) => {
  if (!moment.isMoment(props[propName])) {
    return new Error(`${componentName} requires that ${propName} be a Moment object.`)
  }
  return null
})

/**
 * Config related types
 */
export const elementType = optional(
  shape({
    id: string.isRequired,
    code: string.isRequired,
    component_identifier: string.isRequired,
    label: string,
    parent: string,
    children: arrayOf(
      shape({
        id: string.isRequired,
        code: string.isRequired,
      }),
    ),
    is_field: bool,
    component: func,
    listenersLocal: arrayOf(string),
    listenersAPI: arrayOf(string),
  }),
)

elementType.optional = emptyOrShape(elementType)

/**
 * Report types
 */
export const tagType = shape({
  id: string.isRequired,
  name: string.isRequired,
  platform: string,
  loading: bool,
  exclude: bool,
  dimension: string,
})

export const searchResultType = optional(
  shape({
    platforms: arrayOf(
      shape({
        platform: string.isRequired,
        data: arrayOf(tagType).isRequired,
      }),
    ),
    mapped: arrayOf(tagType),
    raw: arrayOf(tagType),
    isLoading: bool.isRequired,
  }),
)

export const dimensionType = shape({
  dimension: string.isRequired,
  label: string.isRequired,
  category: string,
  data: arrayOf(object),
})

export const savedQueryType = shape({
  label: string.isRequired,
  request: object,
})

/**
 * Application's structure types
 */
export const snackType = shape({
  content: string.isRequired,
  duration: number.isRequired,
  type: string,
})

export const rightType = shape({
  id: number.isRequired,
  name: string.isRequired,
})

export const companyType = shape({
  id: number.isRequired,
  name: string.isRequired,
  code: string,
  type: string.isRequired,
})

export const basicCompanyType = shape({
  id: number.isRequired,
  name: string.isRequired,
  code: string.isRequired,
})

export const basicProfileType = shape({
  id: string.isRequired,
  master: bool.isRequired,
  name: string.isRequired,
})

export const currencyType = shape({
  id: number.isRequired,
  code: string.isRequired,
  symbol: string.isRequired,
  position: string.isRequired,
  rate_per_usd: number.isRequired,
})

export const basicCurrencyType = shape({
  id: number.isRequired,
  name: string.isRequired,
  code: string.isRequired,
})

export const metricType = emptyOrShape(
  shape({
    code: string.isRequired,
    label: string.isRequired,
    type: string.isRequired,
  }),
)

export const userType = emptyOrShape(
  shape({
    id: number.isRequired,
    email: string.isRequired,
    first_name: string,
    last_name: string,
    currency: currencyType,
    default_company: companyType,
    main_company: companyType,
    companies: arrayOf(
      shape({
        right: string.isRequired,
        company: companyType.isRequired,
      }),
    ),
  }),
)
