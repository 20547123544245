import cx from 'classnames'
import { CSSProperties, FC, HTMLProps, ReactNode } from 'react'

import * as styles from './Typography.scss'

export interface TypographyProps extends HTMLProps<HTMLDivElement> {
  variant?:
    | 'title1'
    | 'title2'
    | 'label'
    | 'body'
    | 'modalTitle'
    | 'dropdownLabel'
    | 'dropdownSectionTitle'
    | 'sectionSeparatorTitle'
  children: ReactNode
  component?: 'div' | 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
  fontSize?: CSSProperties['fontSize']
  marginTop?: CSSProperties['marginTop']
  marginLeft?: CSSProperties['marginLeft']
  marginRight?: CSSProperties['marginRight']
  marginBottom?: CSSProperties['marginBottom']
  paddingLeft?: CSSProperties['paddingLeft']
  paddingRight?: CSSProperties['paddingRight']
  paddingTop?: CSSProperties['paddingTop']
  paddingBottom?: CSSProperties['paddingBottom']
  fontWeight?: CSSProperties['fontWeight']
}

const Typography: FC<TypographyProps> = ({
  variant,
  className,
  component,
  children,
  fontSize,
  style,
  marginTop,
  marginLeft,
  marginBottom,
  marginRight,
  fontWeight,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  ...divProps
}) => {
  const classes = cx(
    {
      [styles.Typography]: true,
      [styles.TypographyLabel]: variant === 'label',
      [styles.TypographyTitle1]: variant === 'title1',
      [styles.TypographyTitle2]: variant === 'title2',
      [styles.TypographyBody]: variant === 'body',
      [styles.TypographyModalTitle]: variant === 'modalTitle',
      [styles.TypographyDropdownLabel]: variant === 'dropdownLabel',
      [styles.TypographySectionSeparatorTitle]: variant === 'sectionSeparatorTitle',
    },
    className,
  )

  const Component = component || 'div'

  return (
    <Component
      style={{
        ...style,
        marginLeft,
        marginTop,
        marginBottom,
        marginRight,
        fontSize,
        paddingBottom,
        paddingLeft,
        paddingRight,
        paddingTop,
        fontWeight,
      }}
      className={classes}
      {...divProps}
    >
      {children}
    </Component>
  )
}

Typography.defaultProps = {
  variant: 'body',
  component: 'div',
}

export default Typography
