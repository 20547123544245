import { FC, SVGProps } from 'react'

const SelfServiceMappingIcon: FC<SVGProps<SVGSVGElement> & { size?: number }> = ({
  style,
  size = 16,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 12 12"
      preserveAspectRatio="none"
      style={{
        height: size,
        width: size,
        ...style,
      }}
      {...props}
    >
      <path
        d="M9.75 7.5c-.45 0-.825.15-1.2.375l-1.8-1.5V4.35c.9-.3 1.5-1.125 1.5-2.1C8.25.975 7.275 0 6 0S3.75.975 3.75 2.25c0 .975.6 1.8 1.5 2.1v2.025L3.45 7.8c-.375-.15-.75-.3-1.2-.3C.975 7.5 0 8.475 0 9.75S.975 12 2.25 12s2.25-.975 2.25-2.25c0-.225-.075-.525-.15-.75L6 7.725 7.65 9c-.075.225-.15.45-.15.75 0 1.275.975 2.25 2.25 2.25S12 11.025 12 9.75 11.025 7.5 9.75 7.5zm-7.5 3c-.45 0-.75-.3-.75-.75S1.8 9 2.25 9s.75.3.75.75-.3.75-.75.75zM6 3c-.45 0-.75-.3-.75-.75s.3-.75.75-.75.75.3.75.75S6.45 3 6 3zm3.75 7.5c-.45 0-.75-.3-.75-.75S9.3 9 9.75 9s.75.3.75.75-.3.75-.75.75z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  )
}

export default SelfServiceMappingIcon
