/* eslint-disable no-console */
import delay from 'lodash/delay'
import has from 'lodash/has'
import React, { FC, memo, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import compare from 'react-fast-compare'
import toast from 'react-hot-toast'
import { connect } from 'react-redux'

import useAccount from 'src/hooks/useAccount'
import useDispatcher from 'src/hooks/useDispatcher'
import useFetch from 'src/hooks/useFetch'
import useLocalStorage from 'src/hooks/useLocalStorage'
import useProductMatchParams from 'src/hooks/useProductMatchParams'
import useQueryParams from 'src/hooks/useQueryParams'
import {
  ComponentsSettingsActionsProps,
  resetComponentsSettings,
  setComponentsSettingsLoading,
} from 'src/redux/actions/componentsSettingsActions'
import { RootState } from 'src/redux/store'
import AdomikOriginType from 'src/types/AdomikOriginType'
import { ReactCallBackFunction } from 'src/types/CallbackFunction'
import { GetMenuInterface, MenuInterface } from 'src/types/MenuInterface'
import SaveDashboardItemInterface from 'src/types/SaveDashboardItemInterface'
import { getApiUrl } from 'src/utils'
import { prepareComponentTree } from 'src/utils/configManager'
import { Currency } from 'src/utils/currency'
import { Export } from 'src/utils/export'
import { transformProgramMenusFn } from 'src/utils/menuUtils'
import { Metric, MetricChartData } from 'src/utils/metric'
import { SubProduct } from 'src/utils/product'
import { Market } from 'src/utils/queries'
import { getDashboardKeyFn } from 'src/utils/saveDashboardUtils'
import { ComponentsSettings } from 'src/utils/settings'
import { getSnowflakeProductsFn } from 'src/utils/snowflakeProductsUtils'
import { isNeedToClearQueryFn } from 'src/utils/urlUtils'

export type Dashboard = {
  id: string
  label: string
  favorite: boolean
  selected: boolean
  sub_product?: string
  components_settings?: ComponentsSettings
}
export type DashboardsObject = { [pageCode: string]: SaveDashboardItemInterface } // Keep favorites and selecteds dashboards by subproduct
export type DimensionColor = { id: string; color: string; dimension: string }
export type UpdateMarketFn = (market: Market, persist?: boolean) => Promise<void>

interface GetDashboardArgs {
  subProduct: string
  reset?: boolean
  adomikOrigin?: AdomikOriginType
  programId?: number | string
}

interface GetFavoriteDashboardSettingsArgs {
  subProduct: string
  enableLoading?: boolean
  resetSettings?: boolean
  adomikOrigin?: AdomikOriginType
  programId?: number | string
}
export interface SettingsCtx {
  isDashboardListLoading: boolean
  components: object
  elements: MenuInterface[]
  elementsByCode: object
  currencies: ReadonlyArray<any>
  dimensionsColors: ReadonlyArray<DimensionColor>
  market: Market
  componentsSettingsRef: MutableRefObject<ComponentsSettings>
  exports: ReadonlyArray<Export>
  currencyUpdate: boolean
  dashboardsList: SaveDashboardItemInterface[]
  defaultDashboard: SaveDashboardItemInterface
  favoritesDashboards: DashboardsObject
  selectedsDashboards: DashboardsObject
  exportConfig: object
  /** Common metrics data (e.g: in KeyMetrics, MultiCharts...). One source of data and changes in one place & simplify the use from the different components */
  metricsData: Metric[]
  chartsData: MetricChartData[]
  setExportConfig: (value: object) => void
  setFavoritesDashboards: (value: DashboardsObject) => void
  setSelectedsDashboards: (value: DashboardsObject) => void
  setDefaultDashboard: (value: SaveDashboardItemInterface) => void
  setDashboardsList: ReactCallBackFunction<SaveDashboardItemInterface[]>
  getDashboards: (args: GetDashboardArgs) => Promise<any>
  setCurrencyUpdate: (value: boolean) => void
  /** Set common metrics (e.g: in KeyMetrics, MultiCharts...). One source of data and changes in one place & simplify the use from the different components */
  setMetricsData: ReactCallBackFunction<Metric[]>
  setChartsData: (value: MetricChartData[]) => void
  getComponents: (adomikOrigin?: AdomikOriginType) => Promise<any>
  getMenu: (adomikOrigin?: AdomikOriginType) => Promise<any>
  getCurrencies: (adomikOrigin?: AdomikOriginType) => Promise<any>
  getDimensionsColors: () => Promise<any>
  updateSelectedsDashboards: (value: DashboardsObject) => void
  updateFavoritesDashboards: (value: DashboardsObject) => void
  updateMarket: UpdateMarketFn
  fetchExports: () => Promise<any>
  deleteExport: (id: string) => Promise<any>
  updateExport: (id: string, settings: any) => Promise<any>
  getFavoriteDashboardSettings: (args: GetFavoriteDashboardSettingsArgs) => Promise<any>
  clearQueryParams: (queryParams?: object) => void
}

export const SettingsContext = React.createContext<SettingsCtx>({} as SettingsCtx)

const SettingsProvider: FC<{ [x: string]: any } & ComponentsSettingsActionsProps> = ({
  children,
  settings,
  oldSettings,
}) => {
  const [dimensionsColors, setDimensionsColors] = useLocalStorage('dimensions-colors')
  const { setQueryParams, queryParams } = useQueryParams()
  const [currencies, setCurrencies] = useState<ReadonlyArray<Currency>>()
  const [currencyUpdate, setCurrencyUpdate] = useState<boolean>(false)
  const [components, setComponents] = useState<object>()
  const [elements, setElements] = useState<MenuInterface[] | undefined>()
  const [elementsByCode, setElementsByCode] = useState<object>()
  const [market, setMarket] = useState<Market>()
  const [exportConfig, setExportConfig] = useState({})
  const [metricsData, setMetricsData] = useState<Metric[]>([])
  const [chartsData, setChartsData] = useState<MetricChartData[]>([])
  const [exports, setExports] = useState<ReadonlyArray<Export>>()
  const [dashboardsList, setDashboardsList] = useState<SaveDashboardItemInterface[]>([])
  const [defaultDashboard, setDefaultDashboard] = useState<SaveDashboardItemInterface>({
    id: 'default_dashboard',
    label: 'Standard Dashboard',
    favorite: true,
    selected: true,
    viewed: true,
  })
  const [favoritesDashboards, setFavoritesDashboards] = useState<DashboardsObject>({})
  const [selectedsDashboards, setSelectedDashboards] = useState<DashboardsObject>({})
  const { informations: accountInformations } = useAccount()
  const componentsSettingsRef = useRef<undefined | ComponentsSettings>()

  const routeMatch = useProductMatchParams()

  const isMarketIntel = routeMatch?.params?.subProduct === SubProduct.AnalyticsV2
  const program =
    isMarketIntel && routeMatch?.params?.program ? parseInt(routeMatch.params.program) : undefined

  const resetComponentsSettingsDispatcher = useDispatcher(resetComponentsSettings)
  const setComponentsSettingsLoadingDispatcher = useDispatcher(setComponentsSettingsLoading)

  const [, executeGetComponents] = useFetch<any>(
    {
      url: `${getApiUrl()}/api/admin/components`,
      method: 'GET',
    },
    { manual: true },
  )

  const [, executeGetMenu] = useFetch<GetMenuInterface[]>(
    {
      url: `${getApiUrl()}/api/admin/menu`,
      method: 'GET',
    },
    { manual: true },
  )

  const [, executeGetCurrencies] = useFetch<any>(
    {
      url: `${getApiUrl()}/api/currencies`,
      method: 'GET',
    },
    { manual: true },
  )

  const [, executeGetDimensionsColors] = useFetch<any>(
    {
      // To replace with right endpoint once deployed on QA
      url: `${getApiUrl()}/api/admin/dimension_colors`,
      method: 'GET',
    },
    { manual: true },
  )

  const [, executeSetDefaultMarket] = useFetch({ method: 'POST' }, { manual: true })
  const [, executeFetchExports] = useFetch<ReadonlyArray<Export>>(
    { url: `${getApiUrl()}/api/admin/export_configs` },
    { manual: true },
  )
  const [, executeDeleteExport] = useFetch({ method: 'DELETE' }, { manual: true })
  const [, executeUpdateExport] = useFetch({ method: 'PUT' }, { manual: true })

  const fetchExports = async () => {
    const { data: exports } = await executeFetchExports()
    setExports(exports)
  }

  const deleteExport = async (id: string) => {
    await executeDeleteExport({ url: `${getApiUrl()}/api/admin/export_configs/${id}` })
    await fetchExports()
    toast.success('Export successfully deleted')
  }

  const updateExport = async (id: string, settings: Partial<Export>) => {
    await executeUpdateExport({
      url: `${getApiUrl()}/api/admin/export_configs/${id}`,
      data: { export_config: settings },
    })
    await fetchExports()
    toast.success('Export successfully updated')
  }

  const getDimensionsColors = useCallback(async () => {
    const { data: dimensionsColors } = await executeGetDimensionsColors()

    setDimensionsColors(
      dimensionsColors.map(
        (data: { resource_id: string; hexa_code: string; dimension: string }) => ({
          id: data.resource_id,
          color: data.hexa_code,
          dimension: data.dimension,
        }),
      ),
    )
  }, [executeGetDimensionsColors, setDimensionsColors])

  const [, executeGetDashboards] = useFetch<SaveDashboardItemInterface[]>(
    {
      url: `${getApiUrl()}/api/admin/saved_dashboards`,
      method: 'GET',
    },
    { manual: true },
  )

  const [, executeGetFavoriteDashboardSettings] = useFetch({ method: 'GET' }, { manual: true })

  const [, executeGetExportConfig] = useFetch<any>({ method: 'GET' }, { manual: true })

  /**
   * Update selected dashboards by sub product
   */
  const updateSelectedDashboards = useCallback(async (value: DashboardsObject) => {
    setSelectedDashboards(s => ({ ...s, ...value }))
  }, [])

  /**
   * Update favorites dashboards by sub product
   * @param DashboardsObject value
   */
  const updateFavoritesDashboards = useCallback(async (value: DashboardsObject) => {
    setFavoritesDashboards(f => ({ ...f, ...value }))
  }, [])

  /**
   * Clear queries params in url
   */
  const clearQueryParams = useCallback(
    (queryParams?: object) => {
      setQueryParams({
        ...queryParams,
        scopes: { filters: [] },
        time_period: {},
        dimensions: '',
      })
    },
    [setQueryParams],
  )

  const settingsRef = useRef(settings)
  const oldSettingsRef = useRef(oldSettings)

  useEffect(() => {
    settingsRef.current = settings
    oldSettingsRef.current = oldSettings
  }, [settings, oldSettings])

  /**
   * Get favorite dashboard via api by product and update store
   *
   * On the "Standard Dashboard", this method is not called, we use the parameters of the url or default data to build the settings
   *
   * @param {string} subProductCodeArg
   * @param {boolean} enableLoading
   * @param {boolean} reset
   */
  const getFavoriteDashboardSettings = useCallback(
    async ({
      subProduct: subProductCodeArg,
      enableLoading = false,
      resetSettings: reset = false,
      adomikOrigin,
      programId: programArg,
    }: GetFavoriteDashboardSettingsArgs): Promise<any> => {
      const subProductCode = subProductCodeArg || SubProduct.Dashboard
      const dashboardKey = getDashboardKeyFn({ subProductCode, programId: programArg })
      const snowflakeProducts = getSnowflakeProductsFn()
      const isSnowflakeProduct =
        adomikOrigin === 'snowflake' || snowflakeProducts.includes(subProductCode as SubProduct)
      enableLoading && setComponentsSettingsLoadingDispatcher(true)
      let responseData: any
      const exportId = queryParams.export_id
      const from = queryParams?.from

      const programId = programArg || program

      if (exportId) {
        // If "export_id" is present in url, get settings from "export_config" api response
        // Display "PDF" only
        const { data: configIdSettings }: any = await executeGetExportConfig(
          {
            url: `${getApiUrl()}/api/admin/export_configs/${exportId}`,
          },
          undefined,
          isSnowflakeProduct ? 'snowflake' : 'pg',
        )

        setExportConfig(configIdSettings)
        responseData = configIdSettings
        reset = true
      } else {
        // Normal process

        const { data }: any = await executeGetFavoriteDashboardSettings(
          {
            url: `${getApiUrl()}/api/admin/saved_dashboards/favorite/${subProductCode}`,
            params: {
              program_id: programId,
            },
          },
          undefined,
          isSnowflakeProduct ? 'snowflake' : 'pg',
        ).catch(() => {
          console.error('Error while fetching favorite dashboard settings')
          return { data: {} }
        })
        responseData = data
      }
      const { components_settings, program_id } = responseData
      let newSettings: ComponentsSettings = settingsRef.current
      let newOldSettings: ComponentsSettings = oldSettingsRef.current

      const isComponentSettingProgram = programId
        ? program_id?.toString() === programId.toString()
        : true

      if (reset) {
        // Reset settings/oldSettings
        newSettings = { [dashboardKey]: components_settings?.[subProductCode] }
        newOldSettings = { [dashboardKey]: components_settings?.[subProductCode] }
      } else {
        // Keep a page in state even if you switch between products
        // Keep settings if subProductCode key already exist in settings object
        // and merge with new data
        if (
          !has(newSettings, dashboardKey) &&
          has(components_settings, subProductCode) &&
          isComponentSettingProgram
        ) {
          newSettings = { ...newSettings, [dashboardKey]: components_settings[subProductCode] }
          newOldSettings = {
            ...newOldSettings,
            [dashboardKey]: components_settings[subProductCode],
          }
        }
      }
      if (isNeedToClearQueryFn(from)) {
        resetComponentsSettingsDispatcher(newSettings, newOldSettings)
      }

      enableLoading && delay(() => setComponentsSettingsLoadingDispatcher(false), 600)
    },
    [
      executeGetExportConfig,
      executeGetFavoriteDashboardSettings,
      queryParams.export_id,
      queryParams?.from,
      program,
      resetComponentsSettingsDispatcher,
      setComponentsSettingsLoadingDispatcher,
    ],
  )

  /**
   * Get(via api)/set all dashboards
   * @param ProductPage subProductCode
   * @param boolean reset
   */
  const getDashboards = useCallback(
    async ({
      subProduct: subProductCode = 'dashboard',
      reset = false,
      adomikOrigin,
      programId,
    }: GetDashboardArgs) => {
      const dashboardKey = getDashboardKeyFn({ subProductCode, programId })

      const snowflakeProducts = getSnowflakeProductsFn()
      const isSnowflakeProduct =
        adomikOrigin === 'snowflake' || snowflakeProducts.includes(subProductCode as SubProduct)
      const { data: dashboardsListData } = await executeGetDashboards(
        {
          url: `${getApiUrl()}/api/admin/saved_dashboards`,
          params: {
            program_id: programId,
          },
        },
        undefined,
        isSnowflakeProduct ? 'snowflake' : 'pg',
      )
      const dashboards = dashboardsListData.map(d => {
        d.selected = d.favorite
        return d
      })
      let dashboardDefault: SaveDashboardItemInterface = {
        ...defaultDashboard,
        sub_product: subProductCode,
        favorite: true,
        selected: true,
      }
      const from = queryParams?.from
      let dashboardFavorite = dashboards.find(
        d =>
          d.favorite &&
          getDashboardKeyFn({ subProductCode: d.sub_product!, programId: d.program_id }) ===
            dashboardKey,
      )
      dashboardFavorite = (!reset && favoritesDashboards?.[dashboardKey]) || dashboardFavorite
      let selected = dashboards.find(
        d =>
          d.selected &&
          getDashboardKeyFn({ subProductCode: d.sub_product!, programId: d.program_id }) ===
            dashboardKey,
      )
      selected = (!reset && selectedsDashboards?.[dashboardKey]) || selected
      if (from === 'launch_in' || from === 'shared_link' || from === 'cross_launch_in') {
        // Switch directly to the Standard Dashboard
        setSelectedDashboards({ ...selectedsDashboards, [dashboardKey]: dashboardDefault })
      } else {
        dashboardDefault = {
          ...defaultDashboard,
          favorite: !dashboardFavorite?.favorite,
          selected: !selected?.selected,
        }
        const dashboard: DashboardsObject = {
          [dashboardKey]: selected?.selected ? selected : dashboardDefault,
        }
        setSelectedDashboards({ ...selectedsDashboards, ...dashboard })
      }
      setFavoritesDashboards({
        ...favoritesDashboards,
        [dashboardKey]: dashboardFavorite?.favorite ? dashboardFavorite : dashboardDefault,
      })
      setDashboardsList(dashboards)
      setDefaultDashboard(dashboardDefault)
    },
    [
      defaultDashboard,
      executeGetDashboards,
      favoritesDashboards,
      queryParams?.from,
      selectedsDashboards,
    ],
  )

  const getComponents = useCallback(
    async (adomikOrigin?: AdomikOriginType) => {
      const { data: components } = await executeGetComponents(undefined, undefined, adomikOrigin)
      setComponents(prepareComponentTree(components))
    },
    [executeGetComponents],
  )

  const getMenu = useCallback(
    async (adomikOrigin?: AdomikOriginType) => {
      const { data: menu } = await executeGetMenu(undefined, undefined, adomikOrigin)

      ReactDOM.unstable_batchedUpdates(() => {
        const transformedMenu = transformProgramMenusFn(menu)
        setElements(transformProgramMenusFn(menu))
        setElementsByCode(
          transformedMenu.reduce(
            (result: any, element: any) => ({
              ...result,
              ...(element?.children || []).reduce(
                (subResult: any, childElement: any) => ({
                  ...subResult,
                  [childElement.programCode || childElement.code]: {
                    ...childElement,
                    parentName: element.name,
                  },
                }),
                result,
              ),
              [element.code]: element,
            }),
            {},
          ),
        )
      })
    },
    [executeGetMenu],
  )

  const getCurrencies = useCallback(
    async (adomikOrigin?: AdomikOriginType) => {
      const { data: currencies } = await executeGetCurrencies(undefined, undefined, adomikOrigin)
      setCurrencies(currencies)
    },
    [executeGetCurrencies],
  )

  const updateMarket: UpdateMarketFn = useCallback(
    async (market, persist = true) => {
      try {
        if (persist) {
          await executeSetDefaultMarket({
            url: `${getApiUrl()}/api/benchmark/change_default_program`,
            data: {
              program_id: market.program?.id,
              seller_category: market.program.seller_category,
            },
          })
        }

        setMarket(market)
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    [executeSetDefaultMarket],
  )

  useEffect(() => {
    if (!market && accountInformations) {
      setMarket({
        program: {
          id: accountInformations.default_program,
          seller_category: accountInformations.seller_category,
        },
      })
    }
  }, [accountInformations, market])

  const contextValue = {
    components,
    elements,
    elementsByCode,
    currencies,
    dimensionsColors: dimensionsColors || [],
    market,
    componentsSettingsRef,
    exports,
    currencyUpdate,
    dashboardsList,
    defaultDashboard,
    setDefaultDashboard,
    setCurrencyUpdate,
    setDashboardsList,
    setMetricsData,
    metricsData,
    chartsData,
    setChartsData,
    favoritesDashboards,
    selectedsDashboards,
    setSelectedsDashboards: setSelectedDashboards,
    setFavoritesDashboards,
    getComponents,
    getDashboards,
    getMenu,
    getCurrencies,
    getDimensionsColors,
    updateMarket,
    deleteExport,
    updateExport,
    fetchExports,
    getFavoriteDashboardSettings,
    updateSelectedsDashboards: updateSelectedDashboards,
    updateFavoritesDashboards,
    clearQueryParams,
    exportConfig,
    setExportConfig,
    isDashboardListLoading: false,
  }

  return (
    <SettingsContext.Provider value={contextValue as SettingsCtx}>
      {children}
    </SettingsContext.Provider>
  )
}

const mapStateToProps = (state: RootState) => ({
  settings: state.componentsSettings.settings,
  oldSettings: state.componentsSettings.oldSettings,
})

export default connect(mapStateToProps, null)(memo(SettingsProvider, compare))
