import { ComponentCode, ComponentsSettings, SettingsLoaded } from 'src/utils/settings'
import {
  RESET_COMPONENTS_SETTINGS,
  RESET_SETTINGS_LOADED,
  SET_COMPONENTS_SETTINGS,
  SET_COMPONENTS_SETTINGS_LOADING,
  SET_OLD_COMPONENTS_SETTINGS,
  SET_SETTINGS_LOADED,
} from '../constants/constants'

// Components settings actions interface
export interface ComponentsSettingsActionsProps {
  setComponentsSettingsLoading: (value: boolean) => void
  setSettingsLoaded: (value: SettingsLoaded) => void
  resetSettingsLoaded: () => void
  resetComponentsSettings: (settings?: ComponentsSettings, oldSettings?: ComponentsSettings) => void
  setComponentsSettings: (
    value: object,
    componentCode?: ComponentCode | null,
    subProductCode?: string | null,
    isOld?: boolean,
  ) => void
}

/**
 * Set components settings
 * @param value
 * @param componentCode
 * @param subProductCode
 * @param isOld
 */
export const setComponentsSettingsAction = (
  value: object,
  componentCode?: ComponentCode | null,
  subProductCode?: string | null,
  isOld = false,
) => ({
  type: isOld ? SET_OLD_COMPONENTS_SETTINGS : SET_COMPONENTS_SETTINGS,
  payload: { value, componentCode, subProductCode },
})

/**
 * Reset components settings
 * @param settings
 * @param oldSettings
 */
export const resetComponentsSettings = (
  settings: ComponentsSettings = {},
  oldSettings: ComponentsSettings = {},
) => ({
  type: RESET_COMPONENTS_SETTINGS,
  payload: { settings, oldSettings },
})

/**
 * Set loading status when data (settings) loads
 * @param value
 */
export const setComponentsSettingsLoading = (value: boolean) => ({
  type: SET_COMPONENTS_SETTINGS_LOADING,
  payload: { value },
})

/**
 * Define the state when ecosystems, keyMetrics or filterBar parameters are loaded at the end of the run.
 * The parameters of the component are not loaded at the same time and in the same way. Allows to determine if the settings obj of a component are well loaded (No more
 * api or script call, all the treatments are executed after the page loading).
 * @param SettingsLoaded value
 */
export const setSettingsLoadedAction = (value: SettingsLoaded) => ({
  type: SET_SETTINGS_LOADED,
  payload: { value },
})

/**
 * Reset settings loaded obj
 * @param SettingsLoaded value
 */
export const resetSettingsLoadedAction = () => ({
  type: RESET_SETTINGS_LOADED,
})
