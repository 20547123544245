import { AnyAction } from 'redux'

import { UserInterface } from 'src/types/UserInterface'
import { SHARE_DASHBOARD_USERS_CHANGED } from '../constants/constants'

export interface ShareDashboardUsersStore {
  users: UserInterface[]
}

export const initState: ShareDashboardUsersStore = {
  users: [],
}

const shareDashboardUsersReducer = (state = initState, action: AnyAction) => {
  switch (action.type) {
    case SHARE_DASHBOARD_USERS_CHANGED: {
      const { users } = action.payload as { users: UserInterface[] }
      return { ...state, users }
    }

    default:
      return state
  }
}

export default shareDashboardUsersReducer
