import { useContext } from 'react'

import {
  ProductPageContext,
  ProductPageCtx,
} from 'src/components/data/providers/ProductPageProvider'

const useProductPage = (): ProductPageCtx => useContext(ProductPageContext)

export default useProductPage
