// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AdomikLoader__loaderWrapper___MOECO{position:fixed;top:0;left:0;width:100%;height:100%;z-index:1000;background-color:#f8f9fa;opacity:1;transition:opacity 1s ease;display:flex;justify-content:center;align-items:center}.AdomikLoader__imgLoader___XNX0l{height:4.375rem;animation:AdomikLoader__spin___hg0K4 2s linear infinite}.AdomikLoader__loaderMessage___inpsG{font-weight:400;font-style:italic;position:absolute;top:calc(50% - 6rem);width:100%;text-align:center}@keyframes AdomikLoader__spin___hg0K4{0%{transform:rotate(0deg)}100%{transform:rotate(-360deg)}}`, "",{"version":3,"sources":["webpack://./src/components/ui/atoms/PlaceHolder/AdomikLoader/AdomikLoader.scss"],"names":[],"mappings":"AAIA,qCACE,cAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,YAAA,CACA,wBAAA,CACA,SAAA,CACA,0BAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CAGF,iCACE,eAAA,CACA,uDAAA,CAGF,qCACE,eAAA,CACA,iBAAA,CACA,iBAAA,CACA,oBAAA,CACA,UAAA,CACA,iBAAA,CAGF,sCACE,GACE,sBAAA,CAGF,KACE,yBAAA,CAAA","sourcesContent":["@use '../../../../../../src/styles/colors';\n\n$_bg-wrapper: colors.$solitude;\n\n.loaderWrapper {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 1000;\n  background-color: $_bg-wrapper;\n  opacity: 1;\n  transition: opacity 1s ease;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.imgLoader {\n  height: 4.375rem;\n  animation: spin 2s linear infinite;\n}\n\n.loaderMessage {\n  font-weight: 400;\n  font-style: italic;\n  position: absolute;\n  top: calc(50% - 6rem);\n  width: 100%;\n  text-align: center;\n}\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n\n  100% {\n    transform: rotate(-360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var loaderWrapper = `AdomikLoader__loaderWrapper___MOECO`;
export var imgLoader = `AdomikLoader__imgLoader___XNX0l`;
export var spin = `AdomikLoader__spin___hg0K4`;
export var loaderMessage = `AdomikLoader__loaderMessage___inpsG`;
export default ___CSS_LOADER_EXPORT___;
