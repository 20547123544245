import cx from 'classnames'
import { forwardRef, ReactNode, useEffect, useRef, useState } from 'react'
import ReactSelect, { components } from 'react-select'

import * as styles from './Select.scss'

export interface Option {
  label: string
  value: any
}

export type Value = Option | ReadonlyArray<Option> | null | undefined

export interface SelectProps {
  /**
   * Options
   */
  options: ReadonlyArray<Option>

  /**
   * Initial value
   */
  defaultValue?: Value

  /**
   * Value (when controlled)
   */
  value?: Value

  /**
   * Is disabled
   * @default false
   */
  disabled?: boolean

  /**
   * Is multi
   * @default false
   */
  multi?: boolean

  /**
   * Placeholder
   */
  placeholder?: string

  /**
   * Classname
   */
  className?: string

  /**
   * On change event
   */
  onChange?: (value: Value) => void

  /**
   * On change event
   */
  onBlur?: any

  /**
   * components
   */
  components?: any

  /**
   * Option rendering function
   */
  render?(option: Option): ReactNode

  /**
   * Selected option rendering function
   */
  renderSelected?(option: Option): ReactNode

  isOptionDisabled?: (opion: Option) => boolean

  /**
   * Option item is on a single line (unwrapped text)
   */
  isSingleLineItemOption?: boolean

  error?: boolean | string
}

/**
 * Selection padding right
 */
const SELECTION_PADDING = 14

const Select = forwardRef<any, SelectProps>(
  (
    {
      options,
      defaultValue,
      value,
      disabled = false,
      multi = false,
      placeholder,
      className,
      onChange,
      onBlur,
      render,
      renderSelected,
      isOptionDisabled,
      isSingleLineItemOption,
      error,
      ...otherProps
    },
    ref,
  ) => {
    const menuRef = useRef<HTMLDivElement>(null)
    const [isMenuOpened, setIsMenuOpened] = useState(false)
    const [columnWidth, setColumnWidth] = useState<number | undefined>(undefined)

    useEffect(() => {
      if (isMenuOpened && isSingleLineItemOption) {
        if (menuRef.current) {
          const { scrollWidth } = menuRef.current
          // Check if div has scrollbar
          const isOverflowed = scrollWidth > menuRef.current.getBoundingClientRect().width
          setColumnWidth(scrollWidth + (isOverflowed ? SELECTION_PADDING : 0))
          return
        }
        setColumnWidth(undefined)
      }
    }, [isMenuOpened, isSingleLineItemOption])

    return (
      <ReactSelect
        options={options}
        onMenuOpen={() => setIsMenuOpened(true)}
        onMenuClose={() => setIsMenuOpened(false)}
        onChange={(newValue: Value) => {
          if (onChange) {
            onChange(newValue)
          }
        }}
        components={{
          MenuList: props => <components.MenuList {...props} innerRef={menuRef as any} />,
        }}
        styles={{
          option: base => ({
            ...base,
            width: columnWidth,
            whiteSpace: isSingleLineItemOption ? 'nowrap' : undefined,
          }),
        }}
        onBlur={onBlur}
        value={value}
        isDisabled={disabled}
        isClearable={false}
        isMulti={multi}
        placeholder={placeholder}
        backspaceRemovesValue={false}
        isOptionDisabled={isOptionDisabled}
        formatOptionLabel={(option, { context }) => {
          if (renderSelected && context === 'value') {
            return renderSelected(option)
          }

          if (render) {
            return render(option)
          }

          return option.label
        }}
        classNamePrefix="select"
        className={cx(styles.Select, { [styles.SelectError]: error }, className)}
        ref={ref}
        {...(defaultValue ? { defaultValue } : {})}
        {...otherProps}
      />
    )
  },
)

export default Select
