import cx from 'classnames'
import { CSSProperties, FC, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import AdomikLogo from 'src/assets/images/adomik-logo-blue-vect.png'
import AccountProfileButtonContainer from 'src/components/data/containers/AccountProfileButtonContainer/AccountProfileButtonContainer'
import SavedDashboardsContainer from 'src/components/data/containers/SavedDashboardsContainer'
import ProductPage from 'src/components/ui/atoms/ProductPage'
import usePrinter from 'src/hooks/usePrinter'
import useProductPage from 'src/hooks/useProductPage'
import useQueryParams from 'src/hooks/useQueryParams'
import useSettings from 'src/hooks/useSettings'
import useWindowSize from 'src/hooks/useWindowSize'
import { Product, SubProduct } from 'src/utils/product'
import PageLoadingHandler from '../PageLoadingHandler'
import HeaderProductTitle from './HeaderProductTitle'

import * as styles from './Header.scss'

interface HeaderProps {
  printModeAllowed?: boolean
  componentsCodes?: {
    exportDashboard: string
  }
  title?: string
}

// Min width of dropdown profil button before scroll
const CONTAINER_RIGHT_MIN_WIDTH = 250
// Padding in button scroll
const CONTAINER_RIGHT_PADDING_RIGHT = 74

const Header: FC<HeaderProps> = ({ printModeAllowed, componentsCodes, title }) => {
  const { pathname } = useLocation()
  const settings = useSettings()
  const { printMode } = usePrinter()
  const page = useProductPage()
  const { queryParams } = useQueryParams()
  const subProducts = [SubProduct.MarketAlerts, SubProduct.MarketAlertsV2] // All paths that match with subProduct
  const products = [Product.PRICE, Product.TROUBLESHOOT, Product.PbsYield] // All paths that match /troubleshoot/, /price/
  const displaySaveDashboardContainer =
    !products.includes(page.product as Product) &&
    !subProducts.includes(page.code as SubProduct) &&
    Object.keys(page).length

  const exportId = queryParams.export_id

  const headerLeftRef = useRef<HTMLDivElement | null>(null)

  const helpDeskRef = useRef<HTMLDivElement>(null)

  const { width: windowWidth } = useWindowSize()

  const [rightStyle, setRightStyle] = useState<CSSProperties>({})

  useEffect(() => {
    if (headerLeftRef.current) {
      const { width: leftWidth } = headerLeftRef.current.getBoundingClientRect()
      // If no need to show scroll
      if (leftWidth + CONTAINER_RIGHT_MIN_WIDTH < windowWidth) {
        const helpDeskWidth = helpDeskRef?.current?.getBoundingClientRect()?.width || 0
        setRightStyle({
          maxWidth: windowWidth - leftWidth - helpDeskWidth - CONTAINER_RIGHT_PADDING_RIGHT,
          display: 'flex',
          justifyContent: 'flex-end',
        })
        return
      }
    }
    setRightStyle({})
  }, [windowWidth])

  return (
    <header
      className={cx(styles.Header, {
        [styles.HeaderPrintMode]: printMode,
      })}
    >
      <div className={styles.leftContent} ref={headerLeftRef}>
        <img className={styles.Logo} alt="logo" src={AdomikLogo} />
        {(!displaySaveDashboardContainer || printMode) && (
          <span>
            <ProductPage pathname={pathname} settings={settings} title={title} />
          </span>
        )}
        {!exportId && Boolean(displaySaveDashboardContainer) && (
          <>
            {!printMode && <HeaderProductTitle pathname={pathname} settings={settings} />}
            <SavedDashboardsContainer
              printMode={printMode}
              componentsCodes={componentsCodes}
              printModeAllowed={printModeAllowed}
            />
          </>
        )}
        {exportId && <PageLoadingHandler exportId={exportId} selectedSavedDashboard={null} />}
      </div>
      <div className={styles.rightContent} style={rightStyle}>
        <AccountProfileButtonContainer />
        {!printMode && <div className={styles.HeaderHelpDeskContainer} />}
      </div>
    </header>
  )
}

export default Header
