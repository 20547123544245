import { FC, ReactNode } from 'react'
import { createPortal } from 'react-dom'

import Body from './Body'
import Footer from './Footer'
import ModalCore from './ModalCore'
import ModalHeader from './ModalHeader'

interface ModalProps {
  children: ReactNode
  onRequestClose: () => void
  open: boolean
  isManuallyShaped?: boolean
  isManuallyColored?: boolean
  hideCloseIcon?: boolean
  className?: string
  clickOutsideToClose?: boolean
  disableCloseIcon?: boolean
  containerClassName?: string
}

const Modal: FC<ModalProps> & {
  Footer: typeof Footer
  Body: typeof Body
  Header: typeof ModalHeader
} = props => {
  if (!props.open) return null
  return createPortal(<ModalCore {...props} />, document.body)
}

Modal.Footer = Footer
Modal.Body = Body
Modal.Header = ModalHeader

export default Modal
