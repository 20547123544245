import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import React, { PureComponent, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import useAccount from 'src/hooks/useAccount'
import useSettings from 'src/hooks/useSettings'
import { userType } from 'src/utils/propTypes'
import SidebarFooter from './SidebarFooter'
import SubMenu from './SubMenu'

import * as styles from './Menu.scss'

class Menu extends PureComponent {
  static propTypes = {
    settings: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
    updateMenuStatus: PropTypes.func.isRequired,
    user: userType,
  }

  menuWasOpen = null

  constructor(props) {
    super(props)

    this.onResizeWindow = this.onResizeWindow.bind(this)
    this.openMenu = this.openMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
    this.toggleDropdown = this.toggleDropdown.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.menuRef = React.createRef()
  }

  state = {
    currentProduct: '',
    currentParent: '',
    smallDevice: window.innerWidth < 1024,
    openSections: {},
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { history } = nextProps
    const { currentParent, openSections } = prevState

    if (!history && !history.location) {
      return null
    }

    const urlParse = history.location.pathname.split('/').filter(item => item.length)

    const [currentParentUrl, currentProductUrl] = urlParse

    if (currentParent === currentParentUrl && isEmpty(openSections)) {
      return {
        openSections: {
          [currentParent]: true,
        },
      }
    }

    if (currentParent && currentParent !== currentParentUrl) {
      return {
        currentParent,
      }
    } else if (currentParentUrl && currentProductUrl) {
      return {
        currentProduct: currentProductUrl,
        currentParent: currentParentUrl,
      }
    } else if (currentParentUrl) {
      return {
        currentParent: currentParentUrl,
      }
    }
    return null
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResizeWindow, false)
    // Managing menu opening and closing on mobile devices
    const menuRef = this.menuRef.current
    if (!isEmpty(menuRef)) {
      menuRef.addEventListener('touchstart', this.openMenu)
    }
    window.addEventListener('touchstart', this.handleClickOutside)

    const { updateMenuStatus } = this.props

    const { smallDevice } = this.state

    if (smallDevice) {
      updateMenuStatus(false)
    }

    this.timeout = setTimeout(() => {
      ReactTooltip.rebuild()
    }, 100)

    updateMenuStatus(false)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResizeWindow)
    window.removeEventListener('touchstart', this.handleClickOutside)
    clearTimeout(this.timeout)
  }

  handleClickOutside(event) {
    const { target } = event
    if (this.menuRef.current && !this.menuRef.current.contains(target)) {
      this.closeMenu()
    }
  }

  onResizeWindow() {
    const { updateMenuStatus, isMenuOpen } = this.props

    if (window.innerWidth < 1024) {
      this.menuWasOpen = isMenuOpen
      updateMenuStatus(false)
      this.setState(() => ({
        smallDevice: true,
      }))
    } else if (this.menuWasOpen !== null) {
      updateMenuStatus(this.menuWasOpen)
      this.setState(() => ({
        smallDevice: false,
      }))
    }
  }

  toggleDropdown(currentParent, currentProduct) {
    const { openSections } = this.state
    const isOpen = !!openSections[currentParent]

    this.setState(() => ({
      currentParent,
      currentProduct,
    }))
    // to avoid close currentParent when we click on subProduct
    if (!currentProduct) {
      this.setState(() => ({
        openSections: {
          [currentParent]: !isOpen,
        },
      }))
    }
  }

  openMenu() {
    const { updateMenuStatus } = this.props
    updateMenuStatus(true)
  }

  closeMenu() {
    const { updateMenuStatus } = this.props
    updateMenuStatus(false)
  }

  render() {
    const { settings, history, logout, user, updateMenuStatus, isMenuOpen } = this.props

    const { smallDevice, currentProduct, currentParent, openSections } = this.state
    const isOpen = isMenuOpen

    const urlParse = history.location.pathname.split('/').filter(item => item.length)

    const [currentParentUrl, currentProductUrl] = urlParse

    const menuClasses = cx({
      [styles.Menu]: true,
      [styles.MenuClosed]: !isOpen,
    })

    return (
      <aside className={menuClasses} data-theme={'dark'}>
        <div
          className={styles.Content}
          ref={this.menuRef}
          // For mobile navigation
          onTouchStart={this.openMenu}
          // For desktop navigation
          onMouseEnter={this.openMenu}
          onMouseLeave={this.closeMenu}
        >
          <div className={styles.Navigation}>
            {!isEmpty(settings.elements) &&
              settings.elements.map(parentElement => {
                return (
                  <SubMenu
                    key={parentElement.code}
                    currentProduct={currentProduct}
                    currentParent={currentParent}
                    toggleDropdown={this.toggleDropdown}
                    parentElement={parentElement}
                    isMenuOpen={isOpen}
                    currentParentUrl={currentParentUrl}
                    currentProductUrl={currentProductUrl}
                    openSections={openSections}
                    isCurrentParentOpen={!!openSections[parentElement.code]}
                  />
                )
              })}
          </div>
          <SidebarFooter
            isOpen={isOpen}
            smallDevice={smallDevice}
            updateMenuStatus={updateMenuStatus}
            logout={logout}
            history={history}
            user={user}
          />
        </div>
      </aside>
    )
  }
}

export default ({ onToggle }) => {
  const history = useHistory()
  const settings = useSettings()
  const { informations: account, logout } = useAccount()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const onToggleMenu = toggled => {
    setIsMenuOpen(toggled)
    onToggle(toggled)
  }

  return (
    <Menu
      history={history}
      settings={settings}
      user={account}
      updateMenuStatus={onToggleMenu}
      logout={logout}
      isMenuOpen={isMenuOpen}
    />
  )
}
