import cx from 'classnames'
import { FC, useState } from 'react'

import Image from 'src/assets/images/404.jpg'
import Button from '../../atoms/Button'

import * as styles from './GlobalError.scss'

const GlobalError: FC = () => {
  const [loaded, setLoaded] = useState(false)

  const classes = cx({
    [styles.GlobalError]: true,
    [styles.GlobalErrorLoaded]: loaded,
  })

  const handleGoBackToHomeAndReload = () => {
    window.location.href = '/'
  }

  return (
    <div data-testid="global-error" className={classes}>
      <div className={styles.GlobalErrorMessage}>
        <div className={styles.GlobalErrorHeader}>
          <span className={styles.GlobalErrorCode}>500</span>
          <span className={styles.GlobalErrorTitle}>- Oooops, An error occured :(</span>
        </div>
        <div className={styles.GlobalErrorDetails}>
          <Button buttonType="primary" onClick={handleGoBackToHomeAndReload}>
            Go back to the home page
          </Button>
        </div>
      </div>
      <img className={styles.GlobalErrorImage} src={Image} alt="" onLoad={() => setLoaded(true)} />
    </div>
  )
}

export default GlobalError
