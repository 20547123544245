import cx from 'classnames'
import { HTMLAttributes } from 'react'

import * as styles from './Text.scss'

interface TextProps extends HTMLAttributes<HTMLDivElement> {
  dark?: boolean
  animated?: boolean
  withContainer?: boolean
  containerProps?: HTMLAttributes<HTMLDivElement>
  fullParentSize?: boolean
}

const Text = ({
  dark,
  animated,
  className,
  withContainer,
  containerProps,
  fullParentSize,
  ...otherProps
}: TextProps) => {
  const classes = cx(styles.Text, className, {
    [styles.TextDark]: dark,
    [styles.TextAnimated]: animated,
    [styles.TextFullParentSize]: fullParentSize,
  })
  const line = <div className={classes} {...otherProps} />
  if (withContainer) {
    return (
      <div {...containerProps} className={cx(styles.Container, containerProps?.className)}>
        {line}
      </div>
    )
  } else {
    return line
  }
}

Text.defaultProps = {
  withContainer: true,
}

export default Text
