import { ChangeEvent } from 'react'

import Button from 'src/components/ui/atoms/Button'
import Input from 'src/components/ui/atoms/Forms/FlatInput'
import StarIcon from 'src/components/ui/atoms/Icons/StarIcon'
import Modal from 'src/components/ui/atoms/Modal'
import ComponentColor from 'src/types/ItemColor'
import { SEARCH } from 'src/utils/constant'

import * as styles from './QueryModal.scss'

interface Props {
  title: string
  value: string
  buttonLabel: string
  placeholder?: string
  hasError?: boolean
  open: boolean
  icon?: boolean
  inputTestId?: string
  buttonTestId?: string
  onClose: () => void
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  submit: () => void
  componentColor?: ComponentColor
}

const InputModal = ({
  value: inputValue,
  open,
  title,
  placeholder,
  buttonLabel,
  hasError,
  inputTestId,
  buttonTestId,
  icon,
  submit,
  onClose,
  onChange,
}: Props) => (
  <Modal open={open} onRequestClose={onClose}>
    <Modal.Header>{title}</Modal.Header>
    <Modal.Body className={styles.Editor}>
      <div className={styles.ModalContent}>
        <Input
          placeholder={placeholder}
          className={styles.Input}
          onChange={onChange}
          hasError={hasError}
          value={inputValue}
          data-testid={inputTestId}
          autoFocus
        />
        {icon && <StarIcon className={styles.StartIcon} color={SEARCH.color.yellow} />}
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={onClose} buttonType="silence">
        <div>Cancel</div>
      </Button>
      <Button buttonType="primary" onClick={submit} data-testid={buttonTestId}>
        <span>{buttonLabel}</span>
      </Button>
    </Modal.Footer>
  </Modal>
)

export default InputModal
