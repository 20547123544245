import { Field } from 'redux-form'

import { emailsList } from 'src/utils/formValidation'
import EmailsList from '../EmailsList'

const EmailsListField = ({ name, ...others }: { name: string; 'data-testid'?: string }) => (
  <Field
    name={name}
    data-testid={others['data-testid']}
    component={EmailsList}
    validate={[emailsList]}
  />
)

export default EmailsListField
