/* -------------------------------------------------------------------------- */
/*                             COMPONENTS SETTINGS                            */
/* -------------------------------------------------------------------------- */

export const SET_COMPONENTS_SETTINGS = 'SET_COMPONENTS_SETTINGS'
export const SET_OLD_COMPONENTS_SETTINGS = 'SET_OLD_COMPONENTS_SETTINGS'
export const RESET_COMPONENTS_SETTINGS = 'RESET_COMPONENTS_SETTINGS'
export const SET_COMPONENTS_SETTINGS_LOADING = 'SET_COMPONENTS_SETTINGS_LOADING'
export const SET_SETTINGS_LOADED = 'SET_SETTINGS_LOADED'
export const RESET_SETTINGS_LOADED = 'RESET_SETTINGS_LOADED'

/* -------------------------------------------------------------------------- */
/*                                    ROOT                                    */
/* -------------------------------------------------------------------------- */

export const SET_COMPONENTS_LOADING = 'SET_COMPONENTS_LOADING'
export const RESET_COMPONENTS_LOADING = 'RESET_COMPONENTS_LOADING'

/* -------------------------------------------------------------------------- */
/*                             FILTER BAR                                     */
/* -------------------------------------------------------------------------- */

export const FILTER_BAR_HOVERED = 'FILTER_BAR_HOVERED'
export const FILTER_BAR_FIXED_POSITION_CHANGED = 'FILTER_BAR_FIXED_POSITION_CHANGED'
export const FILTER_BAR_SET_CURRENT_TAGS = 'FILTER_BAR_SET_CURRENT_TAGS'
export const FILTER_BAR_SET_IS_TIME_PERIOD_INVALID = 'FILTER_BAR_SET_IS_TIME_PERIOD_INVALID'
export const FILTER_BAR_SET_MISSING_LAUNCHING_DIMENSIONS =
  'FILTER_BAR_SET_MISSING_LAUNCHING_DIMENSIONS'

/* -------------------------------------------------------------------------- */
/*                             TIME PERIOD                                    */
/* -------------------------------------------------------------------------- */
export const TIME_PERIOD_SET = 'TIME_PERIOD_SET'

/* -------------------------------------------------------------------------- */
/*                             MAPPING_MENU                                   */
/* -------------------------------------------------------------------------- */

export const MAPPING_MENU_SELECT_CHANGED = 'MAPPING_MENU_SELECT_CHANGED'
export const MAPPING_MENU_SHOW_SELECT_ONLY_CHANGED = 'MAPPING_MENU_SHOW_SELECT_ONLY_CHANGED'
export const MAPPING_MENU_SELECT_ALL = 'MAPPING_MENU_SELECT_ALL'
export const MAPPING_MENU_UNSELECT_ALL = 'MAPPING_MENU_UNSELECT_ALL'
export const MAPPING_MENU_SELECT_ONE_CHANGED = 'MAPPING_MENU_SELECT_ONE_CHANGED'
export const MAPPING_MENU_SET_AVAILABILITY = 'MAPPING_MENU_SET_AVAILABILITY'
export const MAPPING_MENU_SET_MODAL_STATUS = 'MAPPING_MENU_SET_MODAL_STATUS'

/* -------------------------------------------------------------------------- */
/*                             ADOMIK_LOADING                                   */
/* -------------------------------------------------------------------------- */

export const ADOMIK_LOADING_MESSAGE_GENERATE = 'ADOMIK_LOADING_MESSAGE_GENERATE'
export const PACMAN_LOADER_ENABLE = 'PACMAN_LOADER_ENABLE'

/* -------------------------------------------------------------------------- */
/*                             ECOSYSTEM                                   */
/* -------------------------------------------------------------------------- */

export const ECOSYSTEM_SET_LAUNCHING_SELECTED_ITEMS = 'ECOSYSTEM_SET_LAUNCHING_SELECTED_ITEMS'
export const ECOSYSTEM_REMOVE_LAUNCHING_SELECTED_ITEMS = 'ECOSYSTEM_REMOVE_LAUNCHING_SELECTED_ITEMS'
export const ECOSYSTEM_SET_LAUNCHING_SELECTED_ONE_ITEM = 'ECOSYSTEM_SET_LAUNCHING_SELECTED_ONE_ITEM'
export const ECOSYSTEM_SET_LAUNCHING_SELECTED_MULTIPLE_ITEMS =
  'ECOSYSTEM_SET_LAUNCHING_SELECTED_MULTIPLE_ITEMS'
export const ECOSYSTEM_SET_LAUNCHING_SELECTED_CLEAR = 'ECOSYSTEM_SET_LAUNCHING_SELECTED_CLEAR'
export const ECOSYSTEM_SYNCHRONISE_LAUNCHING_SELECTED_ITEMS =
  'ECOSYSTEM_SYNCHRONISE_LAUNCHING_SELECTED_ITEMS'

/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                             SHARE DASHBOARD USERS                                   */
/* -------------------------------------------------------------------------- */
export const SHARE_DASHBOARD_USERS_CHANGED = 'SHARE_DASHBOARD_USERS_CHANGED'
