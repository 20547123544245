const LogoutIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g fill="#818E94" fillRule="nonzero">
      <path d="M6 7H0v2h6v3l4-4-4-4z" />
      <path d="M15 16H1a1 1 0 0 1-1-1v-4h2v3h12V2H2v3H0V1a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1z" />
    </g>
  </svg>
)

export default LogoutIcon
