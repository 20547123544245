import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { reducer as formReducer } from 'redux-form'
import thunk from 'redux-thunk'

import componentsSettingsReducer from 'src/redux/reducers/componentsSettingsReducer'
import rootReducer from 'src/redux/reducers/root'
import adomikLoadingReducer from './reducers/adomikLoadinReducer'
import ecosystemReducer from './reducers/ecosystemReducer'
import filterBarReducer from './reducers/filterBarReducer'
import mappingMenuReducer from './reducers/mappingMenuReducer'
import shareDashboardUsersReducer from './reducers/shareDashboardUsers'

const reducers = combineReducers({
  form: formReducer,
  componentsSettings: componentsSettingsReducer,
  filterBar: filterBarReducer,
  root: rootReducer,
  mappingMenu: mappingMenuReducer,
  adomikLoading: adomikLoadingReducer,
  ecosystem: ecosystemReducer,
  shareDashboardUsers: shareDashboardUsersReducer,
})

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const configureStore = () => {
  const store = createStore(
    reducers,
    /* preloadedState, */ composeEnhancers(applyMiddleware(thunk)),
  )

  return store
}

const store = configureStore()

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export { store, reducers }
