import cx from 'classnames'
import { FC, HTMLAttributes, ReactNode } from 'react'

import ComponentColor from 'src/types/ItemColor'

import * as styles from '../Dropdown.scss'

interface OptionsProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode | ReactNode[]
  value?: string
  active?: boolean
  className?: string
  onClick?: () => void
  componentColor?: ComponentColor
}

const Options: FC<OptionsProps> = ({
  children,
  value,
  className,
  active,
  onClick,
  componentColor,
  ...divProps
}) => {
  const isOrange = componentColor === ComponentColor.orange
  return (
    <div
      onClick={onClick}
      className={cx(
        {
          [styles.Options]: true,
          [styles.OptionsOrange]: isOrange,
          [styles.OptionsActive]: active,
          [styles.OptionsOrangeActive]: isOrange && active,
        },
        className,
      )}
      {...divProps}
    >
      {children}
    </div>
  )
}

export default Options
