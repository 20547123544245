import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'
import Moment from 'moment'
import { extendMoment } from 'moment-range'

import { isHoursComparisonDisabled } from 'src/components/ui/organisms/Calendar/calendarComparisonUtils'
import { ADOMIK_ID } from './constant'

const localMoment = 'en'
const COMPARISON_DISABLED = 'comparison_disabled'
const COMPARISON_MONTHLY_DISABLED = 'comparison_month_disabled'

export const FIRST_DAY_OF_WEEK_START = 1
export const FIRST_WEEK_OF_YEAR_START = 7

export const moment = extendMoment(Moment)
moment.updateLocale(localMoment, {
  week: {
    // Should be an integer representing the first day of the week, 0 is Sunday, 1 is Monday, ..., 6 is Saturday.
    dow: FIRST_DAY_OF_WEEK_START,
    // Should be an integer. doy is used together with dow to determine the first week of the year
    doy: FIRST_WEEK_OF_YEAR_START,
  },
})

export const generateDropDown = (items, { id, name, code } = {}) => {
  if (!items || !Array.isArray(items)) {
    return []
  }
  const [idItem, nameItem, codeItem] = [id || 'id', name || 'name', code || 'code']
  return items.map(item => ({
    id: item[idItem],
    name: item[nameItem],
    code: item[codeItem],
  }))
}

export const humanizeDate = date => date && moment(date).format('MMM D')
export const humanizeMonth = date => date && moment(date).format('MMM YYYY')
export const humanizeFullDate = date => date && moment(date).format('YYYY-MM-DD')
export const formattedDateWithHour = date => date && moment(date).format('YYYY-MM-DD-HH')
export const humanizeFullDateWithHour = date => date && moment(date).format('MMM D hha')
export const humanizeFullDateWithHourAndUtc = date => date && moment(date).format('MMM D hha UTC')

export const humanizeFullDates = (dates, isHourly = false) =>
  dates &&
  mapValues(dates, el => el && (isHourly ? formattedDateWithHour(el) : humanizeFullDate(el)))

export const getApiUrl = () => {
  return process.env.API_URL
}

export const getQueryURL = () => {
  return `${process.env.API_URL}/api/queries`
}

export const getUserConfigURL = () => {
  return `${process.env.API_URL}/api/saved_configs`
}

// FIXME: Need to handle product
export const getMethodReporting = () => {
  return 'post'
}

export const completeSnack = snack => ({
  duration: 2500,
  type: 'info',
  ...snack,
})

export const stopWheelPropagation = (e, node) => {
  const hasChildScrolling = el => {
    if (
      el.scrollHeight > el.clientHeight &&
      el.scrollHeight - el.clientHeight - el.scrollTop > 0 &&
      el.scrollHeight > 100
    ) {
      return true
    }
    if (el === node || !el.parentNode) {
      return false
    }
    return hasChildScrolling(el.parentNode)
  }
  if (e.nativeEvent.deltaY <= 0 || hasChildScrolling(e.target)) {
    return true
  }
  e.preventDefault()
  return false
}

export const isComparisonDisabled = ({
  isBenchmarkProduct,
  startDate,
  endDate,
  startDateCompare,
  endDateCompare,
  numberOfDate,
  type,
  isPreviousYearComparison,
  isDataDownloadProduct,
  enableCompare,
  periodAvailableMin = 0,
}) => {
  let message = null
  const availablePeriodMin = periodAvailableMin ?? 0
  if (startDateCompare && endDateCompare && numberOfDate && type && type !== 'hours') {
    const previousMonth = startDate.clone().subtract(1, 'month')
    const previousMonthDays = previousMonth.daysInMonth()
    const startDateToMonth = startDate.diff(startDate.clone().startOf('month'), 'days')

    const lastYearCompareStartDate = moment().subtract(1, 'years').subtract(32, 'days')
    const lastYearCompareEndDate = moment().subtract(1, 'years').add(31, 'days')

    let daysToSubtract
    if (
      startDate.month() === endDate.month() &&
      startDate.month() !== moment().subtract(2, 'month').month()
    ) {
      daysToSubtract = startDateToMonth + previousMonthDays
    } else if (startDate.month() === moment().subtract(2, 'month').month()) {
      daysToSubtract = startDateToMonth
    } else {
      daysToSubtract = startDateToMonth
    }

    let range
    const sellRange = moment.range(startDate.clone().subtract(daysToSubtract, 'days'), startDate)
    let reportRange = moment.range(
      moment().subtract(numberOfDate + availablePeriodMin, type),
      moment().subtract(availablePeriodMin, type),
    )
    const troubleshootRange = moment.range(lastYearCompareStartDate, lastYearCompareEndDate)

    if (type === 'months') {
      reportRange = moment.range(
        moment().subtract(numberOfDate, type).startOf('month'),
        moment()
          .subtract(1 + availablePeriodMin, 'months')
          .endOf('month'),
      )
    }

    if (isBenchmarkProduct) {
      range = sellRange
    } else {
      range = reportRange
    }

    if (
      enableCompare &&
      (!endDateCompare.within(range) ||
        (!startDateCompare.within(range) && endDateCompare.within(range)))
    ) {
      if (
        isPreviousYearComparison &&
        startDateCompare.within(troubleshootRange) &&
        endDateCompare.within(troubleshootRange)
      ) {
        return message
      }
      /**
       * Comparison has been disabled
       * their is no data available in the comparison period
       * due to the lack of data for old days / months
       * */
      if (type === 'months') {
        message = COMPARISON_MONTHLY_DISABLED
      } else {
        message = COMPARISON_DISABLED
      }
    }
  } else if (type === 'hours') {
    return isHoursComparisonDisabled({
      numberOfAllowedDate: numberOfDate,
      startCompareDate: startDateCompare,
      startDate,
      endDate,
      endCompareDate: endDateCompare,
      enableCompare,
    })
  }

  if (
    moment().date() > 0 &&
    startDate &&
    endDate &&
    (startDate.isSame(moment(), 'month') || endDate.isSame(moment(), 'month')) &&
    !message &&
    type === 'months' &&
    !isDataDownloadProduct
  ) {
    message = 'current_month_not_same_duration'
  }
  return message
}

export const parseSearchResults = raw => {
  const { mapped = [], platforms = {} } = groupBy(raw, el => {
    const mappedElement = el.mapped ? el.mapped : el.id.substr(0, 3) === 'adk'
    return mappedElement ? 'mapped' : 'platforms'
  })

  return {
    raw,
    mapped,
    platforms: map(groupBy(platforms, 'platform'), (data, platform) => ({
      data,
      platform,
    })),
  }
}

export const idGen = () => {
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)

  return `${s4()}${s4()}-${s4()}${s4()}-${s4()}${s4()}`
}

export const matchProduct = (product, productMatch) => product === productMatch
export const matchSubProduct = (subProduct, subProductMatch) => subProduct === subProductMatch

export const markdown = value => {
  // eslint-disable-next-line no-useless-escape
  const star = /[\*\_]{2}([^\*\_]+)[\*\_]{2}/g

  const formattedMessage = value.replace(star, '<b>$1</b>')

  return { __html: formattedMessage }
}

export const isPlatformMapped = platformId => {
  const platform = platformId.substr(0, 3)

  return platform === ADOMIK_ID
}
